import React from "react";

const SearchForm = ({setSearch, findData, setDateBegin, setDateEnd, dateBegin, dateEnd}) => {
    const mapPeriods = [
        {
            id: 1,
            name: 'За всё время',
            disableInputs: true
        }, {
            id: 2,
            name: 'За указанный период',
            disableInputs: false
        }, {
            id: 3,
            name: 'Текущий квартал',
            disableInputs: true
        }, {
            id: 4,
            name: 'Текущий месяц',
            disableInputs: true
        }, {
            id: 5,
            name: 'За последние 90 дней',
            disableInputs: true
        }, {
            id: 6,
            name: 'Текущий год',
            disableInputs: true
        }
    ]
    const [activePeriod, setActivePeriod] = React.useState(mapPeriods[0])

    const setQuadOfYear = () => {
        const d = new Date()
        const month = d.getMonth()
        const year = d.getFullYear()
        if (0 <= month && month < 3) {
            setDateBegin(`${year}-01-01`)
            setDateEnd(`${year}-03-31`)
        } else if (3 <= month && month < 6) {
            setDateBegin(`${year}-04-01`)
            setDateEnd(`${year}-06-30`)
        } else if (6 <= month && month < 9) {
            setDateBegin(`${year}-07-01`)
            setDateEnd(`${year}-09-30`)
        } else if (9 <= month && month <= 11) {
            setDateBegin(`${year}-10-01`)
            setDateEnd(`${year}-12-31`)
        }
    }

    const setCurrentYear = () => {
        const d = new Date()
        const year = d.getFullYear()
        setDateBegin(`${year}-01-01`)
        setDateEnd(`${year}-12-31`)
    }

    const setCurrentMonth = () => {
        const d = new Date()
        const month = d.getMonth()
        const year = d.getFullYear()
        setDateBegin(`${year}-${("0"+(month+1)).slice(-2)}-01`)
        const endMonth = new Date(d.getFullYear(), month + 1, 0)
        setDateEnd(`${year}-${("0"+(endMonth.getMonth()+1)).slice(-2)}-${endMonth.getDate()}`)
    }

    const setLastThreeMonths = () => {
        const end = new Date()
        const begin = new Date()
        setDateEnd(end.toISOString().split('T')[0])
        begin.setMonth(end.getMonth() - 3)
        setDateBegin(begin.toISOString().split('T')[0])
    }

    const processActivePeriod = (index) => {
        const tab = mapPeriods[index]
        setActivePeriod(tab)
        switch (tab.id) {
            case 1:
                setDateBegin('')
                setDateEnd('')
                break
            case 3:
                setQuadOfYear()
                break
            case 4:
                setCurrentMonth()
                break
            case 5:
                setLastThreeMonths()
                break
            case 6:
                setCurrentYear()
                break
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-md-8 col-lg-6">
                    <div className="form-group mb-3 form-floating">
                        <input
                            className="form-control"
                            onChange={(e) => setSearch(e.currentTarget.value)}
                        />
                        <label>Введите параметр заявки:</label>
                    </div>
                </div>
                <div className="col-auto mb-3">
                    <button onClick={findData} className="btn btn-base btn-large">Найти</button>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-xl-6 col-md-8">
                    <h5>Период:</h5>
                    <div className="row">
                        <div className="col-6">
                            <div className="form-group mb-3">
                                <div className="datepicker-wrapper form-floating">
                                    <input
                                        className="form-control"
                                        autoComplete="off"
                                        type="date"
                                        placeholder="Placeholder"
                                        disabled={activePeriod.disableInputs}
                                        value={dateBegin}
                                        onChange={(e) => setDateBegin(e.currentTarget.value)}
                                    />
                                    <label>Начало периода</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="form-group mb-3">
                                <div className="datepicker-wrapper form-floating">
                                    <input
                                        className="form-control"
                                        autoComplete="off"
                                        type="date"
                                        placeholder="Placeholde"
                                        disabled={activePeriod.disableInputs}
                                        value={dateEnd}
                                        onChange={(e) => setDateEnd(e.currentTarget.value)}
                                    />
                                    <label>Конец периода</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="search-filter">
                        {mapPeriods.map((el, index) => {
                            return <div className="search-filter__item ">
                                <a
                                    className={activePeriod.id === el.id ? 'search-filter__link active': 'search-filter__link'}
                                    onClick={() => processActivePeriod(index)}
                                >{el.name}
                                </a>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

export default SearchForm
