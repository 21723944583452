import React from "react";

const SearchTabs = ({setAppLeasingStatus}) => {

    const tabs = [
        {
            id: 1,
            name: 'Выставлено КП',
            status: 3
        }, {
            id: 2,
            name: 'Отклонены',
            status: 9
        }
    ]

    const [activeTab, setActiveTab] = React.useState(null)

    const processActiveTab = (index) => {
        const tab = tabs[index]
        setActiveTab(tab)
        setAppLeasingStatus(tab.status)
    }

    return (
        <>
            {tabs && <>
                <h5>Статус:</h5>
                <div className="search-filter mb-3">
                    {tabs.map((el, i) => (<div key={i} className="search-filter__item">
                        <a
                            className={activeTab && activeTab.id === el.id ? 'search-filter__link active': 'search-filter__link'}
                            onClick={() => processActiveTab(i)}
                        >{el.name}
                        </a>
                    </div>))}
                </div>
            </>
            }
        </>
    )
}

export default SearchTabs
