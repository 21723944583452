import React from "react";
import {Link, useParams, Redirect, withRouter} from "react-router-dom";
import {api} from "../../Run";
import './SettingRights.css';

const SettingRights = ({main, reload}) => {

    let {UserCompanyId} = useParams()
    const [data, setData] = React.useState(null)
    const [message, setMessage] = React.useState('')
    const [anyMessage, setAnyMessage] = React.useState(false)
    const [isUserDeleted, setIsUserDeleted] = React.useState(false)
    const [errors, setErrors] = React.useState({
        FirstName: {
            error: false,
            title: 'Имя'
        },
        Email: {
            error: false,
            title: 'Email'
        },
        LastName: {
            error: false,
            title: 'Фамилия'
        }
    })

    const required = ['FirstName', 'Email', 'LastName']

    const changeStaffCard = () => {
        api('request',
            {
                Get: 'CompanyStaffCard',
                action: 'CompanyStaffEdit',
                CompanyId: main.CompanyId,
                UserCompanyId: UserCompanyId
            })
            .then(
                response => {
                    console.log('response', response)
                    if (response && response.hasOwnProperty('data')) {
                        if (response.data.hasOwnProperty('Error')) {
                            setAnyMessage(true)
                            setMessage(response.data.Error)
                        } else {
                            setData(response.data)
                        }
                    }
                }
            )
    }

    const sendStaffCard = () => {
        const errorFields = required.filter(el => !data[el])
        if (errorFields.length > 0) {
            let tmp = {...errors}
            errorFields.map(f => {
                tmp[f].error = true
            })
            setErrors(tmp)
            setAnyMessage(true)
            setMessage('Заполните поля ' + errorFields.map(f => errors[f].title).join(', '))
            return
        }

        api('request',
            {
                Put: 'CompanyStaffCard',
                action: 'CompanyStaffEdit',
                CompanyId: main.CompanyId,
                UserCompanyId: UserCompanyId,
                Position: data.Position,
                LastName: data.LastName,
                FirstName: data.FirstName,
                MidName: data.MidName,
                Email: data.Email,
                FLAccess: data.FLAccess
            }).then(
            response => {
                console.log('response', response)
                if (response) {
                    setAnyMessage(true)
                    setMessage(response.data.hasOwnProperty('Error') ? response.data.Error : response.data.msg)
                }
            }
        ).catch(
            error => {
                console.log('error', error)
                setAnyMessage(true)
                setMessage(error)
            }
        )
    }

    const deleteStaff = () => {
        api('request',
            {
                Put: 'CompanyStaffCard',
                action: 'CompanyStaffDelete',
                CompanyId: main.CompanyId,
                UserCompanyId: UserCompanyId
            })
            .then(
                response => {
                    console.log('response', response)
                    if (response && response.hasOwnProperty('data')) {
                        if (response.data.hasOwnProperty('Error')) {
                            setAnyMessage(true)
                            setMessage(response.data.Error)
                        } else {
                            setAnyMessage(true)
                            setIsUserDeleted(true)
                            setMessage(response.data.msg)
                            setData(response.data)
                            window.location.href = window.location.origin + "/UserList"
                        }
                    }
                }
            )
    }


    React.useEffect(() => {
        changeStaffCard();
    }, [])

    const handleChange = (param) => (e) => {
        const val = e.currentTarget.value
        setData({
            ...data,
            [param]: val
        })
        if (errors.hasOwnProperty(param) && val) {
            setErrors({
                ...errors,
                [param]: {
                    ...errors[param],
                    error: false
                }
            })
        }
    }

    const handleChangeAccess = (Id) => {
        setData({
            ...data,
            FLAccess: {...data.FLAccess, [Id]: data.FLAccess[Id] === 1 ? 0 : 1}
        })
    }

    console.log('data', data)

    return (
        <>{data && <div className="content-page">
            <div className="content-main">
                <div className="page-head mb-4">Настройки прав пользователя</div>

                {anyMessage && <div className='login-alert p-3 text-center mb-3'>
                    <div className='text-primary'>Внимание!</div>
                    <span>{message}</span>
                </div>}

                <div className="row">
                    <div className="col-md-8 col-lg-6">
                        <div className={`form-group mb-3 form-floating ${errors['LastName'].error ? 'error' : ''}`}>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Иванов"
                                value={data.LastName}
                                id="lastName"
                                onChange={handleChange('LastName')}
                            />
                            <label htmlFor="lastName">Фамилия</label>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div
                                    className={`form-group mb-3 form-floating ${errors['FirstName'].error ? 'error' : ''}`}>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Иванов"
                                        value={data.FirstName}
                                        id="name"
                                        onChange={handleChange('FirstName')}
                                    />
                                    <label htmlFor="name">Имя</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-3 form-floating">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Иванов"
                                        value={data.MidName}
                                        id="patronymic"
                                        onChange={handleChange('MidName')}
                                    />
                                    <label htmlFor="patronymic">Отчество</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    className={`form-group mb-3 form-floating ${errors['Email'].error ? 'error' : ''}`}>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Иванов"
                                        value={data.Email}
                                        id="email"
                                        onChange={handleChange('Email')}
                                    />
                                    <label htmlFor="email">E-mail</label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-3 form-floating">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Иванов"
                                        value={data.Position}
                                        id="position"
                                        onChange={handleChange('Position')}
                                    />
                                    <label htmlFor="position">Должность</label>
                                </div>
                            </div>
                        </div>
                        <h5 className="mt-4 mb-3">Доступные разделы</h5>
                        {data.AccessList && data.AccessList.map(s => <div className="form-group mb-3">
                            <label>
                                <input
                                    type="checkbox"
                                    name="checkbox"
                                    checked={data.FLAccess[s.Id]}
                                    onChange={() => handleChangeAccess(s.Id)}
                                /><span>{s.Name}</span>
                            </label>
                        </div>)}
                        <div className="row mb-3">
                            <div className="col-auto mb-3">
                                <button
                                    className="btn btn-base"
                                    onClick={sendStaffCard}
                                    disabled={isUserDeleted}
                                >Сохранить
                                </button>
                            </div>
                            <div className="col-auto mb-3">
                                <Link to={`/SettingRights/${data.UserCompanyId}`}>
                                    <button
                                        className="btn btn-ghost"
                                        onClick={deleteStaff}
                                        disabled={isUserDeleted}
                                    >Удалить профиль</button>
                                </Link>
                            </div>
                        </div>
                        <Link to='/UserList' className="icon icon-arrow-back">Вернуться к списку пользователей</Link>
                    </div>
                </div>
            </div>
            <div className="content-promo"/>
        </div>}</>
    )
}

export default SettingRights
