import {Link,useHistory} from 'react-router-dom'
import {api} from "../Run"
import { useEffect, useState } from 'react'

import fil from '../svg/Fil.svg'

function AppLeasing({main}) {

    let [data,setData] = useState({a:false,b:false,urlKp:''})
    let history = useHistory();

    let boxA = () => {
        if (data.a) {
            setData(data=> ({...data,a:false}))
        } else {
            setData(data=> ({...data,a:true}))
        }
    }

    let boxB = () => {
        if (data.b) {
            setData(data=> ({...data,b:false}))
        } else {
            setData(data=> ({...data,b:true}))
        }
    }

    useEffect(()=> {
        api('request',{"Get":"AppLeasing",action: "AppLeasingInfo",CompanyId:main.CompanyId}).then((d) => {
            setData(data=> ({...data,urlKp:d.data.AppLeasingLink}))
        })
    },[])




    return <div class="content-page"><div class="content-main"><div class="page-head mb-4">Новая заявка</div>
    <h4>Расчет КП</h4>
    <p>Выберите желаемые условия лизинга и получите коммерческое предложение компании с подробными условиями и графиком платежей от персонального менеджера.</p>
    <ul>
        <li>Услуга предоставляется бесплатно, в срок от 1 до 3 дней и не является офертой.</li>
        <li>Результат расчета действует ограниченный срок, указанный в Правилах компании, и ни в каком случае не может быть признан обязательством по выдаче описанного предмета лизинга лизинговой компанией.</li>
    </ul><Link class="btn btn-base" to="/AppLeasingNew">Создать новый расчет</Link>
    <h4 class="mt-4">Заявка на акцептование КП</h4>
    <p>Выберите и акцептуйте коммерческое предложение с оптимальными условиями и запустите заявку на рассмотрение.<br />Услуга предоставляется бесплатно.</p>
    <button class="btn btn-base" onClick={()=>{
         history.push(data.urlKp);
    }} disabled={!data.urlKp}>Акцептовать КП</button>
    </div>

    <div class="content-promo"></div>
    </div>

    // return <>
    // <div className='title'>Новая заявка на лизинг</div>

    // <div className='row mt-3'>
    //     <div className='col-sm-6'><Link to='/AppLeasingNew' className="btn btn-primary btn-block">Создать новый расчет</Link></div>
    //     <div className='col-sm-6'><button className="btn btn-primary btn-block" disabled={true}>Акцептовать КП</button></div>
    // </div>

    // <div className='row mt-3'>
    //     <div className='col-sm-6'>
    //         <div className='box-info box-info-left'>
    //             <h3 className='my-0 py-2 px-2' onClick={boxA} style={{color:'#155a97',fontWeight:'500',cursor:'pointer'}}>Об услуге</h3>
    //             <img onClick={boxA} style={{position:'absolute',top:'18px',right:'30px'}} src={fil} />
    //             {data.a && <div className='px-2'>

    //             <p className='mt-0'>Выберите транспортные средства и желаемые условия лизинга и получите коммерческое предложение компании в виде расчета ежемесячного платежа по указанным условиям. </p>
    //             <p>Услуга предоставляется бесплатно, в срок от 1 до 3 дней и не является офертой. Результат расчета действует ограниченный срок, указанный в Правилах компании, и ни в каком случае не может быть признан обязательством по выдаче описанного предмета лизинга лизинговой компанией.</p>
    //             </div>}
    //         </div>
    //     </div>
    //     <div className='col-sm-6'>
    //     <div className='box-info box-info-right'>
    //             <h3 className='my-0 py-2 px-2' onClick={boxB} style={{color:'#155a97',fontWeight:'500',cursor:'pointer'}}>Об услуге</h3>
    //             <img onClick={boxB} style={{position:'absolute',top:'18px',right:'30px'}} src={fil} />
    //             {data.b && <div className='px-2'>
    //             <p className='mt-0'>Выберите и акцептуйте коммерческое предложение с оптимальными условиями и запустите заявку на рассмотрение.</p>
    //             <p>Услуга предоставляется бесплатно.</p>
    //             </div>}
    //         </div>
    //     </div>
    // </div>


    // </>
}

export default AppLeasing
