import {Link} from 'react-router-dom'

function NoPage({main, page,setPage}) {
  return <>
  {/* <div className='title'>Новый расчет на лизинг № Р2012115</div> */}
{/* 
  <div className='mt-3'>
      <div className="btn-group btn-group-justified" role="group">
          <div className="btn-group" role="group">
              <Link to='/new' type="button" className="btn btn-zv done ">Создание расчета</Link>
          </div>
          <div className="btn-group" role="group">
              <Link to='/kp' type="button" className="btn btn-zv done">Согласование КП</Link>
          </div>
          <div className="btn-group" role="group">
              <Link to='/blank' type="button" className="btn btn-zv done">Заполнение анкеты</Link>
          </div>
          <div className="btn-group" role="group">
              <Link to='/doc' type="button" className="btn btn-zv done">Сбор документов</Link>
          </div>
          <div className="btn-group" role="group">
              <Link to='/ok' type="button" className="btn btn-zv active">Одобрение сделки</Link>
          </div>
          <div className="btn-group" role="group">
              <Link to='/end' type="button" className="btn btn-zv done">Подписание договора</Link>
          </div>
      </div>
  </div> */}


  <div className='title-blank mt-3 pb-0' style={{borderTop:'0'}}>
  
  <label className="container-checkbox mb-0">
  Шаг 1 из 3 Проверка документов
                                <input type="checkbox"  checked={page.AppLeasingStatus === '5' || page.AppLeasingStatus === '6'} disabled />
                                <span className="checkmark"></span>
                            </label>
    </div>


<div className='row'>

<div className='col-xs-12'>

  <div className='box-info box-info-left-no-title mt-4 px-3'>


    <p>Автоматическая проверка загруженных документов на соответствие формату, проверка корректности содержимого. В случае, если документ не прошел автоматическую проверку, его необходимо подгрузить повторно, выполнив требования в описании к документу.</p>
    <p>В случае успешной проверки всех документов из первоначального списка, проверка переходит в завершающую стадию принятия решения по лизинговой сделке.</p>
  
  </div>

  </div>
     
  </div>


  <div className='title-blank mt-3 pb-0'>
  


  <label className="container-checkbox mb-0">
  Шаг 2 из 3 Запрос дополнительной информации
                                <input type="checkbox"  checked={page.AppLeasingStatus === '6'} disabled />
                                <span className="checkmark"></span>
                            </label>
    </div>






    <div className='row'>

<div className='col-xs-12'>

  <div className='box-info box-info-left-no-title mt-4 px-3'>


    <p>Для принятия окончательного решения требуется предоставление дополнительной информации. Необходимо вернуться к этапу сбора документов и подгрузить запрошенные дополнительно материалы.</p>
   
  
  </div>

  </div>
     
  </div>









  <div className='title-blank mt-3  pb-0'>




  <label className="container-checkbox mb-0">
  Шаг 3 из 3 Проверка завершена
                                <input type="checkbox"  checked={page.AppLeasingStatus === '6'} disabled />
                                <span className="checkmark"></span>
                            </label>


    </div>



    <div className='row'>

<div className='col-xs-12'>

  <div className=' mt-4 px-3'>


    <p>Поздравляем! “СТОУН-XXI” готова сотрудничать с Вашей компанией. Для уточнения одобренных условий в ближайшее время с Вами свяжется персональный менеджер:</p>
   
   <p style={{color:'#666666',marginLeft:'30px'}}>Ваш персональный менеджер: {main.PM[0].fio}</p>
  
  </div>

  </div>
     
  </div>


  <div className='mt-4 mx-auto' style={{maxWidth:'300px'}}>
                <button className="btn btn-primary btn-block" disabled={!(page.AppLeasingStatus === '6')}>Акцептовать одобренные условия</button>
            </div>

            <div className='mt-4 mx-auto' style={{maxWidth:'300px'}}>
                <Link to='/AppLeasing' className="btn btn-primary btn-block">Отправить новую заявку</Link>
            </div>


            <div className='row mx-auto' style={{marginTop:'-15px'}}>
        <div className='col-sm-6'><button className="btn btn-link">Отменить сделку</button></div>
        <div className='col-sm-6 text-right'><button className="btn btn-link" disabled={!(page.AppLeasingStatus === '6')}>Перейти к подписанию договора</button></div>
    </div>




  </>
}

export default NoPage
