import React, {useEffect} from 'react'
import { Link } from 'react-router-dom';
import {api} from "../Run";

const Main = (props) => {

    const [applications, setApplications] = React.useState({});

    useEffect(() => {





        api('test', {
                Get: "AppLeasingStat",
                action: "AppLeasingShowStat",
                token: localStorage.getItem('token'),
                CompanyId: props.main.CompanyId
            }
        ).then(
            response => {
                if (response.s === 'success') {
                    setApplications(response.data[0])
                }
            }
        )
    }, []);

    return <div className="content-page">
        <div className="content-main">
            <div className="page-head mb-4">Главная</div>
            <h1>Заявки</h1>
            <div className="row">
                <div className="col-auto mb-3">
                    <div className="applications-tag">Новых КП: <b>{applications.KPNew}</b></div>
                </div>
                <div className="col-auto mb-3">
                    <div className="applications-tag">Документов требуется загрузить: <b>{applications.Doc2Load}</b></div>
                </div>
                <div className="col-auto mb-3">
                    <div className="applications-tag">Документов на проверке: <b>{applications.DocInCheck}</b></div>
                </div>
                <div className="col-auto mb-3">
                    <div className="applications-tag">Заявок отклонено: <b>{applications.AppLeasingDenied}</b></div>
                </div>
                <div className="col-auto mb-3">
                    <div className="applications-tag">Заявок на рассмотрении: <b>{applications.AppLeasing4View}</b></div>
                </div>
                <div className="col-auto mb-3">
                    <div className="applications-tag">Заявок на подписании: <b>{applications.AppLeasing4Sign}</b></div>
                </div>
            </div>
            <div className="row mb-4 mb-n3">
                <div className="col-auto mb-3"><Link className="btn btn-base" to='/AppLeasing'>Подать новую заявку</Link></div>
                <div className="col-auto mb-3"><button className="btn btn-ghost" href="" disabled>Запрос в компанию</button></div>
            </div>
            <div className="row">
                {/* <div className="col-md-6 mb-4">
                    <div className="index-info">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-auto">
                                <div className="h4 mb-1 mt-0 text-primary">Договоры</div>
                            </div>
                            <div className="col-auto">
                                <div className="note text-secondary mb-1">Действующих договоров: <a href="">25</a></div>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-between mb-n2 m-t-10">
                            <div className="col-auto mb-2">Сумма действующих договоров:</div>
                            <div className="col-auto mb-2"><a className="h6 mb-0 mt-0" href="">49 900 000,00 ₽</a></div>
                        </div>
                        <div className="row align-items-center justify-content-between mb-n2 m-t-10">
                            <div className="col-auto mb-2">Выплачено:</div>
                            <div className="col-auto mb-2"><a className="h6 mb-0 mt-0" href="">23 750 000,00 ₽</a></div>
                        </div>
                        <div className="row align-items-center justify-content-between mb-n2 m-t-10">
                            <div className="col-auto mb-2 text-danger">Задолженность:</div>
                            <div className="col-auto mb-2"><a className="h6 mb-0 mt-0" href="">25 500,00 ₽</a></div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="col-md-6 mb-4">
                    <div className="index-info">
                        <div className="row justify-content-between align-items-center">
                            <div className="h4 mb-1 mt-0 text-primary">Вознаграждения</div>
                        </div>
                        <div className="row align-items-center justify-content-between mb-n2 m-t-10">
                            <div className="col-auto mb-2">Планируемые выплаты:</div>
                            <div className="col-auto mb-2"><a className="h6 mb-0 mt-0" href="">49 900 000,00 ₽</a></div>
                        </div>
                        <div className="row align-items-center justify-content-between mb-n2 m-t-10">
                            <div className="col-auto mb-2">На подписании:</div>
                            <div className="col-auto mb-2"><a className="h6 mb-0 mt-0" href="">23 750 000,00 ₽</a></div>
                        </div>
                        <div className="row align-items-center justify-content-between mb-n2 m-t-10">
                            <div className="col-auto mb-2 text-success">Ожидает перечисления:</div>
                            <div className="col-auto mb-2"><a className="h6 mb-0 mt-0" href="">25 500,00 ₽</a></div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="col-md-6 mb-4">
                    <div className="index-info">
                        <div className="row justify-content-between align-items-center">
                            <div className="h4 mb-1 mt-0 text-primary">Сделки</div>
                        </div>
                        <div className="row align-items-center justify-content-between mb-n2 m-t-10">
                            <div className="col-auto mb-2">Сделок заключено: <b>5</b></div>
                            <div className="col-auto mb-2"><a className="h6 mb-0 mt-0" href="">49 900 000,00 ₽</a></div>
                        </div>
                        <div className="row align-items-center justify-content-between mb-n2 m-t-10">
                            <div className="col-auto mb-2">Авто отгружено: <b>37</b></div>
                            <div className="col-auto mb-2"><a className="h6 mb-0 mt-0" href="">23 750 000,00 ₽</a></div>
                        </div>
                        <div className="row align-items-center justify-content-between mb-n2 m-t-10">
                            <div className="col-auto mb-2">Авто к поставке: <b>5</b></div>
                            <div className="col-auto mb-2"><a className="h6 mb-0 mt-0" href="">25 500,00 ₽</a></div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="col-md-6 mb-4">
                    <div className="index-info">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-auto">
                                <div className="h4 mb-1 mt-0 text-primary">Фотоштрафы</div>
                            </div>
                            <div className="col-auto">
                                <div className="note text-secondary mb-1">Последний выставленный штраф: 29.12.19 на 500 ₽
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-between mb-n2 m-t-10">
                            <div className="col-auto mb-2">Новых штрафов: <b>2</b></div>
                            <div className="col-auto mb-2"><a className="h6 mb-0 mt-0" href="">1 500,00 ₽</a></div>
                        </div>
                        <div className="row align-items-center justify-content-between mb-n2 m-t-10">
                            <div className="col-auto mb-2">Не оплачено штрафов: <b>2</b></div>
                            <div className="col-auto mb-2"><a className="h6 mb-0 mt-0" href="">1 500,00 ₽</a></div>
                        </div>
                    </div>
                </div> */}
            </div>




            <div class="row">
	{/* <div class="col-lg-4 mb-4">
		<div class="splide js-promo-slider index-promo__slider">
			<div class="splide__track">
				<ul class="splide__list m-0">
					<li class="splide__slide index-promo">
						<div class="index-promo__img-wrap"><img class="index-promo__img" src="images/index-promo.png" /></div>
						<div class="index-promo__inner"><a class="h5 mb-2 fw-normal d-block mb-2" href="">Оперативный лизинг Mercedes-Benz</a>
							<p>Аванс от 0%<br />Срок лизинга до 3-х лет<br />Скидки от дилеров марки до 12%</p><a class="btn btn-base">Подробнее</a>
						</div>
					</li>
					<li class="splide__slide index-promo">
						<div class="index-promo__img-wrap"><img class="index-promo__img" src="images/promo-2.jpg" /></div>
						<div class="index-promo__inner"><a class="h5 mb-2 fw-normal d-block mb-2" href="">Оперативный лизинг Volkswagen Tiguan</a>
							<p>Аванс от 0%<br />Срок лизинга до 3-х лет<br />Скидки от дилеров марки до 12%</p><a class="btn btn-base">Подробнее</a>
						</div>
					</li>
					<li class="splide__slide index-promo">
						<div class="index-promo__img-wrap"><img class="index-promo__img" src="images/index-promo.png" /></div>
						<div class="index-promo__inner"><a class="h5 mb-2 fw-normal d-block mb-2" href="">Оперативный лизинг Mercedes-Benz</a>
							<p>Аванс от 10%<br />Срок лизинга до 12-х лет<br />Скидки от дилеров марки до 70%</p><a class="btn btn-base">Подробнее</a>
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div> */}
	<div class="col-lg-4 mb-4">
		<div class="index-promo">
			<div class="index-promo__img-wrap"><img class="index-promo__img" src={props.ad.ADV_MAIN && props.ad.ADV_MAIN[0].IMG} /></div>
			<div class="index-promo__inner"><a class="h5 mb-2 fw-normal d-block" target="_blank" href={props.ad.ADV_MAIN && props.ad.ADV_MAIN[0].LINK}>{props.ad.ADV_MAIN && props.ad.ADV_MAIN[0].NAME}</a>
            <p dangerouslySetInnerHTML={{ __html: props.ad.ADV_MAIN && props.ad.ADV_MAIN[0].PREVIEW_TEXT }}></p>
                <a class="btn btn-base" target="_blank" href={props.ad.ADV_MAIN && props.ad.ADV_MAIN[0].LINK}>Подробнее</a>
			</div>
		</div>
	</div>
    <div class="col-lg-4 mb-4">
		<div class="index-promo">
			<div class="index-promo__img-wrap"><img class="index-promo__img" src={props.ad.ADV_MAIN && props.ad.ADV_MAIN[1].IMG} /></div>
			<div class="index-promo__inner"><a class="h5 mb-2 fw-normal d-block" target="_blank" href={props.ad.ADV_MAIN && props.ad.ADV_MAIN[1].LINK}>{props.ad.ADV_MAIN && props.ad.ADV_MAIN[1].NAME}</a>
            <p dangerouslySetInnerHTML={{ __html: props.ad.ADV_MAIN && props.ad.ADV_MAIN[1].PREVIEW_TEXT }}></p>
                <a class="btn btn-base" target="_blank" href={props.ad.ADV_MAIN && props.ad.ADV_MAIN[1].LINK}>Подробнее</a>
			</div>
		</div>
	</div>
	<div class="col-lg-4 mb-4">
		<div class="index-promo">
			<div class="index-promo__img-wrap"><img class="index-promo__img" src={props.ad.ADV_MAIN && props.ad.ADV_MAIN[2].IMG} /></div>
			<div class="index-promo__inner"><a class="h5 mb-2 fw-normal d-block" target="_blank" href={props.ad.ADV_MAIN && props.ad.ADV_MAIN[2].LINK}>{props.ad.ADV_MAIN && props.ad.ADV_MAIN[2].NAME}</a>
				<p dangerouslySetInnerHTML={{ __html: props.ad.ADV_MAIN && props.ad.ADV_MAIN[2].PREVIEW_TEXT }}></p>
                <a class="btn btn-base" target="_blank" href={props.ad.ADV_MAIN && props.ad.ADV_MAIN[2].LINK}>Подробнее</a>
			</div>
		</div>
	</div>
</div>
        </div>
        <div className="content-promo"/>
    </div>


}

export default Main;
