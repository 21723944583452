import {useEffect, useState} from 'react'

import Address from "../../../components/common/Address";
import PassportPrompt from "../../../components/common/PassportPrompt";
import InputMask from "react-input-mask";
import {
    Beneficiary
} from '../ErrorInput'

let Blank3 = ({save,input,data,setData,error,setError,del,changeArr,changeArrChe,changeAddress,changePassport,disabled}) => {
    return <>{data.Beneficiary.map((d, i) => <><div class="row mt-3">
	<div class="col-md-8 col-lg-6 mb-3">
		<div className={`form-group mb-3 form-floating ${error.Beneficiary[i].BeneficiaryLName && 'has-error'}`}>
			<input class="form-control" onBlur={save}  name="BeneficiaryLName" onChange={(e) => changeArr(e, i, 'Beneficiary')} value={d.BeneficiaryLName} placeholder="Иванов" disabled={disabled} />
			<label>Фамилия бенефициара</label>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div className={`form-group mb-3 form-floating ${error.Beneficiary[i].BeneficiaryFName && 'has-error'}`}>
					<input class="form-control" onBlur={save}  name="BeneficiaryFName" onChange={(e) => changeArr(e, i, 'Beneficiary')} value={d.BeneficiaryFName} placeholder="Иванов" disabled={disabled} />
					<label>Имя бенефициара</label>
				</div>
			</div>
			<div class="col-md-6">
				<div className={`form-group mb-3 form-floating ${error.Beneficiary[i].BeneficiaryMName && 'has-error'}`}>
					<input class="form-control" onBlur={save}  name="BeneficiaryMName" onChange={(e) => changeArr(e, i, 'Beneficiary')} value={d.BeneficiaryMName} placeholder="Иванов" disabled={disabled} />
					<label>Отчество бенефициара</label>
				</div>
			</div>
			<div class="col-md-6">
				<div className={`form-group mb-3 ${error.Beneficiary[i].BeneficiaryBD && 'has-error'}`}>
					<div class="datepicker-wrapper form-floating">
						<input class="form-control" onBlur={save}  name="BeneficiaryBD" onChange={(e) => changeArr(e, i, 'Beneficiary')} value={d.BeneficiaryBD} autocomplete="off" type="date" placeholder="Placeholder" disabled={disabled} />
						<label>Дата рождения</label>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				<div className={`form-group mb-3 form-floating ${error.Beneficiary[i].BeneficiaryBPlace && 'has-error'}`}>
					<input class="form-control" onBlur={save}  name="BeneficiaryBPlace" onChange={(e) => changeArr(e, i, 'Beneficiary')} value={d.BeneficiaryBPlace} placeholder="Иванов" disabled={disabled} />
					<label>Место рождения</label>
				</div>
			</div>
		</div>
		<div className={`form-group mb-3 form-floating ${error.Beneficiary[i].BeneficiaryINN && 'has-error'}`}>
			<input class="form-control" onBlur={save}  name="BeneficiaryINN" onChange={(e) => changeArr(e, i, 'Beneficiary')} value={d.BeneficiaryINN} placeholder="Иванов" disabled={disabled} />
			<label>ИНН</label>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div className={`form-group mb-3 form-floating ${error.Beneficiary[i].BeneficiaryTel && 'has-error'}`}>
				<InputMask mask="+7(999)999-99-99" class="form-control" onBlur={save}  name="BeneficiaryTel" onChange={(e) => changeArr(e, i, 'Beneficiary')} value={d.BeneficiaryTel} placeholder="Иванов" type="tel" disabled={disabled}/>
					{/* <input class="form-control" onBlur={save}  name="BeneficiaryTel" onChange={(e) => changeArr(e, i, 'Beneficiary')} value={d.BeneficiaryTel} placeholder="Иванов" type="tel" disabled={disabled} /> */}
					<label>Телефон</label>
				</div>
			</div>
			<div class="col-md-6">
				<div className={`form-group mb-3 form-floating ${error.Beneficiary[i].BeneficiaryEmail && 'has-error'}`}>
					<input class="form-control" onBlur={save}  name="BeneficiaryEmail" onChange={(e) => changeArr(e, i, 'Beneficiary')} value={d.BeneficiaryEmail} placeholder="Иванов" type="email" disabled={disabled} />
					<label>Email</label>
				</div>
			</div>
		</div>
		<div class="form-group mb-3">
			<label>
				<input type="checkbox" name='BeneficiaryPDL' onClick={(e) => changeArrChe(e, i, 'Beneficiary')}
                                    defaultChecked={d.BeneficiaryPDL} disabled={disabled} /><span>Является ПДЛ</span>
			</label>
		</div>
	</div>
	<div class="col-md-8 col-lg-6 mb-3">
		<h5 class="mb-2">Паспорт</h5>
		<div className={`form-group mb-3 form-floating ${error.Beneficiary[i].BeneficiaryPassportSN && 'has-error'}`}>
			<input class="form-control" onBlur={save}  name="BeneficiaryPassportSN" onChange={(e) => changeArr(e, i, 'Beneficiary')} value={d.BeneficiaryPassportSN} placeholder="Иванов" disabled={disabled} />
			<label>Серия и номер паспорта</label>
		</div>
		<div className={`form-group mb-3 form-floating ${error.Beneficiary[i].BeneficiaryCitizenship && 'has-error'}`}>
			<select class="form-control form-select" onBlur={save}  name="BeneficiaryCitizenship" onChange={(e) => changeArr(e, i, 'Beneficiary')} value={d.BeneficiaryCitizenship} disabled={disabled}>
				<option>РФ</option>
				<option>Иностранный гражданин</option>
			</select>
			<label>Гражданство</label>
		</div>
		{/* <div className={`form-group mb-3 form-floating ${error.Beneficiary[i].BeneficiaryPassPlace && 'has-error'}`}>
			<input class="form-control" name="BeneficiaryPassPlace" onChange={(e) => changeArr(e, i, 'Beneficiary')} value={d.BeneficiaryPassPlace} placeholder="Иванов" />
			<label>Место выдачи</label>
		</div> */}
		<PassportPrompt
				error={error.Beneficiary[i].BeneficiaryPassPlace}
                            name="BeneficiaryPassPlace"
                            label="Место выдачи"
                            className='form-control'
                            value={d.BeneficiaryPassPlace}
                            onChange={changePassport('Beneficiary', 'BeneficiaryPassPlace', 'BeneficiaryPassPCode', i)}
							disabled={disabled}
							onBlur={save} 
                        />
		<div class="row">
			<div class="col-md-6">
				<div className={`form-group mb-3 ${error.Beneficiary[i].BeneficiaryPassDate && 'has-error'}`}>
					<div class="datepicker-wrapper form-floating">
						<input class="form-control" onBlur={save}  name="BeneficiaryPassDate" onChange={(e) => changeArr(e, i, 'Beneficiary')} value={d.BeneficiaryPassDate} autocomplete="off" type="date" placeholder="Placeholder" disabled={disabled} />
						<label>Дата выдачи</label>
					</div>
				</div>
			</div>
			<div class="col-md-6">
				{/* <div className={`form-group mb-3 form-floating ${error.Beneficiary[i].BeneficiaryPassPlace && 'has-error'}`}>
					<input class="form-control" name="BeneficiaryPassPlace" onChange={(e) => changeArr(e, i, 'Beneficiary')} value={d.BeneficiaryPassPlace} placeholder="Иванов" />
					<label>Код подразделения</label>
				</div> */}
				<PassportPrompt
						error={error.Beneficiary[i].BeneficiaryPassPCode}
                            name="BeneficiaryPassPCode"
                            label="Код подразделения"
                            className='form-control'
                            value={d.BeneficiaryPassPCode}
                            onChange={changePassport('Beneficiary', 'BeneficiaryPassPlace', 'BeneficiaryPassPCode', i)}
							mask="999-999"
							disabled={disabled}
							onBlur={save} 
                        />
			</div>
		</div>
		{/* <div className={`form-group mb-3 form-floating ${error.Beneficiary[i].BeneficiaryRegistration && 'has-error'}`}>
			<input class="form-control" name="BeneficiaryRegistration" onChange={(e) => changeArr(e, i, 'Beneficiary')} value={d.BeneficiaryRegistration} placeholder="Иванов" />
			<label>Адрес регистрации</label>
		</div> */}
		<Address
				error={error.Beneficiary[i].BeneficiaryRegistration}
				name="BeneficiaryRegistration"
				className="form-control"
				onChange={changeAddress('Beneficiary', 'BeneficiaryRegistration', i)}
				value={d.BeneficiaryRegistration}
				label="Адрес регистрации"
				onBlur={save} 
				disabled={disabled}
			/>
	</div>
</div>

<div className='mb-3'>{data.Beneficiary.length !== 1 &&
                        <button
						onBlur={save} 
                            onClick={() => del('Beneficiary', i, 'Beneficiary')}
                            className='btn btn-ref' disabled={disabled}>
                            Удалить бенефициарного владельца
                        </button>}
                        </div>

{data.Beneficiary.length === (i + 1) && <button className='btn btn-ref' onClick={(e) => {
                            setError(error => ({...error, Beneficiary: [...error.Beneficiary, Beneficiary]}));
                            setData(data => ({
                                ...data,
                                Beneficiary: [...data.Beneficiary, {...Beneficiary, BeneficiaryCitizenship: 'РФ'}]
                            }))
                        }} onBlur={save}  disabled={disabled}>Добавить бенефициарного владельца</button>}
{/* <div class="form-group mb-3 mt-3">
	<label>
		<input type="checkbox" name="checkbox" /><span>ЮЛ-заявитель входит в группу компаний</span>
	</label>
</div> */}
</>) }

{/* <div class="row mt-5">
	<div class="col-md-8 col-lg-6">
		<div class="form-group mb-3 form-floating">
			<input class="form-control" placeholder="Иванов" />
			<label>Описание структуры ГК</label>
		</div>
	</div>
</div> */}

</>
}

export default Blank3