import React from "react";
import {Link} from 'react-router-dom';
import noPage from '../images/404.svg';

const NoPage = () => {
    return <div className="content-page">
        <div className="content-main">
            <div className="page-head mb-4">Страница не найдена</div>
            <div className="row my-4">
                <div className="col-lg-8">
                    <p>Мы позаботились о том, чтобы все вводимые в формы данные сохранялись автоматически. Будем
                        благодарны, если Вы сообщите о недоразумении нашим разработчикам – просто нажмите желтую
                        кнопку, и они получат отчет об ошибке.</p>
                    <div className="mt-4"><Link to="/"><a className="btn btn-base">Сообщить об ошибке</a></Link></div>
                    <div className="row mt-4">
                        <div className="col-auto">
                            <Link to="/"><a>Вернуться назад</a></Link>
                        </div>
                        <div className="col-auto">
                            <Link to='/'><a>Перейти на главную</a></Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 d-none d-lg-block">
                    <img className="page-404 w-100" src={noPage}/>
                </div>
            </div>
            <div style={{borderTop: '2px solid #004680'}}>
                <h2 className='mt-3'>На случай, если вы что-то искали</h2>
                <div className="row mt-4">
                    <form className="col-md-8 col-lg-6 d-flex flex-column flex-sm-row align-items-start">
                        <div className="form-group form-floating me-3 w-100 mb-3 mb-sm-0">
                            <input className="form-control" id="inputText1" placeholder="Placeholder"/>
                            <label htmlFor="inputText1">Введите первые буквы</label>
                        </div>
                        <Link to='/'><button className="btn btn-base btn-large">Найти</button></Link>
                    </form>
                </div>
            </div>
        </div>
        <div className="content-promo"/>
    </div>
}

export default NoPage
