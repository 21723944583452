import React, { useState,useEffect } from 'react'
import InnPrompt from "../../components/common/InnPrompt";
import style from '../../components/common/Adress.module.css';

let Pl = ({main,data,setData,error,setError,disabled,page}) => {

    // let [brand,setBrand] = useState(main.Brands)
    const [showTooltip, setShowTooltip] = React.useState(false)
    const [options, setOptions] = React.useState([]);
    const [optionsTwo, setOptionsTwo] = React.useState([]);


    let dis = !(!page.newKP || disabled)

    let add = () => {
        setData(data => ({...data, pl:[...data.pl,{PLTypeId:"1",RegionId:31,PLYear:2021,PLIsNew:"1"}]}))
        setError(error => ({...error, pl:[...error.pl,{}]}))
    }

    let del = (id) => {
        setData(data => ({...data,pl:data.pl.filter((e,i) => i !== id )} ))
    }


    let  reverseString = (str) => {

        if(str) {
            let splitString = String(str).split("");
        let reverseArray = splitString.reverse();
        let joinArray = reverseArray.join("");

        let num = joinArray.replace(/\s/g, '')
        let len = num.length
        let arr = []
        for (let i = 0; i < len; i++) {
          arr.push(num[i]);
          if ((i + 1) % 3 == 0 && i !== len - 1) {
            arr.push(' ');
          }
        }
        let newFormat = arr.reverse().join('');


        return newFormat;
        } else {
            return '';
        }
    }



    let change = (ev,id) => {

        if  (ev.target.name === 'PLPrice') {

            setData(data => ({...data,  pl: data.pl.map((e,i)=>{return id === i ? {...e, [ev.target.name]:ev.target.value} : e})}))

        } else {
            setData(data => ({...data,  pl: data.pl.map((e,i)=>{return id === i ? {...e, [ev.target.name]:ev.target.value} : e})}))
        }
       
        ev.persist()
    }

    let col = (ev,id) => {
        let test = 0;

        if (ev.target.name === 'PLQty') {
            let chek = true
            for (let index = 0; index < data.pl.length; index++) {
                if (index !== id) {
                    if (data.pl[index].PLQty && data.pl[index].PLPrice) {
                        test = test + (Number(data.pl[index].PLPrice.replace(/\s/g, '')) * Number(data.pl[index].PLQty))
                    }
                } else {
                    if (data.pl[index].PLPrice && ev.target.value && chek) {
                        test = test + (Number(data.pl[index].PLPrice.replace(/\s/g, '')) * Number(ev.target.value))
                        chek = false
                    }
                }
            }
           
            
            setData(data => ({...data,  price: test}))
        }

        ev.persist()
    }

    let sum = (ev,id) => {
        let test = 0;
        if (ev.target.name === 'PLPrice') {
            let chek = true
            for (let index = 0; index < data.pl.length; index++) {
                if (index !== id) {
                    if (data.pl[index].PLQty && data.pl[index].PLPrice) {
                        test = test + (Number(data.pl[index].PLPrice.replace(/\s/g, '')) * Number(data.pl[index].PLQty))
                    }
                } else {
                    if (data.pl[index].PLQty && ev.target.value && chek) {
                        test = test + (Number(ev.target.value.replace(/\s/g, '')) * Number(data.pl[index].PLQty))
                        chek = false
                    }
                }

            }


            setData(data => ({...data,  price: test}))
        }
        ev.persist()
    }

    const changeInn = (index) => (value, name, onlyValue=true) => {
        setData({
            ...data,
            pl: data.pl.map( (el, j) => {
                if (j === index) {
                    if (onlyValue) {
                        return {...el, [name]: value}
                    }
                    return {...el, PLSupplierINN: value.data.inn, PLSupplier: value.data.name.short_with_opf}
                }
                return el
            })
        })
    }

    const onBlur = () => {
        toggleTooltip();
        setTimeout(() => {
            setOptions([]);
            setOptionsTwo([]);
        }, 200);

    };

    const toggleTooltip = () => {
        setShowTooltip(!showTooltip)
    }

    const onMChange = (evt,i) => {

        let value = evt.currentTarget.value;

       
        data.pl[i].PLBrand = value;


        if (main.references) {
            main.references.PLType.filter(ee => ee.Id === data.pl[i].PLTypeId && 
  
                setOptions(ee.Brand.filter(function(e) {
                    // console.log(e.Name.toLowerCase().indexOf(value.toLowerCase())>= 0);
                   return e.Name.toLowerCase().indexOf(value.toLowerCase())>= 0
                }))
             
             
             );
        }


        // console.log( main.PLType.filter(ee => ee.Id === '2' && ee));

        // setOptions(main.PLType.map(ee => ee.Id === 1 && console.log(ee)));


      

        // console.log(data.pl[i].PLTypeId);
        // console.log(main.PLType.filter(ee => ee.Id === data.pl[i].PLTypeId && ee.Brand.filter(function(e){
        //     return e.toLowerCase().indexOf(value.toLowerCase())  >= 0;
        //  })));


        // setOptions(main.PLType.filter(ee => ee.Id === '1' && ee.Brand.filter(function(e){
        //     return e.toLowerCase().indexOf(value.toLowerCase())  >= 0;
        //  })));

    }



    const onMChangeTwo = (evt,i) => {

        let value = evt.currentTarget.value;

       
        data.pl[i].PLName = value;


        if (main.references) {
            main.references.PLType.filter(ee => ee.Id === data.pl[i].PLTypeId && 


                ee.Brand.filter(e => e.Name.toLowerCase() === data.pl[i].PLBrand.toLowerCase() && 
                setOptionsTwo(e.Model.filter(function(e) {
                    // console.log(e.Name.toLowerCase().indexOf(value.toLowerCase())>= 0);
                   return e.toLowerCase().indexOf(value.toLowerCase())>= 0
                }))
             
                )
                // setOptions(ee.Brand.filter(function(e) {
                //     // console.log(e.Name.toLowerCase().indexOf(value.toLowerCase())>= 0);
                //    return e.Name.toLowerCase().indexOf(value.toLowerCase())>= 0
                // }))
             
             
             );
        }


        // if (main.references) {
        //     main.references.PLType.filter(ee => ee.Id === data.pl[i].PLTypeId && 
  
        //         setOptionsTwo(ee.Brand.filter(function(e) {
        //             // console.log(e.Name.toLowerCase().indexOf(value.toLowerCase())>= 0);
        //            return e.Name.toLowerCase().indexOf(value.toLowerCase())>= 0
        //         }))
             
             
        //      );
        // }



    }



    

    const setCurrentOption = (value,i) => {
        data.pl[i].PLBrand = value;
    }

    const setCurrentOptionTwo = (value,i) => {
        data.pl[i].PLName = value;
    }

    return <>
    {error.pl.length > 0 && data.pl.map((e,i)=> <div key={i}><div className="form-group mb-3 form-floating">
        <select className="form-control form-select" name="PLTypeId" onChange={(ev)=>change(ev,i)} value={e.PLTypeId} disabled={dis}>
            {main.PLType.map((e,i)=> <option key={i} value={e.Id}>{e.Name}</option>)}
        </select>
        <label>Тип ПЛ</label>
    </div>

    {e.PLTypeId !== "8" && <div className={`form-group mb-3 form-floating ${error.pl[i].PLBrand && 'has-error'}`}>
        <input onBlur={onBlur} className="form-control" name="PLBrand" value={e.PLBrand} onChange={(ev)=>onMChange(ev,i)} placeholder="Марка" disabled={dis} autoComplete="off" />
        <label>Марка</label>
    </div>}

    {!!options.length &&
    <div className={style.dropDown} style={{maxHeight:'300px',overflowY:'auto'}}>
        {options.map( el => (
            <div className={style.dropDown__item}
            onClick={() => setCurrentOption(el.Name,i)}
            >{el.Name}</div>
            ))}
            </div>
            }

    <div className={`form-group mb-3 form-floating ${error.pl[i].PLName && 'has-error'}`}>
        <input onBlur={onBlur} className="form-control" name="PLName" value={e.PLName} onChange={(ev)=>onMChangeTwo(ev,i)} placeholder="Наименование / Модель / Комплектация" disabled={dis} autoComplete="off"  />
        <label>Наименование / Модель / Комплектация</label>
    </div>


    {!!optionsTwo.length &&
    <div className={style.dropDown} style={{maxHeight:'300px',overflowY:'auto'}}>
        {optionsTwo.map( el => (
            <div className={style.dropDown__item}
            onClick={() => setCurrentOptionTwo(el,i)}
            >{el}</div>
            ))}
            </div>
            }

    <div className={`form-group mb-3 form-floating ${error.pl[i].PLQty && 'has-error'}`}>
        <input className="form-control" name="PLQty" value={e.PLQty} onChange={(ev)=>{
            change(ev,i)
            col(ev,i)
        }} placeholder="Количество" disabled={dis} />
        <label>Количество</label>
    </div>

    <div className={`form-group mb-3 form-floating ${error.pl[i].PLPrice && 'has-error'}`}>
        <input className="form-control" name="PLPrice" value={reverseString(e.PLPrice)} onChange={(ev)=>{
            change(ev,i)
            sum(ev,i)
        }} placeholder="Стоимость за единицу" disabled={dis} />
        <label>Стоимость за единицу</label>
    </div>
    
    <div className="form-group mb-3 form-floating">
        <select className="form-control form-select" name='RegionId' value={e.RegionId} onChange={(ev)=>change(ev,i)} disabled={dis}>
            {main.Region.map((e)=> <option value={e.Id}>{e.Name}</option>)}
        </select>
        <label>Регион эксплуатации</label>
    </div>
    
    <div className="form-group mb-3 form-floating">
        <select className="form-control form-select" name='PLYear' value={e.PLYear} onChange={(ev)=>change(ev,i)} disabled={dis}>
            {data.years.map((e)=> <option>{e}</option>)}
        </select>
        <label>Год выпуска</label>
    </div>
    
    <div className="form-group mb-3">
        <label>
            <input type="radio" name={"radiogroup"+i} checked={e.PLIsNew === "1"} onClick={()=>setData(data => ({...data,  pl: data.pl.map((e,ii)=>{return i === ii ? {...e, PLIsNew:"1"} : e})}))} disabled={dis} />
            <span>Новый</span>
        </label>
    </div>
    
    <div className="form-group mb-3">
        <label>
            <input type="radio" name={"radiogroup"+i} checked={e.PLIsNew === "2"} onClick={()=>setData(data => ({...data,  pl: data.pl.map((e,ii)=>{return i === ii ? {...e, PLIsNew:"2"} : e})}))} disabled={dis} />
            <span>Подержанный</span>
        </label>
    </div>
    
    {/* <div className="form-group mb-3 form-floating">
        <input className="form-control" name="PLSupplier" value={e.PLSupplier} onChange={(ev)=>change(ev,i)} placeholder="Placeholder" />
        <label>Наименование (ИНН) поставщика</label>
    </div> */}

<InnPrompt
                    type="number"
                    value={e.PLSupplier}
                    error={error.pl[i].PLSupplier && error.pl[i].PLSupplierINN}
                    name="PLSupplier"
                    className='form-control'
                    label='Наименование поставщика'
                    // onBlur={() => updata()}
                    onChange={changeInn(i)}
                    onlyInn={false}
                    disabled={dis}
                />

    {data.pl.length !== 1 && <button className="btn btn-ref mb-3" onClick={()=>del(i)} disabled={dis}>Удалить предмет лизинга</button>}
    
   </div>)}

    
    {/* <button className="btn btn-ref" onClick={add} disabled={disabled}>Добавить предмет лизинга</button> */}
    
    </>
}

export default Pl
