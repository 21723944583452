import React from "react";
import SearchForm from "./common/SearchForm";
import SearchTable from "./common/SearchTable";
import SearchTabs from "./common/SearchTabs";
import {api} from "../../Run";
import data from "bootstrap/js/src/dom/data";

const Search = ({main}) => {

    const [activeTab, setActiveTab] = React.useState('orders')
    const [dataTable, setDataTable] = React.useState(null)
    const [search, setSearch] = React.useState('')
    const [dateBegin, setDateBegin] = React.useState('')
    const [dateEnd, setDateEnd] = React.useState('')
    const [appLeasingStatus, setAppLeasingStatus] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [anyMessage, setAnyMessage] = React.useState(false)

    const findData = () => {
        postSearch()
    }
    const postSearch = () => {
        api('test', {
            Put: "Search",
            action: 'AppLeasingSearch',
            CompanyId: main.CompanyId,
            SearchString: search,
            PeriodStart: dateBegin,
            PeriodEnd: dateEnd,
            AppLeasingStatus: appLeasingStatus,
            }
        ).then((response) => {
            console.log('responseSearch', response)
            if (response && response.hasOwnProperty('data')) {
                setDataTable(response.data.SearchResult)
                if (response.data.hasOwnProperty('Error')) {
                    setAnyMessage(true)
                    setMessage('Введите номер заявки')
                } else {
                    setDataTable(response.data.SearchResult)
                }
                setDataTable(response.data.SearchResult)
                console.log('responseSearchData', dataTable)
            }
        })
    };

    return (
        <div className="content-page">
            <div className="content-main">
                <div className="page-head mb-4">Поиск</div>

                {anyMessage && <div className='login-alert p-3 text-center mb-3'>
                    <div className='text-primary'>Внимание!</div>
                    <span>{message}</span>
                </div>}

                <SearchForm
                    findData={findData}
                    setSearch={setSearch}
                    setDateBegin={setDateBegin}
                    setDateEnd={setDateEnd}
                    dateBegin={dateBegin}
                    dateEnd={dateEnd}
                />

                <SearchTabs setAppLeasingStatus={setAppLeasingStatus} />

                <ul className="nav nav-tabs tabs-base d-none d-md-flex mt-4" id="myTab">
                    <li className="nav-item">
                        <button className={"nav-link" + (activeTab === 'orders' ? ' active' : '')} onClick={() => setActiveTab('orders')}>Заявки</button>
                    </li>
                </ul>

                <SearchTable name={activeTab} dataTable={dataTable} postSearch={postSearch}/>

            </div>
            <div className="content-promo"/>
        </div>
    )
}

export default Search
