import axios from 'axios'


const HOST_URL = '//lc.stone-xxi.ru';
const API_URL = HOST_URL + '/api/';

let api = async (url, data) => {
    console.log('api data', data)
    try {
        let response = await axios.post(
            API_URL + url,
            JSON.stringify({ ...data, token: localStorage.getItem('token') }),
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
        if (response.data.data) {
            if (response.data.data.Error == "Сессия работы завершена, необходимо авторизоваться опять") {
                localStorage.clear()
                window.location.reload()
            }

        }

        return response.data

    } catch (error) {
        setTimeout(() => api(url, data), 10000)
    }
}

let apiNew = async (url, data) => {

    try {

        let response = await axios.post(
            'https://api.asket.ru' + url,
            { ...data },
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )

        return response.data

    } catch (error) {
        setTimeout(() => api(url, data), 10000)
    }

}

let postRequest = async (url, data, headers = {}) => {
    try {
        let response = await axios.post(url, data, { headers: headers })
        return response.data
    } catch (e) {
        setTimeout(() => postRequest(url, data, headers), 10000)
    }
}

let getRequest = async (url, queryParams) => {
    try {
        // let response = await axios.get(HOST_URL + url, {params: queryParams,responseType: 'blob'})\
        let response = await axios({
            method: 'get',
            url: HOST_URL + url,
            params: queryParams,
            responseType: 'blob'
        });
        return response.data
    } catch (e) {
        // setTimeout(() => getRequest(url, queryParams), 10000)
    }
}


let up = async (url, data) => {
    try {
        let response = await axios.post(API_URL + url, data, { headers: { 'Content-Type': 'multipart/form-data' } })
        return response.data
    } catch (error) {
        setTimeout(() => api(url, data), 10000)
    }
}

let Button = (data) => {
    return data.param ? <span className={data.color}></span> : data.title
}

let Load = () => {
    return <div className='load-center'>
        <span className='load'></span>
    </div>
}


let ApiDataTa = async (query) => {

    var url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit";
    var token = "1fa4a716e7f67a3f0342d5be4e1a4adc98b779d9";

    try {
        let res = await axios.post(url, JSON.stringify({ query: query }), {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": "Token " + token
            }
        })
        return res.data
    } catch (error) {
        setTimeout(() => ApiDataTa(query), 10000)
    }
}


let Inn = async (query) => {

    var options = {
        method: "POST",
        url: "https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/json",
            "Authorization": "Token 599670fac2d78bb10384a4655f5b8fcc465125c2"
        },
        data: JSON.stringify({ query: query })
    }

    try {
        let res = await axios(options);

        return res.data.suggestions.length === 0 ? [] : { 'Адрес': res.data.suggestions[0].data.address.unrestricted_value, 'НаимЮЛ': res.data.suggestions[0].value }
    } catch (error) {
        setTimeout(() => ApiDataTa(query), 10000)
    }
}


let chTel = (t) => {

    if (t) {
        t = t.replace(/[^\d]/g, '')

        if (t.length == 11) {
            if (t.toString()[0] !== 7) {
                t = 7 + t.slice(1)
                t = t.substr(0, 1) + '(' + t.substr(1, 3) + ')' + t.substr(4, 3) + '-' + t.substr(7, 2) + '-' + t.substr(9, 2)
                return t
            } else {
                t = t.substr(0, 1) + '(' + t.substr(1, 3) + ')' + t.substr(4, 3) + '-' + t.substr(7, 2) + '-' + t.substr(9, 2)
                return t
            }
        } else if (t.length <= 11) {
            if (t.toString()[0] != 7) {
                t = 7 + t
                t = t.substr(0, 1) + '(' + t.substr(1, 3) + ')' + t.substr(4, 3) + '-' + t.substr(7, 2) + '-' + t.substr(9, 2)
                return t
            } else {
                t = t.substr(0, 1) + '(' + t.substr(1, 3) + ')' + t.substr(4, 3) + '-' + t.substr(7, 2) + '-' + t.substr(9, 2)
                return t
            }
        } else {
            return ''
        }

    } else {
        return ''
    }


}

let getRequestOne = async (url, data={}, headers={}, i = 0) => {

    try {
        let response = await axios.get(url, {params: data}, {headers: headers})
        return response.data
    } catch (e) {}
    
}


export {
    api,
    apiNew,
    chTel,
    up,
    Button,
    Load,
    ApiDataTa,
    Inn,
    getRequest,
    postRequest,
    getRequestOne,
    HOST_URL,
}
