import React from "react";
import {api} from "../../Run";
import TableRows from "./TableRows";
import Tel from "./Tel";

const ConfigNotations = ({main, reload}) => {

    const [data, setData] = React.useState(null);
    const [companyId, setCompanyId] = React.useState(main.CompanyId)
    const [isSave, setIsSave] = React.useState(false)
    const [saveMessage, setSaveMessage] = React.useState('')
    const [anyChecked, setAnyChecked] = React.useState(false)

    const getNotification = () => {
        api('request',
            {
                Get: 'NotificationsConfig',
                action: 'NotificationsEdit',
                CompanyId: companyId,
            })
            .then(
                response => {
                    if (response && response.hasOwnProperty('data')) {
                        setData(response.data)
                    }
                }
            )
    }

    const sendData = () => {
        let notifications = []
        data.Notifications.map(el => {
            el.Items.map(item => {
                notifications.push({
                    CompanyId: companyId,
                    EventCode: item.EventId,
                    byEmail: item.byEmail,
                    bySMS: item.bySMS,
                    byPush: item.byPush
                })
            })
        })

        api('request',
            {Put: 'NotificationsConfig', action: 'NotificationsEdit', Notifications: notifications}
        ).then(
            response => {
                if (response.data.msg) {
                    setIsSave(true)
                    setSaveMessage(response.data.msg)
                }
            }
        ).catch(
            error => {
                if (error.data.msg) {
                    setIsSave(true)
                    setSaveMessage(error.data.msg)
                }
            }
        )
    }

    React.useEffect(() => {
        getNotification()
    }, [])

    const onChangeRegistration = (category, name, i, value) => {
        setAnyChecked(true)
        setData({
            ...data,
            Notifications: data.Notifications.map(n => {
                if (n.Category === category) {
                    n.Items = n.Items.map((el, index) => {
                        if (index === i) {
                            el[name] = value ? '1' : '0'
                        }
                        return el
                    })
                }
                return n
            })
        })
    }
    return (
        <div className="content-page">
            <div className="content-main">
                <div className="page-head mb-4">Настройка уведомлений</div>

                {isSave && <div className='login-alert p-3 text-center mb-3'>
                <div className='text-primary'>Внимание!</div>
                <span>{saveMessage}</span>
            </div>}


                <Tel main={main} />

                {/* <div className="row">
                    <div className="col-auto text-nowrap">sender@stone-xxi.ru</div>
                    <div className="col-auto">
                        <div className="alert alert-success">Адрес электронной почты подтвержден</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto text-nowrap">8 (916) 981-19-90</div>
                    <div className="col-auto">
                        <div className="alert alert-danger">Номер телефона не верифицирован.
                            <a href="">Пройти верификацию</a>
                        </div>
                    </div>


                </div> */}

                <div className="table-responsive mt-4">
                    <table className="table text-nowrap mb-0">
                        <thead>
                        <tr className="position-relative">
                            <th>Тип</th>
                            <th>E-mail</th>
                            <th>SMS</th>
                            <th>Push</th>
                        </tr>
                        </thead>
                        <tbody>
                        <TableRows data={data} onChange={onChangeRegistration}/>
                        </tbody>
                    </table>
                </div>
                <button
                    className="btn btn-base mt-4"
                    disabled={!anyChecked}
                    onClick={sendData}>Сохранить
                </button>
            </div>
            <div className="content-promo"/>
        </div>
    )
}

export default ConfigNotations
