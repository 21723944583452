import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import {OverlayTrigger, Tooltip, Button} from "react-bootstrap";
import {api} from "../../Run";
import {Link} from "react-router-dom";

const UserList = ({main, reload}) => {

    const [data, setData] = React.useState(null);
    const [showData, setShowData] = React.useState(false)
    const [companyId, setCompanyId] = React.useState(main.CompanyId)
    const [message, setMessage] = React.useState('')
    const [anyMessage, setAnyMessage] = React.useState(false)

    const getCompanyStaff = () => {
        api('request',
            {
                Get: 'CompanyStaff',
                action: 'CompanyStaffShow',
                CompanyId: companyId,
            })
            .then(
                response => {
                    console.log('response', response)
                    if (response && response.hasOwnProperty('data')) {
                        if (response.data.hasOwnProperty('Error')) {
                            setAnyMessage(true)
                            setMessage(response.data.Error)
                        } else {
                            setData(response.data)
                        }
                        setShowData(true)
                    }
                }
            )
    }

    const sendInvitation = (userCompanyId) => {
        api('request',
            {
                Put: 'CompanyStaff',
                action: 'CompanyStaffInvite',
                UserCompanyId: userCompanyId,
            })
            .then(
                response => {
                    console.log('response', response)
                    if (response && response.hasOwnProperty('data')) {
                        setAnyMessage(true)
                        if (response.data.hasOwnProperty('Error')) {
                            setMessage(response.data.Error)
                        }
                        if (response.data.hasOwnProperty('msg')) {
                            setMessage(response.data.msg)
                        }
                    }
                }
            )
    }

    const putCompanyStaff = () => {
        const adminElement = data.AccessList.find(el => el.Name === 'Администратор')
        const isAdmin = data.FLCompany.filter(fl => {
            return fl.FLAccess[adminElement.Id]
        })
        if (isAdmin.length === 0) {
            setAnyMessage(true)
            setMessage('Хотябы один сотрудник должен быть Администратор')
            return
        }

        api('request',
            {
                Put: 'CompanyStaff',
                action: 'CompanyStaffShow',
                CompanyId: companyId,
                FLCompany: data.FLCompany.map(fl => {
                    return {
                        UserCompanyId: fl.UserCompanyId,
                        FLAccess: Object.entries(fl.FLAccess).map(el => {
                            return {
                                RoleId: el[0],
                                Access: el[1]
                            }
                        })
                    }
                })
            })
            .then(
                response => {
                    if (response && response.hasOwnProperty('data')) {
                        setAnyMessage(true)
                        if (response.data.hasOwnProperty('Error')) {
                            setMessage(response.data.Error)
                        }
                        if (response.data.hasOwnProperty('msg')) {
                            setMessage(response.data.msg)
                        }
                    }
                    console.log('response', response)
                }
            ).catch(
            error => {
                console.log('error', error)
            }
        )
    }

    React.useEffect(() => {
        getCompanyStaff()
    }, [])

    console.log('data', data)

    const onChangeRole = (userId, accId, e) => {
        const checked = e.currentTarget.checked
        setData({
            ...data,
            FLCompany: data.FLCompany.map(fl => {
                if (fl.Id === userId) {
                    fl.FLAccess[accId] = checked ? 1 : 0
                }
                return fl
            })
        })
    }

    return (
        <> {showData &&
        <div className="content-page">
            <div className="content-main">
                <div className="container">
                    <div className="page-head mb-4">Список пользователей</div>

                    {anyMessage && <div className='login-alert p-3 text-center mb-3'>
                        <div className='text-primary'>Внимание!</div>
                        <span>{message}</span>
                    </div>}

                    {!showData &&
                        <div className="row justify-content-between align-items-center mb-3">
                            <div className="col-auto mb-2">Сотрудники, которым предоставлен доступ в личный кабинет
                            </div>
                            <div className="col-auto mb-2">
                                <Link to={`/SettingRights/0`}>
                                    <a className="btn btn-base" href="">Добавить сотрудника</a>
                                </Link>
                            </div>
                        </div>}
                    <div className="user-list">
                        {data && Object.values(data.FLCompany).map(d => {
                            return <div className="user-list__item mb-4" key={d.Id}>
                                <div className="row mb-n3">
                                    <div className="col-lg-4 col-md-6 mb-3">
                                        <div className="h5">
                                            <span>{d.LastName} </span>
                                            <span>{d.FirstName} </span>
                                            <span>{d.MidName}</span>
                                        </div>
                                        <div className="text-secondary mb-2">{d.JobTitle}</div>
                                        <div className="mb-2">
                                            <a href={`mailto:${d.Email}`}>{d.Email}</a>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 mb-3">
                                        {data.AccessList.map(acc => {
                                            return (
                                                <div className="form-group mb-2" key={acc.Id}>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            checked={d.FLAccess[acc.Id]}
                                                            onChange={(e) => onChangeRole(d.Id, acc.Id, e)}
                                                        /><span>{acc.Name}</span>
                                                    </label>
                                                    {acc.Notes === '' ? null :
                                                    <OverlayTrigger
                                                        defaultShow={false}
                                                        trigger="click"
                                                        placement="top"
                                                        rootClose={true}
                                                        overlay={
                                                            <Tooltip
                                                                id="button-tooltip"
                                                                style={
                                                                    {
                                                                        position: 'absolute',
                                                                        top: '0',
                                                                        left: '0',
                                                                        maxHeight: "150px",
                                                                        overflowY: 'scroll'
                                                                    }
                                                                }
                                                            >
                                                                {acc.Notes}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <FontAwesomeIcon
                                                            style={{cursor: "pointer", position: 'relative'}}
                                                            className="icon-info align-self-start ms-2 text-primary"
                                                            icon={faInfoCircle}
                                                            title="Описание документа"
                                                        />
                                                    </OverlayTrigger>}
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="col-lg-4 text-lg-end mb-3">
                                        <div className="mb-2">
                                            <button
                                                onClick={() => sendInvitation(d.UserCompanyId)}
                                                className="btn btn-ghost btn-small bg-white"
                                            >Отправить приглашение
                                            </button>
                                        </div>
                                        <div className="mb-2">
                                            <Link to={`/SettingRights/${d.UserCompanyId}`}>
                                                <a className="btn btn-ghost btn-small">Изменить данные</a>
                                            </Link>

                                        </div>
                                        <div className="mb-2">
                                            <Link to={`/SettingRights/${d.UserCompanyId}`}>
                                                <a className="btn btn-ghost btn-small">Удалить</a>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })}

                        {/*<div className="mt-4">*/}
                        {/*    <ul className="pagination">*/}
                        {/*        <li className="page-item disabled">*/}
                        {/*            <a className="page-link" href="#" aria-label="Previous">*/}
                        {/*                <span aria-hidden="true">&laquo;</span>*/}
                        {/*            </a>*/}
                        {/*        </li>*/}
                        {/*        <li className="page-item active"><span className="page-link">1</span></li>*/}
                        {/*        <li className="page-item"><a className="page-link" href="#">2</a></li>*/}
                        {/*        <li className="page-item"><a className="page-link" href="#">3</a></li>*/}
                        {/*        <li className="page-item"><a className="page-link" href="#">4</a></li>*/}
                        {/*        <li className="page-item"><a className="page-link" href="#">5</a></li>*/}
                        {/*        <li className="page-item"><a className="page-link" href="#" aria-label="Next"><span*/}
                        {/*            aria-hidden="true">&raquo;</span></a></li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}

                        {!showData &&
                            <div className="mt-5">
                                <button className="btn btn-base" onClick={putCompanyStaff}>Сохранить изменения</button>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className="content-promo"/>
        </div>
        }
        </>
    )
}

export default UserList
