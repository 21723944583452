import {useEffect, useState} from 'react'

import Address from "../../../components/common/Address";
import PassportPrompt from "../../../components/common/PassportPrompt";

import {OverlayTrigger, Popover} from "react-bootstrap";

import style from './Doc.module.css';



let BlankF = ({save,error,d,i,changeArr,changeArrChe,changePassport,changeAddress,disabled}) => {

	let [info,setInfo] = useState(0)

	const popoverDownloadB = (
        <Popover id="downloadError">
            <div className={style.docNotes}>
                <span
                    className="tooltiptext tooltiptextError"
                >Бенефициарный владелец – физлицо, которое в конечном итоге прямо или косвенно владеет (имеет преобладающее участие более 25% в капитале) вышеуказанным Лизингополучателем, либо имеет возможность контролировать действия вышеуказанного Лизингополучателя.</span></div>
        </Popover>
    );

	const popoverDownloadP = (
        <Popover id="downloadError">
            <div className={style.docNotes}>
                <span
                    className="tooltiptext tooltiptextError"
                >Отмечается, если физ.лицо лицо является иностранным/международным/российским публичным должностным лицом или супругом(ой) или родственником по прямой восходящей и нисходящей линии (родитель и ребенок, дедушка, бабушка и внуки), полнородным или неполнородным братом (сестрой), усыновителем, усыновленным).
				По требованию Федерального закона от 07.08.2001 г. №115-ФЗ «О противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма»
				</span></div>
        </Popover>
    );

	const onToggle = () => {
        setInfo(false)
    };

    return <div class="row mt-3">
    	<div class="row">
    		<div class="col-md-8 col-lg-6 mb-3">
                <div className={`form-group mb-3 form-floating ${error.Founder[i].FounderFLLName && 'has-error'}`}>
    				<input  class="form-control" onBlur={save}  name="FounderFLLName" onChange={(e) => changeArr(e, i, 'Founder')} value={d.FounderFLLName && d.FounderFLLName} placeholder="Фамилия учредителя" disabled={disabled} />
    				<label>Фамилия учредителя</label>
    			</div>
    			<div class="row">
    				<div class="col-md-6">
                        <div className={`form-group mb-3 form-floating ${error.Founder[i].FounderFLFName && 'has-error'}`}>
    						<input class="form-control" onBlur={save}  name="FounderFLFName" onChange={(e) => changeArr(e, i, 'Founder')} value={d.FounderFLFName} placeholder="Иванов" disabled={disabled} />
    						<label>Имя учредителя</label>
    					</div>
    				</div>
    				<div class="col-md-6">
                        <div className={`form-group mb-3 form-floating ${error.Founder[i].FounderFLMName && 'has-error'}`}>
    						<input class="form-control" onBlur={save}  name="FounderFLMName" onChange={(e) => changeArr(e, i, 'Founder')} value={d.FounderFLMName} placeholder="Иванов" disabled={disabled} />
    						<label>Отчество учредителя</label>
    					</div>
    				</div>
    				<div class="col-md-6">
                        <div className={`form-group mb-3 ${error.Founder[i].FounderFLBD && 'has-error'}`}>
    						<div class="datepicker-wrapper form-floating">
    							<input class="form-control" onBlur={save}   name="FounderFLBD" onChange={(e) => changeArr(e, i, 'Founder')} value={d.FounderFLBD} autocomplete="off" type="date" placeholder="Placeholder" disabled={disabled} />
    							<label>Дата рождения</label>
    						</div>
    					</div>
    				</div>
    				<div class="col-md-6">
                        <div className={`form-group mb-3 form-floating ${error.Founder[i].FounderFLBPlace && 'has-error'}`}>
    						<input class="form-control" onBlur={save}  name="FounderFLBPlace" onChange={(e) => changeArr(e, i, 'Founder')} value={d.FounderFLBPlace} placeholder="Иванов" disabled={disabled} />
    						<label>Место рождения</label>
    					</div>
    				</div>
    			</div>
    		</div>
    		<div class="col-md-8 col-lg-6 mb-3">
    			<h5 class="mb-2">Паспорт</h5>
    			<div className={`form-group mb-3 form-floating ${error.Founder[i].FounderFLPassportSN && 'has-error'}`}>
    				<input class="form-control" onBlur={save}  name="FounderFLPassportSN" onChange={(e) => changeArr(e, i, 'Founder')} value={d.FounderFLPassportSN}  placeholder="Иванов" disabled={disabled} />
    				<label>Серия и номер паспорта</label>
    			</div>
    			<div className={`form-group mb-3 form-floating ${error.Founder[i].FounderFLCitizenship && 'has-error'}`}>
    				<select class="form-control form-select" onBlur={save}  name="FounderFLCitizenship" onChange={(e) => changeArr(e, i, 'Founder')} value={d.FounderFLCitizenship} disabled={disabled} >
    					<option>РФ</option>
    					<option>Иностранный гражданин</option>
    				</select>
    				<label>Гражданство</label>
    			</div>
    			{/* <div className={`form-group mb-3 form-floating ${error.Founder[i].FounderFLPassPlace && 'has-error'}`}>
    				<input class="form-control" name="FounderFLPassPlace" onChange={(e) => changeArr(e, i, 'Founder')} value={d.FounderFLPassPlace} placeholder="Иванов" />
    				<label>Место выдачи</label>
    			</div> */}
				<PassportPrompt
				error={error.Founder[i].FounderFLPassPlace}
                            name="FounderFLPassPlace"
                            label="Место выдачи"
                            className='form-control'
                            value={d.FounderFLPassPlace}
                            onChange={changePassport('Founder', 'FounderFLPassPlace', 'FounderFLPassPCode', i)}
							disabled={disabled}
							onBlur={save} 
                        />
    			<div class="row">
    				<div class="col-md-6">
                        <div className={`form-group mb-3 ${error.Founder[i].FounderFLPassDate && 'has-error'}`}>
    						<div class="datepicker-wrapper form-floating">
    							<input class="form-control" onBlur={save}  name="FounderFLPassDate" onChange={(e) => changeArr(e, i, 'Founder')} value={d.FounderFLPassDate} autocomplete="off" type="date" placeholder="Placeholder" disabled={disabled} />
    							<label>Дата выдачи</label>
    						</div>
    					</div>
    				</div>
    				<div class="col-md-6">
                        {/* <div className={`form-group mb-3 form-floating ${error.Founder[i].FounderFLPassPCode && 'has-error'}`}>
    						<input class="form-control" name="FounderFLPassPCode" onChange={(e) => changeArr(e, i, 'Founder')} value={d.FounderFLPassPCode} placeholder="Иванов" />
    						<label>Код подразделения</label>
    					</div> */}
						<PassportPrompt
						error={error.Founder[i].FounderFLPassPCode}
                            name="FounderFLPassPlace"
                            label="Код подразделения"
                            className='form-control'
                            value={d.FounderFLPassPCode}
                            onChange={changePassport('Founder', 'FounderFLPassPlace', 'FounderFLPassPCode', i)}
							mask="999-999"
							disabled={disabled}
							onBlur={save} 
                        />
    				</div>
    			</div>
    			{/* <div className={`form-group mb-3 form-floating ${error.Founder[i].FounderFLRegistration && 'has-error'}`}>
    				<input class="form-control" name="FounderFLRegistration" onChange={(e) => changeArr(e, i, 'Founder')} value={d.FounderFLRegistration} placeholder="Иванов" />
    				<label>Адрес регистрации</label>
    			</div> */}
				<Address
				error={error.Founder[i].FounderFLRegistration}
				name="FounderFLRegistration"
				className="form-control"
				onChange={changeAddress('Founder', 'FounderFLRegistration', i)}
				value={d.FounderFLRegistration}
				label="Адрес регистрации"
				disabled={disabled}
				onBlur={save} 
			/>
    		</div>
    		<div class="col-md-8 col-lg-6 mb-3">
    			<h5 class="mb-2">Участие в капитале</h5>
    			<div className={`form-group mb-3 form-floating ${error.Founder[i].FounderFLShare && 'has-error'}`}>
    				<input class="form-control" onBlur={save}  name="FounderFLShare" onChange={(e) => changeArr(e, i, 'Founder')} value={d.FounderFLShare} placeholder="Иванов" disabled={disabled} />
    				<label>Доля</label>
    			</div>
    			<div class="form-group mb-3">
    				<label>
    					<input type="checkbox" name='FounderFLIsBeneficiary'
                                    onClick={(e) => changeArrChe(e, i, 'Founder')}
                                    defaultChecked={d.FounderFLIsBeneficiary} disabled={disabled} /><span>Является бенефициаром</span>
    				</label><buttom onClick={()=>setInfo(1)} class="btn btn-ref icon-info note ms-2"></buttom>
					{info === 1 && <OverlayTrigger
                                trigger='click'
                                placement="auto"
                                rootClose={true}
                                show={true}
                                overlay={popoverDownloadB}
                                onToggle={onToggle}
                            >
                                <span style={{width: '0', height: '0'}}/>
                            </OverlayTrigger>}
    			</div>
    			<div class="form-group mb-3">
    				<label>
    					<input type="checkbox" name='FounderFLPDL'
                                    onClick={(e) => changeArrChe(e, i, 'Founder')}
                                    defaultChecked={d.FounderFLPDL} disabled={disabled} /><span>Является ПДЛ</span>
    				</label><buttom onClick={()=>setInfo(2)} class="btn btn-ref icon-info note ms-2"></buttom>
					{info === 2 && <OverlayTrigger
                                trigger='click'
                                placement="auto"
                                rootClose={true}
                                show={true}
                                overlay={popoverDownloadP}
                                onToggle={onToggle}
                            >
                                <span style={{width: '0', height: '0'}}/>
                            </OverlayTrigger>}
    			</div>
    		</div>
    	</div>
    </div>
}

export default BlankF