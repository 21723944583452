import { useState } from 'react'


import {Link} from 'react-router-dom'

import search from '../svg/iconSearch.svg'
import down from '../svg/Download.svg'
import downS from '../svg/Download-s.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

function End() {

    let [data,setData] = useState({m:{a:{info:false},b:{info:false},c:{info:false},d:{info:false},q:{info:false},w:{info:false}},add:[{name:'Заявка-анкета лизингополучателя'},{name:'Согласие на обработку персональных данных'}]})

    let mouse = (i) => {
        console.log(i);
        setData(data => ({...data, m:{...data.m, [i]:{info:false}}}))
    }

    let mouseOver = (i) => {
        setData(data => ({...data, m:{...data.m, [i]:{info:true}}}))
    }



    return <>
    {/* <div className='title'>Новый расчет на лизинг № Р2012115</div>


    
  
    <div className='mt-3'>
        <div className="btn-group btn-group-justified" role="group">
            <div className="btn-group" role="group">
                <Link to='/AppLeasing' type="button" className="btn btn-zv done ">Создание расчета</Link>
            </div>
            <div className="btn-group" role="group">
                <Link to='/kp' type="button" className="btn btn-zv done">Согласование КП</Link>
            </div>
            <div className="btn-group" role="group">
                <Link to='/blank' type="button" className="btn btn-zv done">Заполнение анкеты</Link>
            </div>
            <div className="btn-group" role="group">
                <Link to='/doc' type="button" className="btn btn-zv done">Сбор документов</Link>
            </div>
            <div className="btn-group" role="group">
                <Link to='/ok' type="button" className="btn btn-zv done">Одобрение сделки</Link>
            </div>
            <div className="btn-group" role="group">
                <Link to='/end' type="button" className="btn btn-zv active">Подписание договора</Link>
            </div>
        </div>
    </div> */}
    
    
    
    <div className='row'>
        <div className='col-sm-5'>
            
            <div className='row' style={{fontSize:'12px'}}>
                <div className='col-sm-12'>

                    <div className='row mt-4'>
                        <div className='col-xs-7 text-right'>№ договора:</div>
                        <div className='col-xs-5'>Л536754</div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-xs-7 text-right'>Дата заключения:</div>
                        <div className='col-xs-5'>07.12.2020</div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-xs-7 text-right'>Сумма договора:</div>
                        <div className='col-xs-5'>38 970 000</div>
                    </div>

                </div>
            </div>
            
        </div>
        <div className='col-sm-7' style={{fontSize:'12px'}}>
            
            
            <div className='row' style={{fontSize:'12px'}}>
                <div className='col-sm-12'>
                    {/* <div className='row mt-4'>
                        <div className='col-xs-7 text-right'>Наименование ЮЛ:</div>
                        <div className='col-xs-5'>ООО "АБВ"</div>
                    </div> */}
                    <div className='row mt-4'>
                        <div className='col-xs-7 text-right'>Сумма аванса:</div>
                        <div className='col-xs-5'>389 000</div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-xs-7 text-right'>Очередной ежемесячный платеж:</div>
                        <div className='col-xs-5'>25 700</div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-xs-7 text-right'>Дата акта приемки-передачи ПЛ:</div>
                        <div className='col-xs-5'>-</div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    
    
    
    <div className='title-blank mt-3 pb-0' style={{borderTop:'0'}}>
        <label className="container-checkbox mb-0">
            Шаг 1 из 5 Подписание документов
            <input type="checkbox"  checked disabled />
            <span className="checkmark"></span>
        </label>
    </div>
    
    
    <div className='row'>
        <div className='col-xs-12'>
            <div className='box-info box-info-left-no-title mt-4 px-3'>
               <p>Раздел содержит полный пакет документов по сделке, включая платежные документы для оплаты, договоры и закрывающие документы. Переход к этупа получения предмета лизинга осуществляется после загрузки подписанных лизингополучателем документов и оплаты обязательных платежей.</p> 
               <p>При загрузке документов выполняется автоматическая проверка на соответствие формату и корректности содержимого. В случае, если документ не прошел автоматическую проверку, его необходимо подгрузить повторно, выполнив требования в описании к документу, для перехода к следующим шагам подписания сделки.</p> 
            </div>
        </div>
    </div>



    <div className='row mt-0'>
        <div className='col-sm-12'>
            <div className="table-responsive">
                <table className="table">
                    <tr>
                        <td style={{position:'absolute',width:'100%',height:'65px'}}><div style={{float:'left'}}><img style={{margin:'0 0 -4px 0'}} src={search} /> <img style={{margin:'0 15px -3px 15px'}} src={down} /> Договор лизинга </div>
                        &ensp; <FontAwesomeIcon style={{color:'#999999'}} onMouseOut={() => mouse('a')} onMouseOver={() => mouseOver('a')} icon={faInfoCircle} />
                        {data.m.a.info ? <div className="tooltip" ><span className="tooltiptext" style={{width:'auto',left:'-15%'}}>Описание из бэкенда</span></div> : ''}
                        </td>
                        <td style={{minWidth:'240px',width:'110px'}}>
                            
                            <label className="container-checkbox mb-0" style={{right:'15px',position:'absolute'}}>
                                
                                <input type="checkbox" checked disabled />
                                <span className="checkmark"></span>
                            </label>
                            
                            <a><img style={{margin:'0 10px -4px 0'}} src={search} /> Загрузить договор с ЭЦП </a>

                            <div className="mt-2" style={{marginLeft:'-100px'}}>
                            <label className="container-checkbox mb-0" style={{right:'15px',position:'absolute'}}>
                                
                                <input type="checkbox" disabled />
                                <span className="checkmark"></span>
                            </label>
                            
                            <img style={{margin:'0 10px -4px 0'}} src={search} /> Загрузить договор с обычной подписью 
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td style={{position:'absolute',width:'100%',height:'65px'}}><div style={{float:'left'}}><img style={{margin:'0 0 -4px 0'}} src={search} /> <img style={{margin:'0 15px -3px 15px'}} src={down} /> Договор поручительства </div>
                        &ensp; <FontAwesomeIcon style={{color:'#999999'}} onMouseOut={() => mouse('b')} onMouseOver={() => mouseOver('b')} icon={faInfoCircle} />
                        {data.m.b.info ? <div className="tooltip" ><span className="tooltiptext" style={{width:'auto',left:'-15%'}}>Описание из бэкенда</span></div> : ''}
                        </td>

                        <td style={{minWidth:'240px',width:'110px'}}>
                            
                            <label className="container-checkbox mb-0" style={{right:'15px',position:'absolute'}}>
                                
                                <input type="checkbox" checked disabled />
                                <span className="checkmark"></span>
                            </label>
                            
                            <a><img style={{margin:'0 10px -4px 0'}} src={search} /> Загрузить договор с ЭЦП </a>

                            <div className="mt-2" style={{marginLeft:'-100px'}}>
                            <label className="container-checkbox mb-0" style={{right:'15px',position:'absolute'}}>
                                
                                <input type="checkbox" disabled />
                                <span className="checkmark"></span>
                            </label>
                            
                            <img style={{margin:'0 10px -4px 0'}} src={search} /> Загрузить договор с обычной подписью 
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td style={{position:'absolute',width:'100%', borderBottom: '0px'}}><div style={{float:'left'}}><img style={{margin:'0 0 -4px 0'}} src={search} /> <img style={{margin:'0 15px -3px 15px'}} src={down} /> Котировка страхования ПЛ </div>
                     
                        </td>
                        <td style={{minWidth:'110px',width:'110px',borderBottom: '0px'}}>
                            
                      
                        </td>
                    </tr>

                    

                </table>
            </div>

        </div>
    </div>

<p className='mb-1'>Выберите страховую компанию для формирования счета на страховку. Счет появится ниже в списке документов.</p>

    <div className='row'>
        <div className='col-xs-12 col-md-5'>
            
            <label className="has-float-label mt-3">
                <select className="form-control">
                    <option>Альфа-Страхование</option>
                </select>
                <span>Название страховой компании</span>
            </label>

        </div>
    </div>




    <div className='row mt-0'>
        <div className='col-sm-12'>
            <div className="table-responsive">
                <table className="table mb-0">
                   

                    <tr>
                        <td style={{width:'100%'}}><div style={{float:'left'}}><img style={{margin:'0 0 -4px 0'}} src={search} /> <img style={{margin:'0 15px -3px 15px'}} src={downS} /> Счет на страховку </div>
                     
                        </td>
  
                    </tr>


                    <tr>
                        <td style={{width:'100%', borderBottom: '0px'}}><div style={{float:'left'}}><img style={{margin:'0 0 -4px 0'}} src={search} /> <img style={{margin:'0 15px -3px 15px'}} src={down} /> Счет на аванс </div>
                     
                        </td>
         
                    </tr>

                    

                </table>
            </div>

        </div>
    </div>
    
    
    <div className='title-blank mt-0 pb-0'>
        
        <label className="container-checkbox mb-0">
            Шаг 2 из 5 Оплата аванса
             <input type="checkbox"  checked disabled />
             <span className="checkmark"></span>
        </label>
    
    </div>

    <div className='row'>
        <div className='col-xs-12'>
            <div className='box-info box-info-left-no-title mt-4 px-3'>
               <p>Ожидается оплата авансового платежа. Для ускорения перехода на шаг передачи предмета лизинга подгрузите платежное банковское поручение с отметкой “ПРОВЕДЕНО”. Допустимый формат файла – pdf, jpg, jpeg, png, gif.</p> 
            </div>


            <div className="text-right my-3" style={{paddingRight:'40px'}}>

            <label className="container-checkbox mb-0" style={{right:'15px',position:'absolute'}}>
                                
                                <input type="checkbox" checked disabled />
                                <span className="checkmark"></span>
                            </label>
                            
                            <a><img style={{margin:'0 10px -4px 0'}} src={search} /> Загрузить платежное поручение </a>


            </div>



        </div>
    </div>



    <div className='title-blank mt-0 pb-0'>
        
        <label className="container-checkbox mb-0">
            Шаг 3 из 5 Оплата страховки
             <input type="checkbox"  checked disabled />
             <span className="checkmark"></span>
        </label>
    
    </div>

    <div className='row'>
        <div className='col-xs-12'>
            <div className='box-info box-info-left-no-title mt-4 px-3'>
               <p>Ожидается оплата страховки для предмета лизинга. Для ускорения перехода на шаг передачи предмета лизинга подгрузите платежное банковское поручение с отметкой “ПРОВЕДЕНО”. Допустимый формат файла – pdf, jpg, jpeg, png, gif.</p> 
            </div>


            <div className="text-right my-3" style={{paddingRight:'40px'}}>

            <label className="container-checkbox mb-0" style={{right:'15px',position:'absolute'}}>
                                
                                <input type="checkbox" checked disabled />
                                <span className="checkmark"></span>
                            </label>
                            
                            <a><img style={{margin:'0 10px -4px 0'}} src={search} /> Загрузить платежное поручение </a>


            </div>



        </div>
    </div>
    


    <div className='title-blank mt-0 pb-0'>
        
        <label className="container-checkbox mb-0">
        Шаг 4 из 5 Назначение ответственного лица для получения предметов лизинга
             <input type="checkbox"  checked disabled />
             <span className="checkmark"></span>
        </label>
    
    </div>

    <div className='row'>
        <div className='col-xs-12'>
            <div className='box-info box-info-left-no-title mt-4 px-3'>
               <p>Для получения предмета лизинга необходимо указать контактные данные ответственного лица, а также заполнить и подписать форму Согласия на обработку персональных данных по ссылке ниже. В случае, если Согласие было получено ранее, переход к следующему шагу произойдет автоматически. </p> 
            </div>

        </div>
    </div>


    <div className='row'>
        <div className='col-xs-12 col-md-4 col-sm-4'>
            
            
            <form className='mt-3'>

                <label className="has-float-label">
                    <input type="text" name="email" id="first_name" required="required" required />
                    <span>Фамилия ответственного лица</span>
                </label>

                <label className="has-float-label mt-3">
                    <input type="text" name="email" id="first_name" required="required" required />
                    <span>Имя ответственного лица</span>
                </label>

                <label className="has-float-label mt-3">
                    <input type="text" name="email" id="first_name" required="required" required />
                    <span>Отчество ответственного лица</span>
                </label>

            </form>



        </div>


        <div className='col-xs-12 col-md-4 col-sm-4'>
            
            
            <form className='mt-3'>

                <label className="has-float-label">
                    <input type="text" name="email" id="first_name" required="required" required />
                    <span>E-mail</span>
                </label>
                
                <label className="has-float-label mt-3">
                    <input type="text" name="email" id="first_name" required="required" required />
                    <span>Телефон</span>
                </label>
                
                <label className="has-float-label mt-3">
                    <input type="text" name="email" id="first_name" required="required" required />
                    <span>Дата рождения</span>
                </label>
                
            </form>



        </div>


        <div className='col-xs-12 col-md-4 col-sm-4 pt-3'>
            <a>Скопировать данные руководителя</a>
        </div>

    </div>


    <div className='row'>
        <div className='col-xs-12'>
            <div className='mt-4' style={{maxWidth:'300px'}}>
                <button type="submit" className="btn btn-primary btn-block">Назначить ответственным лицом</button>
            </div>
        </div>
        <div className='col-xs-12'>
            <p className='mb-1'>Ссылка на форму Согласия для ответственного лица (доступна без регистрации):</p>
            <a>Длинная ссылка типа www.stone-xxi.ru/lk/check/processing/Р2012115</a>
        </div>
    </div>



    <div className='title-blank mt-3 pb-0'>
        
        <label className="container-checkbox mb-0">
            Шаг 5 из 5 Получение предметов лизинга
             <input type="checkbox"  checked disabled />
             <span className="checkmark"></span>
        </label>
    
    </div>

    <div className='row'>
        <div className='col-xs-12'>
            <div className='box-info box-info-left-no-title mt-4 px-3'>


            <p className='mb-0'>Список документов, необходимых для получения предмета лизинга:</p>
            <p className='mb-0 mt-0'>1. Паспорт</p>
            <p className='mb-0 mt-0'>2. Печать компании (кроме случаев получения материальных ценностей по разовой доверенности)</p>
            <p className='mb-0 mt-0'>3. Оригинал действующей доверенности на имя сотрудника в случае, если получатель не является лицом, указанным в Уставе компании</p>
            <p className='mb-0 mt-0'>4. Оригинал договора лизинга в случае, если получатель не является лицом, указанным в Уставе компании</p>
            <p className='mb-0 mt-0'>5. Подписанный оригинал анкеты лизингополучателя</p>
            <p className='mb-0 mt-0'>6. Подписанный оригинал согласия на обработку персональных данных.</p>
            <p className='mt-0'>Дата и место передачи появятся ниже.</p>
                
                
            </div>




            <div className='row'>
        <div className='col-sm-12'>
            
            <div className='row' style={{fontSize:'12px'}}>
                <div className='col-sm-12'>

                    <div className='row mt-4'>
                        <div className='col-xs-3 text-right'>Дата передачи ПЛ:</div>
                        <div className='col-xs-9'>07.12.2020</div>
                    </div>
                    <div className='row mt-2'>
                        <div className='col-xs-3 text-right'>Место передачи ПЛ:</div>
                        <div className='col-xs-9'>Москва, ул. Нагатинская, д.1</div>
                    </div>


                </div>
            </div>
            
        </div>
    </div>


    <div className='row'>
        <div className='col-xs-12'>
            <p><img style={{margin:'0 0 -4px 0'}} src={search} /> <img style={{margin:'0 15px -3px 15px'}} src={down} /> Доверенность на право подписания актов к Договору лизинга и получение предмета лизинга по форме компании</p>
            <p><img style={{margin:'0 0 -4px 0'}} src={search} /> <img style={{margin:'0 15px -3px 15px'}} src={down} /> Акт приемки-передачи к Договору лизинга</p>
            <p><img style={{margin:'0 0 -4px 0'}} src={search} /> <img style={{margin:'0 15px -3px 15px'}} src={down} /> Акт ввода в эксплуатацию к Договору лизинга</p>
        </div>
    </div>



        </div>
    </div>




<div className="mb-5">
    <div className='mt-4 mx-auto' style={{maxWidth:'300px'}}>
                <Link to='/kp' className="btn btn-primary btn-block">Подтвердить получение ПЛ</Link>
            </div>


            <div className='row mx-auto' style={{marginTop:'-15px'}}>
        <div className='col-sm-6'><Link to='/new'>Отменить сделку</Link></div>
        <div className='col-sm-6 text-right'><Link>Перейти в карточку договора</Link></div>
    </div>
    </div>
    </>
}

export default End
