import {useEffect, useState} from 'react'
import InnPrompt from "../../../components/common/InnPrompt";

let BlankU = ({save,error,d,i,changeArr,changeInnArrTwo,disabled}) => {


	const onChangeInn = (i,value, name, onlyValue=true) => {

		let ogrn,inn,nam;
        if (!onlyValue) {
            inn = value.data.inn
			ogrn = value.data.ogrn
			nam = value.data.name.short_with_opf
        }

		changeInnArrTwo('Founder', i, 'FounderULINN', 'FounderULIOGRN', 'FounderULName', inn, ogrn, nam)
		// changeArr('Founder', i, 'FounderULIOGRN', ogrn)
        // changeInnArr('Founder', i, 'FounderULName', value)
    };


    return <div class="row mt-3">
    	<div class="row">
    		<div class="col-md-8 col-lg-6 mb-3">
                {/* <div className={`form-group mb-3 form-floating ${error.Founder[i].FounderULINN && 'has-error'}`}>
    				<input class="form-control" name="FounderULINN" onChange={(e) => changeArr(e, i, 'Founder')} value={d.FounderULINN} placeholder="Иванов" />
    				<label>ИНН</label>
    			</div> */}
				<InnPrompt
			error={error.Founder[i].FounderULINN}
                            value={d.FounderULINN}
                            name="FounderULINN"
                            className='form-control'
                            label='ИНН'
                            onChange={onChangeInn}
							arr={true}
							i={i}
							disabled={disabled}
							onBlur={save} 
                        />
    			<div className={`form-group mb-3 form-floating ${error.Founder[i].FounderULIOGRN && 'has-error'}`}>
    				<input class="form-control" onBlur={save}  name="FounderULIOGRN" onChange={(e) => changeArr(e, i, 'Founder')} value={d.FounderULIOGRN} placeholder="Иванов" disabled={disabled} />
    				<label>ОГРН</label>
    			</div>
                {/* <div className={`form-group mb-3 form-floating ${error.Founder[i].FounderULName && 'has-error'}`}>
    				<input class="form-control" name="FounderULName" onChange={(e) => changeArr(e, i, 'Founder')} value={d.FounderULName} placeholder="Иванов" disabled={disabled} />
    				<label>Наименование ЮЛ</label>
    			</div> */}

<InnPrompt
			error={error.Founder[i].FounderULName}
                            value={d.FounderULName}
                            name="FounderULName"
                            className='form-control'
							onBlur={save} 
                            label='Наименование ЮЛ'
                            onChange={onChangeInn}
							arr={true}
							i={i}
							disabled={disabled}
                        />

    		</div>
    		<div class="col-md-8 col-lg-6 mb-3">
    			<h5 class="mb-2">Участие в капитале</h5>
    			<div className={`form-group mb-3 form-floating ${error.Founder[i].FounderULShare && 'has-error'}`}>
    				<input class="form-control" onBlur={save}  name="FounderULShare" onChange={(e) => changeArr(e, i, 'Founder')} value={d.FounderULShare} placeholder="Иванов" disabled={disabled} />
    				<label>Доля</label>
    			</div>
    			{/* <div class="form-group mb-3">
    				<label>
    					<input type="checkbox" name="checkbox" checked /><span>Является бенефициаром</span>
    				</label><a class="icon-info note ms-2" data-bs-trigger="focus" tabindex="0" role="button" data-bs-toggle="popover" data-bs-content="Какой-то текс"></a>
    			</div>
    			<div class="form-group mb-3">
    				<label>
    					<input type="checkbox" name="checkbox" /><span>Является ПДЛ</span>
    				</label><a class="icon-info note ms-2" data-bs-trigger="focus" tabindex="0" role="button" data-bs-toggle="popover" data-bs-content="Какой-то текс"></a>
    			</div> */}
    		</div>
    	</div>
    </div>
}

export default BlankU