import {useEffect, useState} from 'react'

import BlockF from './BlockF'
import BlockU from './BlockU'

import {
    FounderFL,
    FounderUL
} from '../ErrorInput'

let Blank2 = ({save,input,data,setData,error,setError,del,changeArr,changeArrChe,changePassport,changeAddress,changeInnArr,changeInnArrTwo,disabled}) => {


	return data.Founder.length > 0 &&  data.Founder.map((d, i) => <div class="row mt-3">
		<div class="col-auto">
			<div class="form-group mb-3">
				<label>
					<input type="radio" name={'radiogroup'+i} onClick={() => {
						setError(error => ({
							...error, 
							Founder:  [...error.Founder.map((dd, ii) => (i !== ii ? dd : {...FounderFL}))]
						}))
						setData(data => ({
							...data,
							Founder: [...data.Founder.map((dd, ii) => (i !== ii ? dd : {...FounderFL,FounderFLCitizenship: 'РФ'}))]
						})) 
					}} checked={d.type} disabled={disabled} /><span>Физическое лицо</span>
				</label>
			</div>
		</div>
		<div class="col-auto">
			<div class="form-group mb-3">
				<label>
					<input type="radio" name={'radiogroup'+i} onClick={() => {
						setError(error => ({
							...error, 
							Founder:  [...error.Founder.map((dd, ii) => (i !== ii ? dd : {...FounderUL}))]
						}))
						setData(data => ({
							...data,
							Founder: [...data.Founder.map((dd, ii) => (i !== ii ? dd : {...FounderUL}))]
						}))
						}} checked={!d.type} disabled={disabled} /><span>Юридическое лицо</span>
				</label>
			</div>
		</div>
		{d.type ? <BlockF save={save} input={input} error={error} d={d} i={i} changeArr={changeArr} changeArrChe={changeArrChe} changePassport={changePassport} changeAddress={changeAddress} disabled={disabled}/> : <BlockU save={save} input={input} changeInnArrTwo={changeInnArrTwo} error={error} d={d} i={i} changeArr={changeArr} disabled={disabled} />}
		<div>
		<div className='mb-3'>{data.Founder.length !== 1 && <button type='button' onClick={() => del('Founder', i)} className='btn btn-ref' onBlur={save}  disabled={disabled}>Удалить учредителя</button>}</div>
		<div>{data.Founder.length === (i + 1) && <button onClick={(e) => {
				setError(error => ({...error, Founder: [...error.Founder, FounderFL]}))
				setData(data => ({
					...data,
					Founder: [...data.Founder, {...FounderFL, FounderFLCitizenship: 'РФ'}]
				}))
			}} className='btn btn-ref' onBlur={save}  disabled={disabled}>Добавить учредителя</button>}</div>
		</div>
	</div>)
	
}

export default Blank2