import React from "react";

const TableRows = ({data, onChange}) => {

    const onChangeEmail = (category, name, i) => (e) => {
        onChange(category, name, i, e.currentTarget.checked)
    }

    return (<>
        {data && data.Notifications && data.Notifications.map((el) => {
            return <>
                <tr className="position-relative">
                    <td colSpan="4">
                        <h6 className="pb-3 pt-3 m-0">{el.Category}</h6>
                    </td>
                </tr>
                {el.Items.map((item, jj) => {
                    return <tr className="position-relative" key={jj}>
                        <td>{item.Name}</td>
                        <td>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={parseInt(item.byEmail)}
                                    onChange={onChangeEmail(el.Category, 'byEmail', jj)}
                                /><span>&#8203;</span>
                            </label>
                        </td>
                        <td>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={parseInt(item.bySMS)}
                                    // onChange={onChangeEmail(el.Category, 'bySMS', jj)}
                                /><span>&#8203;</span>
                            </label>
                        </td>
                        <td>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={parseInt(item.byPush)}
                                    // onChange={onChangeEmail(el.Category, 'byPush', jj)}
                                /><span>&#8203;</span>
                            </label>
                        </td>
                    </tr>
                })}
            </>
        })}
    </>)
}

export default TableRows
