import {useEffect, useState} from 'react'

let Blank8 = ({save,input,setInputs,error,changeMain,disabled}) => {
    return <div class="row mt-3 flex-column">
	<div class="col-md-8 col-lg-6">
	<div className={`form-group mb-3 form-floating ${error.main.AdditionalInfo && 'has-error'}`}>
			<input class="form-control" onBlur={save}  name="AdditionalInfo" onChange={changeMain} value={input.AdditionalInfo} placeholder="Иванов" disabled={disabled} />
			<label>Дополнительная информация</label>
		</div>
	</div>
</div>
}

export default Blank8