import {useEffect, useState} from 'react'
import InnPrompt from "../../../components/common/InnPrompt";
import Address from "../../../components/common/Address";
import InputMask from "react-input-mask";
let Blank1 = ({save,input,setInputs,error,changeMain, changeAddress, handleChangeInn, disabled}) => {


	let copy = (type) => {
        if (type) {
            setInputs(input => ({
                ...input,
                SignerLName: input.DirectorLName,
                SignerFName: input.DirectorFName,
                SignerMName: input.DirectorMName,
                SignerPosition: input.DirectorPosition,
                SignerBD: input.DirectorBD
            }))
        } else {
            setInputs(input => ({
                ...input,
                ContactLName: input.DirectorLName,
                ContactFName: input.DirectorFName,
                ContactMName: input.DirectorMName,
                ContactPosition: input.DirectorPosition,
                ContactBD: input.DirectorBD
            }))
        }
    };



    return <div className="row mt-3">
        <div className="col-md-8 col-lg-6 mb-3">
            <h5 className="mb-2">Реквизиты и контактные данные</h5>
            {/* <div className={`form-group mb-3 form-floating ${error.main.LPINN && 'has-error'}`}>
			<input className="form-control" name='LPINN' value={input.LPINN} onChange={changeMain} placeholder="Placeholder" />
            <label>ИНН</label> */}
			<InnPrompt
			error={error.main.LPINN}
                            value={input.LPINN}
                            name="LPINN"
                            className='form-control'
                            label='ИНН'
                            onChange={handleChangeInn}
							disabled={disabled}
							onBlur={save}
                        />
        {/* </div> */}
        {/* <div className={`form-group mb-3 form-floating ${error.main.AddressFact && 'has-error'}`}> */}
			
			<Address
			error={error.main.AddressFact}
			name="AddressFact"
			className="form-control"
			onChange={changeAddress('AddressFact')}
			value={input.AddressFact}
			label="Фактический адрес"
			disabled={disabled}
			onBlur={save}
			/>
            {/* <input className="form-control" name='AddressFact' value={input.AddressFact} onChange={changeMain} placeholder="Placeholder" />
			<label>Фактический адрес</label> */}
		{/* </div> */}
		<div className={`form-group mb-3 form-floating ${error.main.OGRN && 'has-error'}`}>
			<input className="form-control" onBlur={save} name='OGRN' value={input.OGRN} onChange={changeMain} placeholder="Placeholder" disabled={disabled} />
			<label>ОГРН</label>
		</div>
		<div className={`form-group mb-3 form-floating ${error.main.OKPO && 'has-error'}`}>
			<input className="form-control" onBlur={save} name='OKPO' value={input.OKPO} onChange={changeMain} placeholder="Placeholder" disabled={disabled} />
			<label>ОКПО</label>
		</div>
		<div className="row">
			<div className="col-md-6">
				<div className={`form-group mb-3 ${error.main.RegDate && 'has-error'}`}>
					<div className="datepicker-wrapper form-floating">
						<input className="form-control" onBlur={save} name='RegDate' value={input.RegDate} onChange={changeMain} autocomplete="off" type="date" placeholder="Placeholder" disabled={disabled} />
						<label>Дата регистрации</label>
					</div>
				</div>
			</div>
			<div className="col-md-6">
				<div className={`form-group mb-3 ${error.main.StartDate && 'has-error'}`}>
					<div className="datepicker-wrapper form-floating">
						<input className="form-control"  onBlur={save} name='StartDate' value={input.StartDate} onChange={changeMain} autocomplete="off" type="date" placeholder="Placeholder"  disabled={disabled}/>
						<label>Дата начала работы</label>
					</div>
				</div>
			</div>
		</div>
		<div className={`form-group mb-3 form-floating ${error.main.EmployeesNumber && 'has-error'}`}>
			<input className="form-control" onBlur={save}  name='EmployeesNumber' value={input.EmployeesNumber} onChange={changeMain} placeholder="Placeholder" disabled={disabled} />
			<label>Кол-во сотрудников</label>
		</div>
		<div className={`form-group mb-3 form-floating ${error.main.DocType && 'has-error'}`}>
			<select className="form-control form-select" onBlur={save} name='DocType' value={input.DocType} onChange={changeMain} disabled={disabled}>
				<option>Устав</option>
				<option>Доверенность</option>
				<option>Договор</option>
				<option>Свид-во ЕГРИП за ОГРНИП</option>
			</select>
			<label>Вид документа-основания</label>
		</div>
		{input.DocType !== "Устав" && <div className="row">
			<div className="col-md-6">
				<div className={`form-group mb-3 form-floating ${error.main.DocNumber && 'has-error'}`}>
					<input className="form-control" onBlur={save} name='DocNumber' value={input.DocNumber} onChange={changeMain} placeholder="Placeholder" disabled={disabled} />
					<label>Номер документа</label>
				</div>
			</div>
			<div className="col-md-6">
				<div className={`form-group mb-3 ${error.main.DocDate && 'has-error'}`}>
					<div className="datepicker-wrapper form-floating">
						<input className="form-control" onBlur={save} name='DocDate' value={input.DocDate} onChange={changeMain} autocomplete="off" type="date" placeholder="Placeholder" disabled={disabled} />
						<label>Дата документа</label>
					</div>
				</div>
			</div>
		</div>}
		<div className={`form-group mb-3 form-floating ${error.main.WebSite && 'has-error'}`}>
			<input className="form-control" onBlur={save} name='WebSite' value={input.WebSite} onChange={changeMain} placeholder="Placeholder" disabled={disabled} />
			<label>Web-сайт</label>
		</div>
		<div className={`form-group mb-3 form-floating ${error.main.Phone && 'has-error'}`}>
		<InputMask className="form-control"  mask="+7(999)999-99-99" onBlur={save} name='Phone' value={input.Phone} onChange={changeMain} placeholder="Placeholder" disabled={disabled}/>
			{/* <input className="form-control" onBlur={save} name='Phone' value={input.Phone} onChange={changeMain} placeholder="Placeholder" disabled={disabled} /> */}
			<label>Телефон</label>
		</div>
	</div>
	<div className="col-md-8 col-lg-6">
		<h5 className="mb-2">Данные руководителя</h5>
		<div className={`form-group mb-3 form-floating ${error.main.DirectorLName && 'has-error'}`}>
			<input className="form-control" onBlur={save} name='DirectorLName' value={input.DirectorLName} onChange={changeMain} placeholder="Иванов" disabled={disabled} />
			<label>Фамилия руководителя</label>
		</div>
		<div className="row">
			<div className="col-md-6">
				<div className={`form-group mb-3 form-floating ${error.main.DirectorFName && 'has-error'}`}>
					<input className="form-control" onBlur={save} name='DirectorFName' value={input.DirectorFName} onChange={changeMain} placeholder="Иванов"  disabled={disabled}/>
					<label>Имя руководителя</label>
				</div>
			</div>
			<div className="col-md-6">
				<div className={`form-group mb-3 form-floating ${error.main.DirectorMName && 'has-error'}`}>
					<input className="form-control" onBlur={save} name='DirectorMName' value={input.DirectorMName} onChange={changeMain} placeholder="Иванов" disabled={disabled} />
					<label>Отчество руководителя</label>
				</div>
			</div>
			<div className="col-md-6">
				<div className={`form-group mb-3 ${error.main.DirectorBD && 'has-error'}`}>
					<div className="datepicker-wrapper form-floating">
						<input className="form-control" onBlur={save} name='DirectorBD' value={input.DirectorBD} onChange={changeMain} autocomplete="off" type="date" placeholder="Placeholder" disabled={disabled} />
						<label>Дата рождения руководителя</label>
					</div>
				</div>
			</div>
		</div>
		<div className="row mt-3 mb-2">
			<div className="col-auto">
				<h5 className="mb-0">Данные подписанта</h5>
			</div>
			<div className="col-auto"><button className="btn btn-ref" onBlur={save} onClick={()=>copy(true)} disabled={disabled}>Скопировать данные руководителя</button></div>
		</div>
		<div className={`form-group mb-3 form-floating ${error.main.SignerLName && 'has-error'}`}>
			<input className="form-control" onBlur={save} name='SignerLName' value={input.SignerLName} onChange={changeMain} placeholder="Иванов" disabled={disabled} />
			<label>Фамилия подписанта</label>
		</div>
		<div className="row">
			<div className="col-md-6">
				<div className={`form-group mb-3 form-floating ${error.main.SignerFName && 'has-error'}`}>
					<input className="form-control" onBlur={save} name='SignerFName' value={input.SignerFName} onChange={changeMain} placeholder="Иванов" disabled={disabled} />
					<label>Имя подписанта</label>
				</div>
			</div>
			<div className="col-md-6">
				<div className={`form-group mb-3 form-floating ${error.main.SignerMName && 'has-error'}`}>
					<input className="form-control" onBlur={save} name='SignerMName' value={input.SignerMName} onChange={changeMain} placeholder="Иванов" disabled={disabled} />
					<label>Отчество подписанта</label>
				</div>
			</div>
			<div className="col-md-6">
				<div className={`form-group mb-3 ${error.main.SignerBD && 'has-error'}`}>
					<div className="datepicker-wrapper form-floating">
						<input className="form-control" onBlur={save} name='SignerBD' value={input.SignerBD} onChange={changeMain}  autocomplete="off" type="date" placeholder="Placeholder" disabled={disabled} />
						<label>Дата рождения подписанта</label>
					</div>
				</div>
			</div>
			<div className="col-md-6">
				<div className={`form-group mb-3 form-floating ${error.main.SignerPosition && 'has-error'}`}>
					<input className="form-control" onBlur={save} name='SignerPosition' value={input.SignerPosition} onChange={changeMain}  placeholder="Иванов"  disabled={disabled}/>
					<label>Должность подписанта</label>
				</div>
			</div>
		</div>
		<div className="row mt-3 mb-2">
			<div className="col-auto">
				<h5 className="mb-0">Данные контактного лица</h5>
			</div>
			<div className="col-auto"><button className="btn btn-ref" onBlur={save} onClick={()=>copy(false)} disabled={disabled}>Скопировать данные руководителя</button></div>
		</div>
		<div className={`form-group mb-3 form-floating ${error.main.ContactLName && 'has-error'}`}>
			<input className="form-control" onBlur={save} name='ContactLName' value={input.ContactLName} onChange={changeMain} placeholder="Иванов" disabled={disabled} />
			<label>Фамилия контактного лица</label>
		</div>
		<div className="row">
			<div className="col-md-6">
				<div className={`form-group mb-3 form-floating ${error.main.ContactFName && 'has-error'}`}>
					<input className="form-control" onBlur={save} name='ContactFName' value={input.ContactFName} onChange={changeMain}  placeholder="Иванов" disabled={disabled} />
					<label>Имя контактного лица</label>
				</div>
			</div>
			<div className="col-md-6">
				<div className={`form-group mb-3 form-floating ${error.main.ContactMName && 'has-error'}`}>
					<input className="form-control" onBlur={save} name='ContactMName' value={input.ContactMName} onChange={changeMain}  placeholder="Иванов" disabled={disabled} />
					<label>Отчество контактного лица</label>
				</div>
			</div>
			<div className="col-md-6">
				<div className={`form-group mb-3 ${error.main.ContactBD && 'has-error'}`}>
					<div className="datepicker-wrapper form-floating">
						<input className="form-control" onBlur={save} name='ContactBD' value={input.ContactBD} onChange={changeMain} autocomplete="off" type="date" placeholder="Placeholder" disabled={disabled} />
						<label>Дата рождения контактного лица</label>
					</div>
				</div>
			</div>
			<div className="col-md-6">
				<div className={`form-group mb-3 form-floating ${error.main.ContactPosition && 'has-error'}`}>
					<input name='ContactPosition' onBlur={save} value={input.ContactPosition} onChange={changeMain} className="form-control" placeholder="Иванов" disabled={disabled} />
					<label>Должность контактного лица</label>
				</div>
			</div>
		</div>
	</div>
</div>
}

export default Blank1