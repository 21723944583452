import {useEffect, useState} from 'react'
import {Link, useParams, useHistory} from 'react-router-dom'
import {api, Button} from "../../Run"
import InnPrompt from '../../components/Company/InnPrompt'
import Address from "../../components/common/Address";

function CompanyId({main, reload}) {
    let {id} = useParams();
    let [input, setInput] = useState({
        ActivitySphereId: "18",
        AnnualRevenueId: "1",
        EDOServiceId: "123",
        EmployeesNumberId: "1"
    })
    let [page, setPage] = useState({com: '', new: false, id: id, errors: {}})
    let [load, setLoad] = useState({com: false})

    let [button, setButton] = useState({item: false})
    let history = useHistory()

    useEffect(() => {
        if (page.id !== 'add') {
            setPage(page => ({...page, Error: ''}))
            setPage(page => ({...page, new: false}))
            setLoad(load => ({...load, com: true}))
            if (!load.com) {
                setPage(page => ({...page, com: id}))
                api('request', {
                    "Get": "CompanyProfile",
                    "action": "CompanyProfileEdit",
                    "CompanyId": page.id
                }).then((d) => {
                    console.log('d', d)
                    setInput(input => ({
                        CompanyId: d.data.CompanyId,
                        Name: d.data.Name,
                        INN: d.data.INN,
                        OKPO: d.data.OKPO,
                        Address: d.data.Address,
                        EDOServiceId: d.data.EDOServiceId,
                        EmployeesNumberId: d.data.EmployeesNumberId,
                        AnnualRevenueId: d.data.AnnualRevenueId,
                        ActivitySphereId: d.data.ActivitySphereId
                    }))
                    setPage(page => ({...page, id: d.data.CompanyId ? d.data.CompanyId : 'add'}))
                    //  api('test',{"Get":"CompanyStaff","action":"CompanyStaffShow","CompanyId":"36",token:localStorage.getItem('token')}).then((d) => {
                    //     setInput(input => ({...input, FLCompany: d.data.FLCompany}))
                    //     setTimeout(function () {
                    //         setLoad(load => ({...load, com: false}))
                    //         // setPage(page => ({...page, com: d.data.CompanyId}))
                    //     },1000)
                    // })
    
                })
            }
        }
    }, [])





    const handleChangeInn = (value, name, onlyValue = true) => {
        if (onlyValue) {
            setInput({...input, [name]: value})
        } else {
            console.log(value.data);

            let ok = value.data.okpo && value.data.okato ? value.data.okpo + '/' + value.data.okato : ''
            setInput(input => ({
                ...input,
                INN: value.data.inn,
                OKPO: ok,
                Address: value.data.address.unrestricted_value,
                Name: value.data.name.short_with_opf
            }))
        }
    };

    let change = (e) => {
        setInput(input => ({...input, [e.target.name]: e.target.value}))
        e.persist()
    }

    let submit = (e) => {
        setButton(button => ({...button, item: true}))
        api('test', {
            "Put": "CompanyProfile",
            action: "CompanyProfileEdit", ...input,
            token: localStorage.getItem('token')
        }).then((d) => {


            if (d.data.Error) {
                setPage(page => ({...page, Error: d.data.Error, errors: d.data.errors ? d.data.errors : []}))
            } else {
                setPage(page => ({...page, Error: d.data.msg, errors: {}}))
                reload({
                    companies: [...main.companies.map((e, i) => e.id === input.CompanyId ? {
                        ...e,
                        name: input.Name
                    } : e)]
                })
            }

            setButton(button => ({...button, item: false}))
            // setInput(input => ({...input, ...d.data}))
        })

        e.preventDefault()
    }


    let create = (e) => {
        setButton(button => ({...button, create: true}))
        api('test', {
            "Put": "CompanyProfile", action: "CompanyProfileEdit", Name: input.Name,
            INN: input.INN,
            Address: input.Address,
            EDOServiceId: input.EDOServiceId,
            OKPO: input.OKPO,
            EmployeesNumberId: input.EmployeesNumberId,
            AnnualRevenueId: input.AnnualRevenueId,
            CompanyId: 0,
            ActivitySphereId: input.ActivitySphereId, token: localStorage.getItem('token')
        }).then((d) => {

            console.log(d)
            if (d.data.Error) {
                setPage(page => ({...page, Error: d.data.Error, errors: d.data.errors ? d.data.errors : []}))
            } else {

                // setPage(page => ({...page, id: d.data.CompanyId}))
                setPage(page => ({...page, Error: d.data.msg, id: d.data.CompanyId, errors: {}}))
                // setPage(page =>({...page,Error:''}))
                // document.location.reload();
                console.log(main);
                reload({companies: [...main.companies, {id: d.data.CompanyId, name: d.data.Data.Name}]})
                history.push('/Company/' + d.data.CompanyId)
            }
            setButton(button => ({...button, create: false}))
        })
        e.preventDefault()
    }

    let onChangeAddress = (d) => {
        setInput(input => ({...input, Address: d}))
    }


    useEffect(()=> {

        if (page.id !== 'add') {
        setPage(page => ({...page, Error: ''}))
        setPage(page => ({...page, new: false}))
        setLoad(load => ({...load, com: true}))
 
            setPage(page => ({...page, com: id}))
            api('request', {
                "Get": "CompanyProfile",
                "action": "CompanyProfileEdit",
                "CompanyId": id
            }).then((d) => {
                console.log('d', d)
                setInput(input => ({
                    CompanyId: d.data.CompanyId,
                    Name: d.data.Name,
                    INN: d.data.INN,
                    OKPO: d.data.OKPO,
                    Address: d.data.Address,
                    EDOServiceId: d.data.EDOServiceId,
                    EmployeesNumberId: d.data.EmployeesNumberId,
                    AnnualRevenueId: d.data.AnnualRevenueId,
                    ActivitySphereId: d.data.ActivitySphereId
                }))
                setPage(page => ({...page, id: d.data.CompanyId ? d.data.CompanyId : 'add'}))
                //  api('test',{"Get":"CompanyStaff","action":"CompanyStaffShow","CompanyId":"36",token:localStorage.getItem('token')}).then((d) => {
                //     setInput(input => ({...input, FLCompany: d.data.FLCompany}))
                //     setTimeout(function () {
                //         setLoad(load => ({...load, com: false}))
                //         // setPage(page => ({...page, com: d.data.CompanyId}))
                //     },1000)
                // })

            })
        }
    },[id])

    let pl = () => {
        // setPage(page => ({...page, Error: ''}))
        // setPage(page => ({...page, new: false}))
        // setLoad(load => ({...load, com: true}))
 
        //     setPage(page => ({...page, com: id}))
        //     api('request', {
        //         "Get": "CompanyProfile",
        //         "action": "CompanyProfileEdit",
        //         "CompanyId": main.CompanyId
        //     }).then((d) => {
        //         console.log('d', d)
        //         setInput(input => ({
        //             CompanyId: d.data.CompanyId,
        //             Name: d.data.Name,
        //             INN: d.data.INN,
        //             OKPO: d.data.OKPO,
        //             Address: d.data.Address,
        //             EDOServiceId: d.data.EDOServiceId,
        //             EmployeesNumberId: d.data.EmployeesNumberId,
        //             AnnualRevenueId: d.data.AnnualRevenueId,
        //             ActivitySphereId: d.data.ActivitySphereId
        //         }))
        //         setPage(page => ({...page, id: d.data.CompanyId ? d.data.CompanyId : 'add'}))
        //         //  api('test',{"Get":"CompanyStaff","action":"CompanyStaffShow","CompanyId":"36",token:localStorage.getItem('token')}).then((d) => {
        //         //     setInput(input => ({...input, FLCompany: d.data.FLCompany}))
        //         //     setTimeout(function () {
        //         //         setLoad(load => ({...load, com: false}))
        //         //         // setPage(page => ({...page, com: d.data.CompanyId}))
        //         //     },1000)
        //         // })

        //     })
        
        history.push('/Company/' + main.CompanyId)
    }

    return <div className="content-page">
        <div className="content-main">
            <div className="mb-3 page-head mb-4">Профиль организации</div>
            <div className="row">
                <div className="col-lg-6 col-md-8">
                    <div className='col-sm-12'>
                        {page.Error && <div className='login-alert p-3 text-center mb-3'>
                            <div className='text-primary'>Внимание!</div>
                            {page.Error}</div>}
                    </div>
                    <h5 className="mb-2">Реквизиты организации</h5>
                    {/* <div className={`form-group mb-3 form-floating ${page.errors.Name && 'has-error'}`}>
                        <input class="form-control" lass="form-control" name="Name" value={input.Name} onChange={change} required placeholder="Placeholder" />
                        <label>Наименование ЮЛ</label>
                    </div> */}
                    <InnPrompt errors={page.errors.Name} value={input.Name} name="Name" label='Наименование ЮЛ' disabled={Number(id)}
                               onChange={handleChangeInn} className="form-control"/>
                    <div className="row">
                        <div className="col-md-6">
                            <InnPrompt errors={page.errors.INN} value={input.INN} name="INN" label='ИНН' disabled={Number(id)}
                                       onChange={handleChangeInn} className="form-control"/>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-3 form-floating">
                                <input className="form-control" name="OKPO" value={input.OKPO} onChange={change} disabled={Number(id)}
                                       placeholder="Placeholder"/>
                                <label>ОКПО / ОКАТО</label>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-3 form-floating">
                        <select className="form-control form-select" name="EDOServiceId" value={input.EDOServiceId}
                                onChange={change} required>
                            {main.EDOList && main.EDOList.map((d, i) => {
                                return <option value={d.Id}>{d.Name}</option>
                            })}
                        </select>
                        <label>Оператор ЭДО</label>
                    </div>


                    {/* <div className={`form-group mb-3 form-floating ${page.errors.Address && 'has-error'}`}>
			<input class="form-control" name="Address" value={input.Address} onChange={change}  required placeholder="Placeholder" />
			<label>Почтовый адрес</label>
		</div> */}

                    <Address
                        error={page.errors.Address}
                        value={input.Address}
                        name='Address'
                        onChange={onChangeAddress}
                        label='Почтовый адрес'
                        className='form-control'
                    />

                    <h5 className="mb-2 mt-4">Сведения об организации</h5>
                    <div className="form-group mb-3 form-floating">
                        <select className="form-control form-select" name="ActivitySphereId" value={input.ActivitySphereId}
                                onChange={change} required>
                            {main.ActivitySphere && main.ActivitySphere.map((d, i) => {
                                return <option value={d.Id}>{d.Name}</option>
                            })}
                        </select>
                        <label>Сфера деятельности компании:</label>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group mb-3 form-floating">
                                <select className="form-control form-select" name="AnnualRevenueId"
                                        value={input.AnnualRevenueId} onChange={change} required>
                                    {main.AnnualRevenue && main.AnnualRevenue.map((d, i) => {
                                        return <option value={d.Id}>{d.Name}</option>
                                    })}
                                </select>
                                <label>Выручка компании за год</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group mb-3 form-floating">
                                <select className="form-control form-select" name="EmployeesNumberId"
                                        value={input.EmployeesNumberId} onChange={change} required>
                                    {main.EmployeesNumber && main.EmployeesNumber.map((d, i) => {
                                        return <option value={d.Id}>{d.Name}</option>
                                    })}
                                </select>
                                <label>Количество сотрудников</label>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-n3">
                        {page.id !== 'add' ?
                            <div className="col-auto mb-3">
                                <button className="btn btn-base" disabled={button.item} onClick={submit}>
                                    <Button title='Сохранить данные' color='btn-black-base' param={button.item}/>
                                </button>
                            </div> :
                            <div className="col-auto mb-3">
                                <button className="btn btn-ghost" onClick={create} disabled={button.create}>
                                    <Button title='Добавить компанию' color='btn-black-base' param={button.create}/>
                                </button>
                        </div>}
                        <div>
                            <button className="btn btn-base" disabled={button.item}>
                                <Link to="/UserList" className='text-dark'>Список пользователей</Link>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 mt-4 mt-lg-0">
                    <h5>Отправить запрос</h5>
                    <div className="row mt-4">
                        <div className="col-md-6">
                            <button onClick={()=>pl()} action="replace" className="btn btn-ref">Изменить данные ЛП</button>
                            {/* <div class="mt-2">Пояснительный текст к запросу. Может быть разной длины</div> */}
                        </div>
                        <div className="col-md-6">
                            <button className="btn btn-ref" disabled>Подключить ЭДО</button>
                            {/* <div class="mt-2">Пояснительный текст к запросу. Может быть разной длины</div> */}
                        </div>
                    </div>
                </div>
            </div>

            <section className="pb-5 mt-5">
                <Link class="icon icon-arrow-back" to='/CompanyList'>Вернуться к списку компаний</Link>
            </section>
        </div>

        <div className="content-promo"/>
    </div>


}

export default CompanyId
