import { useEffect, useState } from 'react'
import {Link} from 'react-router-dom'
import {api,Load} from "../../Run"
function OrgList({main,reload}) {

    let [data,setData] = useState({list:[]})
    let [load,setLoad] = useState({list:true})

    useEffect(()=> {
        setLoad(load =>({...load,list:true}))
        api('test',{"Get":"CompanyProfile","action":"CompanyList"}).then((d) => {
            if (d.data) {
                setData(data=> ({list:d.data}))
            }
            setLoad(load =>({...load,list:false}))
        })
    },[])


    if (load.list) {
        return <Load />
    } else {
        return <div class="content-page">
        <div class="content-main">
            <div class="page-head mb-4">Список организаций</div>
           {data.list.length > 0 ? <div class="mt-4">
                <div class="table-responsive">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr class="text-nowrap">
                                    <th class="table-sort">Предприятие</th>
                                    <th>ИНН</th>
                                    <th class="table-sort">Ваша должность</th>
                                    <th>Доступные разделы</th>
                                    <th>Заявки</th>
                                    {/* <th>Договоры</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {data.list.map((e,i)=><tr>
                                    <td class="text-nowrap"><Link to={'/Company/'+e.Id}>{e.name}</Link></td>
                                    <td class="text-nowrap">{e.inn}</td>
                                    <td class="text-nowrap">{e.Name}</td>
                                    <td>
                                        {e.menu.map((d)=><Link class="text-nowrap d-block" to={d.url}>{d.name}</Link>)}
                                        {/* <a class="text-nowrap d-block" href="">Заявки</a> */}
                                        {/* <a class="text-nowrap d-block" href="">Договоры</a><a class="text-nowrap d-block" href="">Фотоштрафы</a><a class="text-nowrap d-block" href="">Сделки</a><a class="text-nowrap d-block" href="">Вознаграждения</a> */}
                                    </td>
                                    <td>{e.ALcnt}</td>
                                    {/* <td><a href="">3</a></td> */}
                                </tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>  : ''}
            <div class="mt-4">
                <Link class="btn btn-base" to="/Company/add">Добавить организацию</Link>
            </div>
        </div>
        <div class="content-promo"></div>
    </div>


    }


}

export default OrgList