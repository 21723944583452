import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import i from "../../../svg/i.svg";
import {OverlayTrigger, Popover} from "react-bootstrap";
import style from './Doc.module.css';
import {getRequest} from "../../../Run";
import FileDownload from 'js-file-download';

const UploadBuhFile = ({setDocument, doc, page, link, disableUpload}) => {
    const token = localStorage.getItem('token');
    const [showErrorDownload, setShowErrorDownload] = React.useState(false);


    const createMarkup = (text) => {
        return {
            __html: text
        }
    };

    const handleChangeFile = (e) => {
        setDocument(doc.DocId, e.currentTarget.files, doc.DocTypeId);
    };

    const popoverDescription = (
        <Popover id="popover-basic" style={{background: 'none'}}>
            <div className={style.docNotes}>
                <span
                    dangerouslySetInnerHTML={createMarkup(doc.DocDescription)}
                />
            </div>
        </Popover>
    );

    const popoverError = (
        <Popover id="error">
            <div className="tooltip">
                <span
                    className="tooltiptext"
                    style={{zIndex: '100'}}
                    dangerouslySetInnerHTML={createMarkup(doc.DocNotes)}
                />
            </div>
        </Popover>
    );

    const popoverDownloadError = (
        <Popover id="downloadError">
            <div className="tooltip tooltipError">
                <span
                    className="tooltiptext tooltiptextError"
                >Ошибка скачивания файла. Нужна другая спецификация</span>
            </div>
        </Popover>
    );
    

    const handleDownloadFile = () => {
        getRequest('/api/fileDoc', {
            AppLeasingId: page.id,
            DocId: doc.DocId,
            token: token
        }).then(
            data => {
                if (!data) {
                    setShowErrorDownload(true)
                } else {
                    FileDownload(data, doc.DocFileName);
                }
            }
        )
    };

    const onToggle = () => {
        setShowErrorDownload(false)
    };


    return (
        <tr>
            <td className="w-100 text-nowrap">
                <div className="d-flex">
                    {
                        doc.isErrorRequired &&
                        <div className={style.errorDoc}>Обязательный документ</div>
                    }
                    {doc.DocStatus !== '0'
                        ? <>
                            <a className="d-flex align-items-center line-height-1_2" style={{cursor: 'pointer'}}
                               onClick={handleDownloadFile}>
                                <i className="icon-download me-2"/> {doc.DocName}
                            </a>
                            {showErrorDownload && <OverlayTrigger
                                trigger='click'
                                placement="auto"
                                rootClose={true}
                                show={true}
                                overlay={popoverDownloadError}
                                onToggle={onToggle}
                            >
                                <span style={{width: '0', height: '0'}}/>
                            </OverlayTrigger>}
                        </>
                        : <>
                            <i className="icon-download me-2"/> {doc.DocName}
                        </>
                    }

                    {/* {doc.DocLink === '' || doc.DocLink === ' '
                        ? <>{doc.DocName}</>
                        : <a href={doc.DocLink} target="_blank">{doc.DocName}</a>
                    } */}

                    <OverlayTrigger
                        trigger="click"
                        placement="auto"
                        rootClose={true}
                        overlay={popoverDescription}
                    >
                        <FontAwesomeIcon
                            style={{cursor: "pointer"}}
                            className="icon-info align-self-start ms-2 text-primary"
                            icon={faInfoCircle}
                            title="Описание документа"
                        />
                    </OverlayTrigger>
                </div>
            </td>

            <td>
            {doc.DocStatus !== '2' &&  <label className={style.download} className="btn btn-ref-link" style={{fontSize:'12px'}}>Загрузить
                    <input style={{display: 'none'}} type="file" onChange={handleChangeFile} disabled={disableUpload} multiple/>
                </label>}
            </td>

            <td>
                {doc.DocStatus === '0'
                    ? <div className="text-warning text-nowrap">Не загружен</div>
                    : doc.DocStatus === '1'
                        ? <div className="text-primary text-nowrap">Загружен</div>
                        : doc.DocStatus === '2'
                            ? <div className="text-success text-nowrap">Проверен</div>
                            : <><div className="text-danger text-nowrap">Не принято</div>
                                <div className="text-primary">{doc.DocNotes}</div></>
                }
            </td>
        </tr>
    );
};

export default UploadBuhFile;
