import React, { useState,useEffect } from 'react'
import {api,Button} from '../../Run'
import { Link, useHistory } from "react-router-dom"
import axios from 'axios'

import Calculator from './Calculator'
import Pl from './Pl'

let Payment = ({page, setPage, main, reload}) => {

    let [data, setData] = useState({id:page.id,pl:[{PLTypeId:"1",RegionId:31,PLYear:2021,PLIsNew:"1"}],years:[],type:'eq',adv:10,price:0,sl:24,calculator:{price:0,month:0}})
    let [error, setError] = useState({pl:[]})
    let [button, setButton] = useState({getQuote:false})
    let [msg,setMsg] = useState({title:'',msg:''})

    let history = useHistory();

    let disabled = !(page.AppLeasingStatus == 1 || page.AppLeasingStatus == 2 || !page.AppLeasingStatus)


    

    useEffect(() => {
        setPage(page =>( {...page, modNew:false}))
        let now = new Date().getUTCFullYear();
        let years = Array(now - (now - 20)).fill('').map((v, idx) => now - idx);
        setData(data => ({...data,years: years}))
        console.log('dddddddddddddddddddddddddddddd');
        console.log(page);

        
        
        axios.get("https://old.stone-xxi.ru/brands/calc_d.php?pmt_type="+data.type+"&adv="+data.adv+"&tsType=LA&fmt=JSON&modelId=343511&brand=BMW&model=BMW+2+Series+Gran+Coupe&compl=218i+Online+Edition&sl="+data.sl+"&price="+data.price).then((d) => {
            setData(data => ({...data, calculator:{price: d.data['Суммарный платеж'] ? d.data['Суммарный платеж'] : 0,month: d.data['Ежемесячный платеж'] ? d.data['Ежемесячный платеж'] : 0}}))
        })
        
        if (page.KPId !== 0 && data.id !== 0) {
            // if (page.tempK.KPId) {
            //     console.log(page.tempKp);
            //     setData(data => ({...data,pl: [{...page.tempKp,KPId:0}]}))
            //     setError(error => ({pl:[{}]}))
            // }
            // api('request',{"Get":"AppLeasingForm","action":"AppLeasingEdit","KPId":'649',"AppLeasingId":data.id,"CompanyId":main.CompanyId}).then((d) => {
            //     if (!d.data.Error) {
            //         let temp = [];
            //         let price = 0;
                    
            //         for (let index = 0; index < d.data.PLList.length; index++) {
            //             price = (price + Number(d.data.PLList[index].PLPrice)) * d.data.PLList[index].PLQty
            //             temp = [...temp, {}]
            //         }
                    
            //         if (temp.length > 0) {
            //             setData(data => ({...data,pl: d.data.PLList,price: price}))
            //             setError(error => ({...error,pl:temp}))
            //         } else {
            //             setData(data => ({...data,pl: [{PLTypeId:"1",PLYear:2021,RegionId:35,PLIsNew:"1"}],price: price}))
            //             setError(error => ({...error,pl:[{}]}))
            //         }


            //     } else {
            //         setData(data => ({...data,pl:[{PLTypeId:"1",PLYear:2021,RegionId:35,PLIsNew:"1"}],price: 0}))
            //         setError(error => ({pl:[{}]}))
            //     }
            // })


            api('request',{"Get":"KPCalcEdit","action":"KPRequest","KPId":page.KPId,"AppLeasingId":data.id}).then((d) => {
                let temp = [];
                                    let price = 0;
                    
                    for (let index = 0; index < d.data.PL.length; index++) {
                        price = (price + Number(d.data.PL[index].PLPrice)) * d.data.PL[index].PLQty
                        temp = [...temp, {}]
                    }
                    
                    if (temp.length > 0) {
                        setData(data => ({...data,...d.data,pl:d.data.PL,KPId:0,type:d.data.PmtType,sl:d.data.KPSl,adv:d.data.KPAdvance,price: price}))
                        setError(error => ({...error,pl:temp}))
                        setPage(page => ({...page,KPId:0}))
                    } else {
                        setData(data => ({...data,pl: [{PLTypeId:"1",PLYear:2021,RegionId:35,PLIsNew:"1"}],price: price}))
                        setError(error => ({...error,pl:[{}]}))
                    }



                // setData(data => ({...data,...d.data,pl:d.data.PL,KPId:0,type:d.data.PmtType,sl:d.data.KPSl,adv:d.data.KPAdvance}))
                // UpdateCalculation()
                // setPage(page => ({...page,KPId:0}))
                // setError(error => ({pl:[{}]}))
            })
        } else {
            setError(error => ({pl:[{}]}))
        }

    },[])


    let UpdateCalculation = () => {

            axios.get("https://old.stone-xxi.ru/brands/calc_d.php?pmt_type="+data.type+"&adv="+data.adv+"&tsType=LA&fmt=JSON&modelId=343511&brand=BMW&model=BMW+2+Series+Gran+Coupe&compl=218i+Online+Edition&sl="+data.sl+"&price="+data.price).then((d) => {
            setData(data => ({...data, calculator:{price: d.data['Суммарный платеж'] ? d.data['Суммарный платеж'] : 0,month: d.data['Ежемесячный платеж'] ? d.data['Ежемесячный платеж'] : 0}}))
            window.scrollTo(0, 0)
        })
        
    }

    let GetQuote = (e) => {
        setMsg(msg => ({title:'',msg:''}))
        // setButton(button => ({...button, getQuote:true}))
        // api('request',{
        //     "Put":"AppLeasingForm",
        //     "AppLeasingAmount":data.price,
        //     "DLNumber":"0",
        //     "DLDate":"0",
        //     "DLFile":"0",
        //     // "PL": data.pl,
        //     "CalcAmount":data.price,
        //     "CalcAdv":data.adv,
        //     "CalcSL":data.sl,
        //     "CalcPaymentType":data.type,
        //     "action":"AppLeasingEdit",
        //     "AppLeasingId":data.id
        // }).then((d) => {
        //     if (!d.data.Error) {
        //         setData(data => ({...data, id:d.data[0].AppLeasingId}))
        //         setPage(page => ({...page,id:d.data[0].AppLeasingId}))
        //     }
        //     setButton(button => ({...button, getQuote:false}))
        //     return d
        // }).then((d) => {
            setButton(button => ({...button, getQuote:true}))
            // let id = d.data[0].AppLeasingId


            console.log(page);
            api('request',{
                "Put":"KPCalcEdit",
                "action":"KPRequest",
                "KPId":page.newKP ? page.newKP : page.KPId ? page.KPId : 0,
                AppLeasingId:page.id ? page.id : 0,
                KPAmount:data.price,
                PL: data.pl[0],
                CompanyId:main.CompanyId,
                KPAdvance:data.adv,
                KPSl:data.sl,
                PmtType:data.type
            }).then((d) => {
                // if (d.data.Error) {
                //     setMsg(msg => ({title:'Внимание!',msg:d.data.Error}))
                // } else {

                //     setPage(page => ({...page,modNew:true}))

  
                //         setPage(page => ({...page,AppLeasingStatus:dd.data.AppLeasingStatus,id:id}))
                //         history.push("/AppLeasing/"+id);
                //         setMsg(msg => ({title:'',msg:''}))
                // }


                if (d.data.Error) {

                    setMsg(msg => ({title:'Внимание!',msg:d.data.Error}))

                    if (d.data.errors) {
                        setError(error => ({pl:[{PLName:false,PLQty:false,PLBrand:false,PLPrice:false,PLSupplierINN:false,PLSupplier:false,PLSupplier:false, PLSupplierINN:false,...d.data.errors}]}))
    
    
                    }

                } else {

                    // if (d.data.errors) {

                    //     setError(error => ({pl:[{PLName:false,PLQty:false,PLBrand:false,PLPrice:false,PLSupplierINN:false,PLSupplier:false,PLSupplier:false, PLSupplierINN:false,...d.data.errors}]}))
    
    
                    // } else {
                        setPage(page => ({...page,AppLeasingStatus:d.data.AppLeasingStatus,id:d.data.AppLeasingId,KPId:d.data.KPId}))
                        history.push("/AppLeasing/"+d.data.AppLeasingId)

                        let step = 'new';

                        switch (d.data.AppLeasingStatus) {
                            case '3':
                                step = 'blank'
                            break
                            case '4':
                                step = 'doc'
                            break
                            case '5':
                                step = 'ok'
                            break
                            case '6':
                                step = 'end'
                            break
                            case '7':
                                step = 'ok'
                            break
                            case '8':
                                step = 'ok'
                            break
                            default:
                                step = 'new'
                        }


                        setPage(page => ({...page,modNew:d.data.AppLeasingStatus <= 2, step: step}))
    
                    // }

                }

    
                setButton(button => ({...button, getQuote:false}))
            })


            // api('request',{"Get":"AppLeasingForm",action:'AppLeasingValidate',"AppLeasingId":id}).then((d) => {
            //     if (d.data.Error) {
            //         if (d.data.errors.PL) {
            //             setError(error => ({pl:[...data.pl.map((e,i) => {
            //                 if (d.data.errors.PL[i]) {
            //                     return d.data.errors.PL[i]
            //                 } else {
            //                     return {Name:false,Qty:false,Price:false,SupplierINN:false,Supplier:false}
            //                 }
            //             })],main:d.data.errors.main ? d.data.errors.main : []}))
            //         } else {
            //             setError(error => ({pl:[...data.pl.map((e) => {
            //                 return {Name:false,Qty:false,Price:false,SupplierINN:false,Supplier:false}
            //             })],main:d.data.errors.main ? d.data.errors.main : []}))
            //         }
            //         setButton(button => ({...button, getQuote:false}))
            //     } else {
                    
            //         setTimeout(()=> {
            //         //     api('request',{
            //         //         "Put":"KPCalcEdit",
            //         //         "action":"KPCalcSave",
            //         //         "KPId":0,
            //         //         AppLeasingId:id,
            //         //         KPAmount:data.price,
            //         //         KPAdvance:data.adv,
            //         //         KPSl:data.sl,
            //         //         PmtType:data.type
            //         //     }).then((dd) => {
            //                 api('request',{
            //                     "Put":"KPCalcEdit",
            //                     "action":"KPRequest",
            //                     // "KPId":dd.data.KPId,
            //                     AppLeasingId:id,
            //                     KPAmounts:data.price,
            //                     KPAdvance:data.adv,
            //                     KPSl:data.sl,
            //                     PmtType:data.type
            //                 }).then((d) => {
            //                     // if (d.data.Error) {
            //                     //     setMsg(msg => ({title:'Внимание!',msg:d.data.Error}))
            //                     // } else {

            //                     //     setPage(page => ({...page,modNew:true}))

                  
            //                     //         setPage(page => ({...page,AppLeasingStatus:dd.data.AppLeasingStatus,id:id}))
            //                     //         history.push("/AppLeasing/"+id);
            //                     //         setMsg(msg => ({title:'',msg:''}))
            //                     // }
            //                     setButton(button => ({...button, getQuote:false}))
            //                 })
            //             // })
            //         },2000)
            //     }
            //     setButton(button => ({...button, getQuote:false}))
            // })
        // })
        e.preventDefault()
    }

    let newR = () => {
        setData({...data,pl:[{PLQty:'',PLBrand:'',PLName:'',PLTypeId:"1",RegionId:31,PLYear:2021,PLIsNew:"1",PLSupplier:''}],type:'eq',adv:10,price:0,sl:24,calculator:{price:0,month:0}})
        // setPage(page => ({...page, step: 'new'}))
        setPage(page => ({...page,modNew:false,KPId:0}))
    }

    let newRt = () => {
        // setData({...data,pl:[{PLQty:'',PLBrand:'',PLName:'',PLTypeId:"1",RegionId:31,PLYear:2021,PLIsNew:"1",PLSupplier:''}],type:'eq',adv:10,price:0,sl:24,calculator:{price:0,month:0}})
        // setPage(page => ({...page, step: 'new'}))
        setPage(page => ({...page,modNew:false,KPId:0}))
    }

    return <>
    <h2 className="mt-4">Предмет лизинга</h2>
    <hr className="mt-2" size="2" />
    <div className="row mt-3">
        <div className="col-md-8 col-lg-6 mb-3">
            <Pl main={main} data={data} setData={setData} error={error} setError={setError} disabled={disabled} page={page}/>
            {/* <div className="row mt-4 mb-3">
                <div className="col-12">{msg.msg && <div className='login-alert p-3 text-center mb-3'><div className='text-primary'>{msg.title}</div><span>{msg.msg}</span></div>}</div>
                <div className="col-auto mb-3">
                    <button onClick={GetQuote} className="btn btn-base"disabled={button.getQuote || disabled}><Button title='Отправить расчет для получения КП' color='btn-black-base' param={button.getQuote} /></button>
                </div>
                <div className="col-auto mb-3">
                    <button onClick={UpdateCalculation} className="btn btn-ghost" disabled={disabled}>Обновить расчет</button>
                </div>
            </div> */}
        </div>
        <div className="col-md-8 col-lg-6">
            {/* <div className="block-blue p-4 mb-3">
                <div className="h1 text-body">Выгодно!</div><span>Скидка до 15% на этот предмет лизинга по специальному предложению</span>
            </div> */}
        </div>

        
    </div>

    <Calculator data={data} setData={setData} disabled={disabled} />


    <div className="col-md-8 col-lg-6 mb-3">
    <hr className="mt-2" size="2" />
            <div className="row mt-4 mb-3">
                <div className="col-12">{msg.msg && <div className='login-alert p-3 text-center mb-3'><div className='text-primary'>{msg.title}</div><span>{msg.msg}</span></div>}</div>
                <div className="col-auto mb-3">
                    <button onClick={GetQuote} className="btn btn-base"disabled={button.getQuote || disabled}><Button title='Отправить расчет для получения КП' color='btn-black-base' param={button.getQuote} /></button>
                </div>
                <div className="col-auto mb-3">
                    <button onClick={newR} className="btn btn-ghost" disabled={disabled}>Обновить расчет</button>
                </div>
            </div>
        </div>


    {page.modNew && <div className="modal-open"><div class="modal fade show" id="modalManager" tabindex="-1" aria-labelledby="modalManager" aria-modal="true" role="dialog" style={{display: 'block'}}>
    <div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<h3 class="modal-title">Получить подробное КП</h3>
						<button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setPage(page => ({...page, modNew:false}))}></button>
					</div>
					<div class="modal-body">
						<p>Ваш расчет успешно отправлен личному менеджеру. Коммерческое предложение по данному расчету будет подгружено и отображено на этапе «Согласование КП».</p>
						<p>Вы можете отправить другой расчет, если это необходимо. Полный список всех расчетов и коммерческих предложений к ним можно увидеть на этапе «Согласование КП» .</p>
						<div class="row mb-n3">
							<div class="col-auto mb-3">
								<button onClick={()=>setPage(page => ({...page, step: 'kp'}))} class="btn btn-base" >Завершить формирование расчетов</button>
							</div>
							<div class="col-auto mb-3">
								<button class="btn btn-ghost" onClick={newRt}>Изменить условия лизинга</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div></div>}

    
    </>
}

export default Payment
