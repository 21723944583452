import InputMask from "react-input-mask";
import { useEffect, useState } from 'react'
import {api,Inn,Button} from "../Run"
import {Link} from 'react-router-dom'

import Logo from '../images/logo2.svg'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronRight,faChevronDown,faPen} from '@fortawesome/free-solid-svg-icons'

import Address from "../components/common/Address";
import InnPrompt from '../components/Company/InnPrompt'

let AddCompany = ({main,reload,com}) => {

    let exit = () => {
        localStorage.removeItem('token')
        window.location.reload()
    }



    let [input,setInput] = useState({ActivitySphereId:"18",AnnualRevenueId:"1",EDOServiceId: "123",EmployeesNumberId: "1"})
    let [page,setPage] = useState({com:'',new:false,errors:{}})
    let [load,setLoad] = useState({com:false})

    let [button, setButton] = useState({item:false})



    let create = (e) => {
        setButton(button => ({...button, create:true}))
        api('test',{"Put":"CompanyProfile",action: "CompanyProfileEdit",Name: input.Name,
        INN: input.INN,
        Address: input.Address,
        CompanyId: 0,
        OKPO: input.OKPO,
        EDOServiceId: input.EDOServiceId,
        EmployeesNumberId: input.EmployeesNumberId,
        AnnualRevenueId: input.AnnualRevenueId,
        ActivitySphereId: input.ActivitySphereId,token:localStorage.getItem('token')}).then((d) => {



            if (d.data.Error) {
                setPage(page =>({...page,Error:d.data.Error,errors:d.data.errors ? d.data.errors : []}))
            } else {

                // setPage(page => ({...page, id: d.data.CompanyId}))
                setPage(page =>({...page,Error:d.data.msg,id: d.data.CompanyId,errors:{}}))
                // setPage(page =>({...page,Error:''}))
                // document.location.reload();
                console.log(main);
                // reload({companies:[...main.companies,{id:d.data.CompanyId,name:d.data.Data.Name}]})
                document.location.reload();
            }
            setButton(button => ({...button, create:false}))
        })
        e.preventDefault()
    }


    let change = (e) => {
            console.log(e.target.name);
        setInput(input => ({...input, [e.target.name]: e.target.value}))


        // if (e.target.name === "INN" && e.target.value.length > 9) {
        //     setLoad(load => ({...load, inn: true}))
        //     setTimeout(()=>{
        //         Inn(e.target.value).then((d) => {
        //             if (!input.Address && !input.Name) {
        //                 setInput(input => ({...input, Address: d['Адрес'], Name: d['НаимЮЛ']}))
        //             } else {
        //                 if (input.Address !== d['Адрес'] || input.Name !== d['НаимЮЛ']) {
        //                     setPage(page =>({...page,inn:d['Адрес'],Name:d['НаимЮЛ']}))
        //                 }
        //             }
        //             setLoad(load => ({...load, inn: false}))
        //         })
        //     },500)
        // }


        e.persist()

    }


    let tempinn = (address,name) => {
        setInput(input => ({...input, Address: address, Name: name}))
        setPage(page =>({...page,inn:''}))
    }


    let submit = (e) => {
        setButton(button => ({...button, item:true}))
        api('test',{"Put":"CompanyProfile",action: "CompanyProfileEdit",...input,token:localStorage.getItem('token')}).then((d) => {


            if (d.data.Error) {
                setPage(page =>({...page,Error:d.data.Error}))
            } else {
                setPage(page =>({...page,Error:d.data.msg}))
            }

            setButton(button => ({...button, item:false}))
            // setInput(input => ({...input, ...d.data}))
        })

        e.preventDefault()
    }

    const handleChangeInn = (value, name, onlyValue=true) => {
        if (onlyValue) {
            setInput({...input, [name]: value})
        } else {
            console.log(value.data);
            let ok = value.data.okpo && value.data.okato ?  value.data.okpo +'/'+ value.data.okato : ''
            setInput(input => ({...input,INN:value.data.inn,OKPO:ok, Address: value.data.address.unrestricted_value, Name: value.data.name.short_with_opf}))
        }
    };

    let item = (id) => {
        setPage(page =>({...page,Error:''}))
        setPage(page =>({...page,new:false}))
        setLoad(load => ({...load, com: true}))
        if (!load.com) {
            setPage(page => ({...page, com: id}))
            api('test',{"Get":"CompanyProfile","action":"CompanyProfileEdit","CompanyId":id,token:localStorage.getItem('token')}).then((d) => {
                console.log(d)
                setInput(input => ({
                    CompanyId: d.data.CompanyId,
                    Name: d.data.Name,
                    INN: d.data.INN,
                    Address: d.data.Address,
                    EDOServiceId: d.data.EDOServiceId,
                    EmployeesNumberId: d.data.EmployeesNumberId,
                    AnnualRevenueId: d.data.AnnualRevenueId,
                    ActivitySphereId: d.data.ActivitySphereId
                }))
                 api('test',{"Get":"CompanyStaff","action":"CompanyStaffShow","CompanyId":"36",token:localStorage.getItem('token')}).then((d) => {
                    setInput(input => ({...input, FLCompany: d.data.FLCompany}))
                    setTimeout(function () {
                        setLoad(load => ({...load, com: false}))
                        // setPage(page => ({...page, com: d.data.CompanyId}))
                    },1000)
                })

            })
        }
    }


    let nsew = () => {
        if (page.new) {
            setPage(page =>({...page,new:false}))
        } else {
            setPage(page =>({...page,new:true,com:''}))
            setInput(input => ({
                Name: '',
                INN:'',
                Address: '',
                EDOServiceId: '1',
                EmployeesNumberId: '1',
                AnnualRevenueId: '1',
                ActivitySphereId: '1'
            }))
        }
    }


    // const handleChangeInn = (value, name, onlyValue=true) => {
    //     if (onlyValue) {
    //         setInput({...input, [name]: value})
    //     } else {
    //         console.log(value.data);

    //         setInput(input => ({...input,INN:value.data.inn, Address: value.data.address.unrestricted_value, Name: value.data.name.short_with_opf}))
    //     }
    // };


    let onChangeAddress = (d) => {
        setInput(input => ({...input, Address: d}))
    }


    return <>
    <header class="header">
        <Link class="header-logo" style={{background:'#F2F2F2'}} to="/"><img class="header-logo__img" src={Logo} /></Link>
        <div class="header-inner" style={{background:'#F2F2F2'}}>


            <button className='btn btn-base' style={{position:'absolute',right:'15px'}} onClick={exit}>Выход</button>

   

      
                </div> 
   
    </header>
    <main class="main">



			<div class="content-page" style={{margin:'0 auto', maxWidth:'1000px'}}>
				<div class="content-main">
<div class="mb-3 page-head mb-4">Профиль организации</div>
<div class="row">
	<div class="col-lg-12">
    <div className='col-sm-12'>
    {page.Error && <div className='login-alert p-3 text-center mb-3'><div className='text-primary'>Внимание!</div>{page.Error}</div>}
    </div>
		<h5 class="mb-2">Реквизиты организации</h5>
		{/* <div class="form-group mb-3 form-floating">
			<input class="form-control" name="Name" value={input.Name} onChange={change} required placeholder="Placeholder" />
			<label>Наименование ЮЛ</label>
		</div> */}
        <InnPrompt errors={page.errors.Name} value={input.Name} name="Name" label='Наименование ЮЛ' onChange={handleChangeInn} className="form-control" />
		<div class="row">
			<div class="col-md-6">

                    <InnPrompt
                    errors={page.errors.INN}
                    value={input.INN}
                    name="INN"
                    label='ИНН'
                    onChange={handleChangeInn}
                    className="form-control"
                    />

			</div>
			<div class="col-md-6">
				<div class="form-group mb-3 form-floating">
					<input class="form-control" name="OKPO" value={input.OKPO} onChange={change}  placeholder="Placeholder" />
					<label>ОКПО / ОКАТО</label>
				</div>
			</div>
		</div>
		<div class="form-group mb-3 form-floating">
			<select class="form-control form-select" name="EDOServiceId" value={input.EDOServiceId} onChange={change} required>
                {main.EDOList && main.EDOList.map((d,i)=> {
                    return <option value={d.Id}>{d.Name}</option>
                })}
			</select>
			<label>Оператор ЭДО</label>
		</div>
		{/* <div class="form-group mb-3 form-floating">
			<input class="form-control" name="Address" value={input.Address} onChange={change}  required placeholder="Placeholder" />
			<label>Почтовый адрес</label>
		</div> */}

<Address
        error={page.errors.Address}
        value={input.Address}
        name='Address'
        onChange={onChangeAddress}
        label='Почтовый адрес'
        className='form-control'
        />

		<h5 class="mb-2 mt-4">Сведения об организации</h5>
		<div class="form-group mb-3 form-floating">
            <select class="form-control form-select" name="ActivitySphereId" value={input.ActivitySphereId} onChange={change} required>
                {main.ActivitySphere && main.ActivitySphere.map((d,i)=> {
                        return <option value={d.Id}>{d.Name}</option>
                    })}
            </select>
			<label>Сфера деятельности компании:</label>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div class="form-group mb-3 form-floating">
					<select class="form-control form-select" name="AnnualRevenueId" value={input.AnnualRevenueId} onChange={change} required>
                    {main.AnnualRevenue && main.AnnualRevenue.map((d,i)=> {
                        return <option value={d.Id}>{d.Name}</option>
                    })}
					</select>
					<label>Выручка компании за год</label>
				</div>
			</div>
			<div class="col-md-6">
				<div class="form-group mb-3 form-floating">
					<select class="form-control form-select" name="EmployeesNumberId" value={input.EmployeesNumberId} onChange={change} required>
                    {main.EmployeesNumber && main.EmployeesNumber.map((d,i)=> {
                        return <option value={d.Id}>{d.Name}</option>
                    })}
					</select>
					<label>Количество сотрудников</label>
				</div>
			</div>
		</div>
		<div class="row mb-n3">
			<div class="col-auto mb-3">
				<button class="btn btn-base" onClick={create} disabled={button.create}><Button title='Добавить компанию' color='btn-black-base' param={button.create} /></button>
			</div>
			{/* <div class="col-auto mb-3">
				<button class="btn btn-ghost">Добавить компанию</button>
			</div> */}
		</div>
	</div>
</div>
				</div>
			</div>
		</main>


    </>
}

export default AddCompany