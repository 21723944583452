
import {api,Button} from '../../Run'

function Enter({change,input,button,setButton,setError,setData,reload,data,pass}) {


    let login = (e) => {
        reload({msg:false})
        setError(error => ({...error, reg:''}))
        setButton(button => ({...button, login:true}))
        setError(error => ({...error, login: false}))
        api('request',{Put:"AuthForm",action:"Authorize",password:input.password, login:input.email}).then((d) => {
            if (d.s === 'success') {
                if (d.data.Error) {
                    setError(error => ({...error, login: d.data.Error}))
                    setData(data => ({...data, loginError: true}))
                } else {
                    localStorage.setItem('token', d.data.token)
                    window.location.reload()
                }
            } else {
                setError(error => ({...error, login: true}))
                setData(data => ({...data, loginError: true}))
            }
            setButton(button => ({...button, login:false}))
        })
        e.preventDefault()
    }


    return <><div class="form-group mb-3 form-floating">
        <input class="form-control" id="E-mail" placeholder="E-mail" type="email" name="email" onChange={change} required="required" required value={input.email} />
        <label for="E-mail">E-mail</label>
    </div>
    
    <div class="form-group mb-3">
        <div class="custom-group custom-group-append form-floating">
            <input class="form-control" type={data.pass ? 'password' : 'text'} placeholder="Пароль" name="password" onChange={change} id="first_name" required="required" />
            <label for="E-mail">Пароль</label><a class="custom-group-icon js-drop-eye" onClick={pass}><i className={!data.pass ? 'icon-eye icon-eye-closed' : 'icon-eye'}></i></a>
        </div>
    </div>
    
    <div class="row">
        <div class="col-auto mb-3"><button className='btn btn-ref' onClick={()=>setData(data => ({...data, repass: false}))}>Забыли пароль?</button></div>
        <div class="col-auto mb-3"><button className='btn btn-ref' onClick={()=>setData(data => ({...data, errorMsg: !data.errorMsg}))}>Сообщить о проблеме со входом</button></div>
    </div>
    <div class="text-secondary mb-3 note">
   Входя в личный кабинет пользователя, я подтверждаю, что даю <a href="https://www.stone-xxi.ru/about/person/" target="_blank">согласие на обработку персональных данных</a>.

        
        </div>
    
    <button class="btn btn-base btn-large" onClick={login} disabled={button.login}><Button title='Войти' color='btn-black' param={button.login} /></button>
    
    {/* <div class="mt-3 d-flex align-items-center justify-content-center"><b class="me-3 my-0">Войти через:</b>
    <div class="socs mb-n2"><a class="soc soc_small mb-2" href="#" target="_blank"><i class="icon-soc-vk"></i></a><a class="soc soc_small mb-2" href="#" target="_blank"><i class="icon-soc-facebook"></i></a></div>
    </div> */}
    
    </>
}

export default Enter