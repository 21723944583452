import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import { api } from './Run'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'
import './css/template_styles.css'
import './css/App.css'
import Menu from './Menu'
import Header from './Header'
import Login from './pages/Login'
import NoPage from './pages/NoPage'
import Main from './pages/Main'
import Leasing from './pages/Leasing'
import AppLeasing from './pages/AppLeasing'
import Ok from './pages/Ok'
import End from './pages/End'
import AddCompany from './pages/AddCompany'
import Opd from './pages/Opd'
import OpdRoot from './pages/OpdRoot'
import Repeal from './pages/Repeal'
import RepealRoot from './pages/RepealRoot'
import Test from './Test'
import Toastr from "./components/common/Toastr"
import Contacts from "./pages/Contacts"
import CompanyId from './pages/Settings/CompanyId'
import ConfigNotations from "./pages/ConfigNotations/ConfigNotations"
// import ConfigNotations from "./pages/ConfigNotations";

import axios from 'axios'



// New

import Settings from './pages/Settings/User'
import OrgList from './pages/Settings/OrgList'
import Privilege from './pages/Settings/Privilege'
import Search from "./pages/ Search/Search";
import About from "./pages/About";
import SettingRights from "./pages/SettingRights/SettingRights";
import UserList from "./pages/UserList/UserList";
import CompanyList from "./pages/CompanyList";

let Index = () => {
  let [main, setMain] = useState({ msg: '', companies: [] })
  let [ad, setAd] = useState([])
  let params = (new URL(document.location)).searchParams;
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {

    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });

    if (params.get("UID")) {

      api('test', { Put: 'PasswordReset', action: 'PasswordRefresh', UID: params.get("UID") }).then((d) => {
        localStorage.removeItem('token');
        setMain(main => ({ msg: 'Пароль отправлен на почту' }))
      })
    } else {
      if (localStorage.getItem('token')) {

        api('request', {
          Get: 'MainPageLayout',
          CompanyId: localStorage.getItem('CompanyId') && localStorage.getItem('CompanyId'),
          token: localStorage.getItem('token')
        }).then((d) => {
          if (d.data.Error) {
            localStorage.clear()
            setMain(main => ({ msg: d.data.Error }))
          } else {
            setMain(main => ({ ...main, ...d.data }))
          }
        })


        api('request', { Get: 'MainPageLayout', action: "LoadReferences", token: localStorage.getItem('token') }).then((d) => {
          setMain(main => ({ ...main, references: { ...d.data } }))
        })




      }
    }

    axios.get("https://www.stone-xxi.ru/sale/lkAdv.php").then((d) => {


      setAd(d.data);
    })

  }, [])


  let com = (d) => {

    if (localStorage.getItem('token')) {

      api('request', { Get: 'MainPageLayout', CompanyId: d, token: localStorage.getItem('token') }).then((d) => {
        if (d.data.Error) {
          localStorage.clear()
          setMain(main => ({ msg: d.data.Error }))
        } else {
          setMain(main => ({ ...main, ...d.data }))
        }
      })

    }
  }

  let reload = (d) => {
    console.log(d);
    setMain(main => ({ ...main, ...d }))
  }

  let top = () => {
    window.scrollTo(0, 0)
  }






  if (localStorage.getItem('token')) {
    if (main.companies.length > 0) {
      return <BrowserRouter>
        <Header main={main} reload={reload} com={com} ad={ad} />
        <main className="main">
          <Menu main={main} reload={reload} com={com} />

          <Switch>
            <Route exact path='/'><Main main={main} reload={reload} ad={ad} /></Route>
            <Route path='/AppLeasingList'><Leasing com={com} main={main} reload={reload} /></Route>
            <Route path='/AppLeasingNew'><Leasing com={com} main={main} reload={reload} /></Route>
            <Route exact path='/AppLeasing'><AppLeasing main={main} reload={reload} /></Route>
            <Route exact path='/AppLeasing/:id'><Leasing com={com} main={main} reload={reload} /></Route>
            <Route path='/ok'><Ok main={main} reload={reload} /></Route>
            <Route path='/end'><End main={main} reload={reload} /></Route>
            <Route path='/test'><Test /></Route>
            <Route path='/UserSettings'><Settings main={main} reload={reload} /></Route>
            <Route path='/Settings'><Settings main={main} reload={reload} /></Route>
            <Route path='/CompanyList'><OrgList main={main} reload={reload} /></Route>
            <Route path='/Company/:id'><CompanyId main={main} reload={reload} /></Route>
            <Route path='/Privilege'><Privilege main={main} reload={reload} /></Route>
            <Route path='/Contacts'><Contacts main={main} reload={reload} /></Route>
            <Route path='/opd'><OpdRoot /></Route>
            <Route path='/repeal'><RepealRoot /></Route>
            <Route path='/Search'><Search main={main} reload={reload} /></Route>
            <Route path='/ConfigNotification'><ConfigNotations main={main} reload={reload} /></Route>
            <Route path='/About'><About main={main} reload={reload} /></Route>
            <Route path='/SettingRights/:UserCompanyId'><SettingRights main={main} reload={reload} /></Route>
            <Route path='/UserList'><UserList main={main} reload={reload} /></Route>
            <Route path='*'><NoPage /></Route>
          </Switch>
          <Toastr />
          {showButton && <a class="back-to-top js-back-to-top shown" onClick={top}>Вверх</a>}
        </main>
      </BrowserRouter>

    } else {
      if (main.TimeZone) {
        return <BrowserRouter>
          <Switch><AddCompany main={main} reload={reload} com={com} /></Switch></BrowserRouter>
      } else {
        return ''
      }
    }
  } else {
    return <BrowserRouter>
      <Switch>
        <Route exact path='/opd'><Opd /></Route>
        <Route exact path='/repeal'><Repeal /></Route>
        <Route path='*'><Login main={main} reload={reload} ad={ad} /></Route>
      </Switch>
      <Toastr />
    </BrowserRouter>
  }
}

ReactDOM.render(<Index />, document.getElementById('root'))

reportWebVitals()
