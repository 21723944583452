import React, { useState,useEffect } from 'react'
import axios from 'axios'
import {api,Button} from '../../Run'

let Calculator = ({data,setData,disabled}) => {

    let [tim,setTim] = useState(true)
    let [closetim,setCloseTim] = useState()

    let  reverseString = (str) => {

        if(str) {
            let splitString = String(str).split("");
        let reverseArray = splitString.reverse();
        let joinArray = reverseArray.join("");

        let num = joinArray.replace(/\s/g, '')
        let len = num.length
        let arr = []
        for (let i = 0; i < len; i++) {
          arr.push(num[i]);
          if ((i + 1) % 3 == 0 && i !== len - 1) {
            arr.push(' ');
          }
        }
        let newFormat = arr.reverse().join('');


        return newFormat;
        } else {
            return '';
        }
    }


    let chek = (e) => {
        if (e.target.value < 10) {
            setData(data => ({...data, adv: 10}))
            axios.get("https://old.stone-xxi.ru/brands/calc_d.php?pmt_type="+data.type+"&adv=10&tsType=LA&fmt=JSON&modelId=343511&brand=BMW&model=BMW+2+Series+Gran+Coupe&compl=218i+Online+Edition&sl="+data.sl+"&price="+data.price).then((d) => {
            setData(data => ({...data, calculator:{price: d.data['Суммарный платеж'] ? d.data['Суммарный платеж'] : 0,month: d.data['Ежемесячный платеж'] ? d.data['Ежемесячный платеж'] : 0}}))
        })
        } else if ((e.target.value > 90)) {
            setData(data => ({...data, adv: 90}))
            axios.get("https://old.stone-xxi.ru/brands/calc_d.php?pmt_type="+data.type+"&adv=90&tsType=LA&fmt=JSON&modelId=343511&brand=BMW&model=BMW+2+Series+Gran+Coupe&compl=218i+Online+Edition&sl="+data.sl+"&price="+data.price).then((d) => {
            setData(data => ({...data, calculator:{price: d.data['Суммарный платеж'] ? d.data['Суммарный платеж'] : 0,month: d.data['Ежемесячный платеж'] ? d.data['Ежемесячный платеж'] : 0}}))
        })
    }
    e.persist()
    }

    let m = (e) => {
        if (e.target.value < 12) {
            setData(data => ({...data, sl: 12}))
            axios.get("https://old.stone-xxi.ru/brands/calc_d.php?pmt_type="+data.type+"&adv=10&tsType=LA&fmt=JSON&modelId=343511&brand=BMW&model=BMW+2+Series+Gran+Coupe&compl=218i+Online+Edition&sl=12&price="+data.price).then((d) => {
            setData(data => ({...data, calculator:{price: d.data['Суммарный платеж'] ? d.data['Суммарный платеж'] : 0,month: d.data['Ежемесячный платеж'] ? d.data['Ежемесячный платеж'] : 0}}))
        })
        } else if ((e.target.value > 60)) {
            setData(data => ({...data, sl: 60}))
            axios.get("https://old.stone-xxi.ru/brands/calc_d.php?pmt_type="+data.type+"&adv=90&tsType=LA&fmt=JSON&modelId=343511&brand=BMW&model=BMW+2+Series+Gran+Coupe&compl=218i+Online+Edition&sl=60&price="+data.price).then((d) => {
            setData(data => ({...data, calculator:{price: d.data['Суммарный платеж'] ? d.data['Суммарный платеж'] : 0,month: d.data['Ежемесячный платеж'] ? d.data['Ежемесячный платеж'] : 0}}))
        })
    }
    e.persist()
    }

    let change = (e) => {
        setData(data => ({...data, [e.target.name]: e.target.value}))
        if (e.target.name ===  'adv') {
            axios.get("https://old.stone-xxi.ru/brands/calc_d.php?pmt_type="+data.type+"&adv="+e.target.value+"&tsType=LA&fmt=JSON&modelId=343511&brand=BMW&model=BMW+2+Series+Gran+Coupe&compl=218i+Online+Edition&sl="+data.sl+"&price="+data.price).then((d) => {
                setData(data => ({...data, calculator:{price: d.data['Суммарный платеж'] ? d.data['Суммарный платеж'] : 0,month: d.data['Ежемесячный платеж'] ? d.data['Ежемесячный платеж'] : 0}}))
            })
        } else  if (e.target.name ===  'sl') {
            axios.get("https://old.stone-xxi.ru/brands/calc_d.php?pmt_type="+data.type+"&adv="+data.adv+"&tsType=LA&fmt=JSON&modelId=343511&brand=BMW&model=BMW+2+Series+Gran+Coupe&compl=218i+Online+Edition&sl="+e.target.value+"&price="+data.price).then((d) => {
                setData(data => ({...data, calculator:{price: d.data['Суммарный платеж'] ? d.data['Суммарный платеж'] : 0,month: d.data['Ежемесячный платеж'] ? d.data['Ежемесячный платеж'] : 0}}))
            })
        }
        
        e.persist()
    }


    useEffect(()=> {

        if (tim) {
            setTim(false)
            setCloseTim(setTimeout(() => {
                axios.get("https://old.stone-xxi.ru/brands/calc_d.php?pmt_type="+data.type+"&adv="+data.adv+"&tsType=LA&fmt=JSON&modelId=343511&brand=BMW&model=BMW+2+Series+Gran+Coupe&compl=218i+Online+Edition&sl="+data.sl+"&price="+data.price).then((d) => {
            setData(data => ({...data, calculator:{price: d.data['Суммарный платеж'] ? d.data['Суммарный платеж'] : 0,month: d.data['Ежемесячный платеж'] ? d.data['Ежемесячный платеж'] : 0}}))
        }).then(()=>{
            setTim(true)
        })
            }, 1000) )
        } else {
            clearTimeout(closetim)
            setCloseTim(setTimeout(() => {
                axios.get("https://old.stone-xxi.ru/brands/calc_d.php?pmt_type="+data.type+"&adv="+data.adv+"&tsType=LA&fmt=JSON&modelId=343511&brand=BMW&model=BMW+2+Series+Gran+Coupe&compl=218i+Online+Edition&sl="+data.sl+"&price="+data.price).then((d) => {
            setData(data => ({...data, calculator:{price: d.data['Суммарный платеж'] ? d.data['Суммарный платеж'] : 0,month: d.data['Ежемесячный платеж'] ? d.data['Ежемесячный платеж'] : 0}}))
        }).then(()=>{
            setTim(true)
        })
            }, 1000) )
        }
        
    },[data.price])


 

    useEffect(()=> {
        window.addEventListener('scroll', function(e) {
            console.log(window.scrollY);
            if (window.scrollY > 500) {
                setData(data => ({...data, scrol:true}))
            } else {
                setData(data => ({...data, scrol:false}))
            }
          });
    },[])

    return <>
    {/* <div class="text-secondary mt-3 text-end">{data.AppLeasingStatusDate && 'Расчет сохранен: 22.12.2020 14:32:12'}</div> */}

    {/* {data.scrol && <div style={{position:'fixed',top:'0',zIndex:'3',marginRight:'15px'}}><div style={{background:'#F2F2F2',padding:'10px',borderRadius:'0 0  4px 4px',border:'1px solid #dcdcdc',borderTop:'0px',margin:'0 auto',maxWidth:'550px'}}>
        <h2 className=''>Калькулятор</h2>
        <div>Сумма: {reverseString(data.price)} руб.</div>
        {data.type  === 'eq' &&  <div>Ежемесячные платежи: <span dangerouslySetInnerHTML={{__html:data.calculator.month}} /> руб.</div>}
        {data.type  === 'eq' ? <div class="text-secondary">Сумма платежей за весь срок лизинга: <span dangerouslySetInnerHTML={{__html:data.calculator.price}} /> руб.</div> : <div class="text-secondary">Индивидуальный график платежей</div>}
    </div></div>} */}


    <h2 className='mt-3'>Калькулятор</h2>
    <hr class="mt-2" size="2" />
    <div class="row mt-3">
        <div class="col-md-8 col-lg-6 mb-3">
            
            <div className="form-group mb-3 form-floating">
                <input className="form-control" onBlur={chek} value={data.adv} name="adv" onChange={change} placeholder="Placeholder" disabled={disabled} />
                <label>Аванс, %</label>
            </div>
            
            <div className="form-group mb-3 form-floating">
                <input className="form-control" value={reverseString(data.price)} placeholder="Сумма" disabled/>
                <label>Сумма</label>
            </div>

            <div className="form-group mb-3 form-floating">
                <input className="form-control" onBlur={m} value={data.sl} name="sl" onChange={change} placeholder="Placeholder" disabled={disabled} />
                <label>Срок лизинга, мес</label>
            </div>
            <div class="form-group mb-3">
                <label>
                    <input type="radio" name="radiogroup" name="type" onClick={change} value="eq" checked={data.type === 'eq'} disabled={disabled} /><span>Равномерные платежи</span>
                </label>
            </div>
            <div class="form-group mb-3">
                <label>
                    <input type="radio" name="radiogroup" name="type" onClick={change} value="lt" checked={data.type === 'lt'} disabled={disabled} /><span>Индивидуальный график</span>
                </label>
            </div>
            {data.type  !== 'eq' && <div class="note mt-3">Выберите данный пункт для расчета сезонных/убывающих платежей, и персональный менеджер свяжется с Вами для уточнения деталей.</div>}

            {data.type  === 'eq' && <h5 class="mb-0">Ежемесячные платежи: <span dangerouslySetInnerHTML={{__html:data.calculator.month}} /> руб.</h5>}
            {data.type  === 'eq' && <div class="text-secondary">Сумма платежей за весь срок лизинга: <span dangerouslySetInnerHTML={{__html:data.calculator.price}} /> руб.</div>}

            {!tim && <div class="mt-3" style={{maxWidth:'20px'}}><Button title='222' color='btn-black-base' param={!tim} /></div>}
           
        </div>
    </div></>
}

export default Calculator
