import {Link} from 'react-router-dom'

function Privilege() {
    
    return <div class="content-page">
    <div class="content-main">
<div class="page-head mb-4">Настройки прав пользователя</div>
<div class="row">
<div class="col-md-8 col-lg-6">
<div class="form-group mb-3 form-floating">
<input class="form-control" placeholder="Иванов" value="Иванов" />
<label>Фамилия</label>
</div>
<div class="row">
<div class="col-md-6">
    <div class="form-group mb-3 form-floating">
        <input class="form-control" placeholder="Иванов" value="Иван" />
        <label>Имя</label>
    </div>
</div>
<div class="col-md-6">
    <div class="form-group mb-3 form-floating">
        <input class="form-control" placeholder="Иванов" value="Иванович" />
        <label>Отчество</label>
    </div>
</div>
<div class="col-md-6">
    <div class="form-group mb-3 form-floating">
        <input class="form-control" placeholder="Иванов" value="mail@mail.ru" />
        <label>E-mail</label>
    </div>
</div>
<div class="col-md-6">
    <div class="form-group mb-3 form-floating">
        <input class="form-control" placeholder="Иванов" />
        <label>Должность</label>
    </div>
</div>
</div>
<h5 class="mt-4 mb-3">Доступные разделы</h5>
<div class="form-group mb-3">
<label>
    <input type="checkbox" name="checkbox" /><span>Заявки</span>
</label>
</div>
<div class="form-group mb-3">
<label>
    <input type="checkbox" name="checkbox" /><span>Запросы и обращения</span>
</label>
</div>
<div class="form-group mb-3">
<label>
    <input type="checkbox" name="checkbox" /><span>Фотоштрафы</span>
</label>
</div>
<div class="form-group mb-3">
<label>
    <input type="checkbox" name="checkbox" /><span>Настройки пользователя</span>
</label>
</div>
<div class="form-group mb-3">
<label>
    <input type="checkbox" name="checkbox" /><span>Расширенный поиск</span>
</label>
</div>
<div class="row mb-3">
<div class="col-auto mb-3">
    <button class="btn btn-base">Сохранить</button>
</div>
<div class="col-auto mb-3">
    <button class="btn btn-ghost">Удалить профиль</button>
</div>
</div><a class="icon icon-arrow-back" href="#">Вернуться к списку пользователей</a>
</div>
</div>
    </div>
    <div class="content-promo"></div>
</div>


}

export default Privilege
