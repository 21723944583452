import React from "react";
import { useEffect, useState } from 'react'
import {api,Load} from "../Run"
import {useParams} from "react-router-dom";

import AppLeasingList from './AppLeasingList/AppLeasingList'
import AppLeasingId from './AppLeasingId'

// NEW

import Payment from './step1/Payment'
import Kp from './step2/Kp'
import Blank from './step3/Blank'
import Doc from './step4/Doc/Doc'
import Ok from './step5/Ok'
import End from './step6/End'
import {ProgressBar, Tabs} from "react-bootstrap";
import {Tab} from "bootstrap";

let Menu = ({page,setPage}) => {

    const [activeTab, setActiveTab] = React.useState(1)

    useEffect(() => {
        switch (page.AppLeasingStatus) {
            case '1':
                setPage(page => ({...page,step:'new'}))
            break
            case '2':
                setPage(page => ({...page,step:'kp'}))
            break
            case '3':
                setPage(page => ({...page,step:'blank'}))
            break
            case '4':
                setPage(page => ({...page,step:'doc'}))
            break
            case '5':
                setPage(page => ({...page,step:'ok'}))
            break
            case '6':
                setPage(page => ({...page,step:'end'}))
            break
            case '7':
                setPage(page => ({...page,step:'ok'}))
            break
            case '8':
                setPage(page => ({...page,step:'ok'}))
            break
            default:
                setPage(page => ({...page,step:'new'}))
        }


    },[])

    return <><div className="page-head mb-4">{page.AppLeasingNo ? <>Заявка на лизинг № {page.AppLeasingNo}</> : 'Новый расчет'}</div>
    <nav className="page-nav mb-0">
        <ul className="page-nav__list mb-3">
            <li className="page-nav__item"><button onClick={() => setPage(page => ({...page,step:'new'}))}  className={page.step === 'new' ? "page-nav__link active" : "page-nav__link"}  disabled={!(page.AppLeasingStatus || !page.AppLeasingStatus || page.AppLeasingStatus == 1 || page.AppLeasingStatus == 2 || page.AppLeasingStatus == 3 || page.AppLeasingStatus == 4 || page.AppLeasingStatus == 5 || page.AppLeasingStatus == 6 || page.AppLeasingStatus == 7 || page.AppLeasingStatus == 8 || page.AppLeasingStatus == 9)}>Создание расчета</button></li>
            <li className="page-nav__item"><button onClick={() => setPage(page => ({...page,step:'kp'}))} className={page.step === 'kp' ? "page-nav__link active" : "page-nav__link"} disabled={!(page.AppLeasingStatus || !page.AppLeasingStatus || page.AppLeasingStatus == 2 || page.AppLeasingStatus == 3 || page.AppLeasingStatus == 4 || page.AppLeasingStatus == 5 || page.AppLeasingStatus == 6 || page.AppLeasingStatus == 7 || page.AppLeasingStatus == 8 || page.AppLeasingStatus == 9)}>Согласование КП</button></li>
            <li className="page-nav__item"><button onClick={() => setPage(page => ({...page,step:'blank'}))} className={page.step === 'blank' ? "page-nav__link active" : "page-nav__link"} disabled={!(page.AppLeasingStatus == 3 || page.AppLeasingStatus == 4 || page.AppLeasingStatus == 5 || page.AppLeasingStatus == 6 || page.AppLeasingStatus == 7 || page.AppLeasingStatus == 8 || page.AppLeasingStatus == 9)}>Заполнение анкеты</button></li>
            <li className="page-nav__item"><button onClick={() => setPage(page => ({...page,step:'doc'}))} className={page.step === 'doc' ? "page-nav__link active" : "page-nav__link"} disabled={!(page.AppLeasingStatus == 4 || page.AppLeasingStatus == 5 || page.AppLeasingStatus == 6 || page.AppLeasingStatus == 7 || page.AppLeasingStatus == 8 || page.AppLeasingStatus == 9)}>Сбор документов</button></li>
            <li className="page-nav__item"><button onClick={() => setPage(page => ({...page,step:'ok'}))}  className={page.step === 'ok' ? "page-nav__link active" : "page-nav__link"} disabled={!(page.AppLeasingStatus == 4 || page.AppLeasingStatus == 5 || page.AppLeasingStatus == 6 || page.AppLeasingStatus == 7 || page.AppLeasingStatus == 8|| page.AppLeasingStatus == 9)}>Одобрение сделки</button></li>
            <li className="page-nav__item"><button onClick={() => setPage(page => ({...page,step:'end'}))} className={page.step === 'end' ? "page-nav__link active" : "page-nav__link"} disabled={!(page.AppLeasingStatus == 6 || page.AppLeasingStatus == 8)}>Подписание договора</button></li>
        </ul>
    </nav></>

}

let Leasing = ({main,reload,com}) => {

    let [page, setPage] = useState({})
    let {id} = useParams();
    const [activeTab, setActiveTab] = useState(1)


    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            if (event.state) {
                if (!id) {
                    setPage(page => ({...page, step: 'list', id: 0}))
                }
            }
        }, false);
        if (id) {
     
       

        api('request', {
            "Get": "AppFormEdit",
            "action": "GetCompanyId",
            "AppLeasingId": id
        }).then((d) => {
            if (Number(d.data.CompanyId) !== Number(localStorage.getItem('CompanyId'))) {
                localStorage.setItem('CompanyId',d.data.CompanyId)
                window.location.reload()
            }
            api('request', {
                "Get": "AppLeasingForm",
                "action": "AppLeasingEdit",
                "AppLeasingId": id,
                "CompanyId": main.CompanyId
            }).then((d) => {
                if (d.data.Error) {
                    setPage(page => ({...page, step: 'new', id: 0}))
                } else {
                    setPage(page => ({...page, ...d.data, step: 'new', id: id}))
                }
            })


            
            // console.log(d.data.CompanyId);
        })

    }

    }, [])

    useEffect(() => {
        if (!id) {
            if (window.location.pathname === '/AppLeasingNew') {
                setPage(page => ({...page, step: 'new', id: 0}))
            } else {
                setPage(page => ({...page, step: 'list', id: 0}))
            }
        }

    }, [main])

    switch (page.step) {
        case 'list':
            return <AppLeasingList page={page} setPage={setPage} main={main} reload={reload}/>
        case 'new':
            return <>
                <div className="content-page">
                    <div className="content-main"><Menu page={page} setPage={setPage}/><Payment page={page}
                                                                                                setPage={setPage}
                                                                                                main={main}
                                                                                                reload={reload}/></div>
                    <div className="content-promo"/>
                </div>
            </>
        case 'kp':
            return <>
                <div className="content-page">
                    <div className="content-main"><Menu page={page} setPage={setPage}/><Kp page={page} setPage={setPage}
                                                                                           main={main} reload={reload}/>
                    </div>
                    <div className="content-promo"/>
                </div>
            </>
        case 'blank':
            return <>
                <div className="content-page">
                    <div className="content-main"><Menu page={page} setPage={setPage}/><Blank page={page}
                                                                                              setPage={setPage}
                                                                                              main={main}
                                                                                              reload={reload}/></div>
                    <div className="content-promo"/>
                </div>
            </>
        case 'doc':
            return <>
                <div className="content-page">
                    <div className="content-main">
                        <Menu page={page} setPage={setPage}/>
                        <Doc page={page} setPage={setPage} main={main} reload={reload}/>
                    </div>
                    <div className="content-promo"/>
                </div>
            </>
        case 'ok':
            return <>
                <div className="content-page">
                    <div className="content-main"><Menu page={page} setPage={setPage}/><Ok page={page} setPage={setPage}
                                                                                           main={main} reload={reload}/>
                    </div>
                    <div className="content-promo"/>
                </div>
            </>
        case 'end':
            return <>
                <div className="content-page">
                    <div className="content-main">
                        <Menu
                            page={page}
                            setPage={setPage}
                        />
                       
                        <End page={page}
                             setPage={setPage}
                             main={main}
                             reload={reload}
                             activeTab={activeTab}
                        /></div>
                    <div className="content-promo"/>
                </div>
            </>
        default:
            return <Load/>
    }

}

export default Leasing
