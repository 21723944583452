import {Link} from 'react-router-dom'
import {api,Button,chTel} from '../../Run'
import { useEffect, useState } from 'react'
import InputMask from "react-input-mask";


function AppLeasing({main}) {

    let [input,setInput] = useState({password:'',newPassword:'',TimeZone:1})
    let [page,setPage] = useState({errors:{},errorsPass:{}})
    let [button, setButton] = useState({save:false,rePass:false,tel:true})
    useEffect(()=>{
        api('request',{"Get":"AccountProfile","action":"AccountProfileEdit",token:localStorage.getItem('token')}).then((d) => {
            console.log(d);
            if(d.data.telVerifyRequestId !== '' && d.data.telVerifyRequestTime !== 0) {
                clock(true,d.data.telVerifyRequestTime)
                setButton(button => ({...button, tel:false}))
            } 
            setInput(input => ({...input, ...d.data}))
        })
       
    },[])

    let change = (e) => {
        setInput(input => ({...input, msg:'', error:''}))
        setInput(input => ({...input, [e.target.name]: e.target.value}))
        console.log(input);
        e.persist()


        if (e.target.name === 'code' && e.target.value.length === 4) {
            setInput(input => ({...input, error:''}))
            api('request',{"Put":"AccountProfile","action":"telVerifyCode","telVerifyRequestId":input.telVerifyRequestId,"SMSVerifyCode":e.target.value,token:localStorage.getItem('token')}).then((d) => {
                if (d.data.Error) {
                    setInput(input => ({...input, error:d.data.Error}))
                    setPage(page =>({...page,Error:d.data.Error,errors:d.data.errors ? d.data.errors : {}}))
                } else {
                    setPage(page =>({...page,Error:d.data.msg,errors:{}}))
                    setInput(input => ({...input, msg:d.data.msg, telVerifyed: 1,telVerifyRequestId:''}))
                }
            })
        }
    }


    let [data,setData] = useState({pass:true, repass:true})

    let pass = () => {
        data.pass ? setData(data => ({...data, pass:false})): setData(data => ({...data, pass:true}));
    }

    let repass = () => {
        data.repass ? setData(data => ({...data, repass:false})): setData(data => ({...data, repass:true}));
    }

    // let rePass = (e) => {
    //     setInput(input => ({...input, msgPass:'',errorPass:''}))
    //     setButton(button => ({...button, rePass:true}))
    //     api('request',{"Put":"AccountProfile","action":"PassEdit","NewPassword":input.newPassword, 'password':input.password,token:localStorage.getItem('token')}).then((d) => {
    //         console.log(d);
    //         if (d.data.Error) {
    //             setPage(page =>({...page,ErrorPass:d.data.Error,errorsPass:d.data.errors ? d.data.errors : {}}))
    //         } else {
    //             setPage(page =>({...page,ErrorPass:d.data.msg,errorsPass:{}}))
    //         }
    //         setButton(button => ({...button, rePass:false}))
    //     })
    //     e.preventDefault()
    // }


    let passwordReset = (e) => {
        setButton(button => ({...button, rePass:true}))
        api('request',{Put:'PasswordReset',login:input.email, action:'PasswordReset'}).then((d) => {
            if (d.data.Error) {
                setPage(page =>({...page,ErrorPass:d.data.Error,errorsPass:d.data.errors ? d.data.errors : {}}))
            } else {
                setPage(page =>({...page,ErrorPass:d.data.msg,errorsPass:{}}))
            }
            setButton(button => ({...button, rePass:false}))
        })
        e.preventDefault()
    }

    let tel = () => {
        api('request',{"Put":"AccountProfile","action":"telVerifyRequest","telMob":'+'+chTel(input.telMob),token:localStorage.getItem('token')}).then((d) => {
            if (d.data.Error) { 
                setPage(page =>({...page,Error:d.data.Error,errors:d.data.Error ? d.data.Error : {}}))

            } else {
                clock(true,300)
                console.log(d);
                setInput(input => ({...input, telVerifyRequestId:d.data.telVerifyRequestId}))
                document.getElementById('code').focus();
            }
            

        })
    }

    let save = (e) => {
        setInput(input => ({...input, error:''}))
        setButton(button => ({...button, save:true}))
        api('request',{"Put":"AccountProfile","action":"AccountProfileEdit",
        'LastName':input.LastName,
        'FirstName':input.FirstName,
        'MidName':input.MidName,
        'TimeZone':input.TimeZone,
        'email':input.email,
        'telMob':'+'+chTel(input.telMob),
        token:localStorage.getItem('token')}).then((d) => {

            if (d.data.Error) {
                // setInput(input => ({...input, error:d.data.Error}))
                setPage(page =>({...page,Error:d.data.Error,errors:d.data.errors ? d.data.errors : {}}))
            } else {
                clock(false,0)
                setButton(button => ({...button, tel:true}))
                setInput(input => ({...input, emailVerifyed:d.data.Data.emailVerifyed,telVerifyed:d.data.Data.telVerifyed, telVerifyRequestId:d.data.telVerifyRequestId ? d.data.telVerifyRequestId : ''}))
                // d.data.Data.emailVerifyed
                // d.data.Data.telVerifyed
                setPage(page =>({...page,Error:d.data.msg,errors:{}}))
            
            }




            setButton(button => ({...button, save:false}))
            console.log(d);
        })
        e.preventDefault()
    }
    
    var myTimer;
    
    function clock(d,t) {
        d ? myTimer = setInterval(myClock, 1000) : clearInterval(myTimer);
        var c = t;


        function myClock() {
            --c
            var seconds = c % 60;
            var minutes = (c - seconds) / 60;
            // console.log(minutes + ":" + seconds)
            setButton(button => ({...button, time:minutes + ":" + seconds}))
            if (c == 0) {
                clearInterval(myTimer);
                setButton(button => ({...button, tel:true}))
            }
        }
    }


    
    return <div class="content-page">
    <div class="content-main">
<div class="page-head mb-4">Профиль пользователя</div>
<div class="row">
<div class="col-md-8 col-lg-6">

<div className='col-sm-12'>
    {page.Error && <div className='login-alert p-3 text-center mb-3'><div className='text-primary'>Внимание!</div>{page.Error}</div>}
    </div>


    <div className={`form-group mb-3 form-floating ${page.errors.LastName && 'has-error'}`}>
<input class="form-control" name="LastName" value={input.LastName} onChange={change} placeholder="Placeholder" />
<label>Фамилия</label>
</div>
<div class="row">
<div class="col-md-6">
<div className={`form-group mb-3 form-floating ${page.errors.FirstName && 'has-error'}`}>
        <input class="form-control" name="FirstName" value={input.FirstName} onChange={change} placeholder="Placeholder" />
        <label>Имя</label>
    </div>
</div>
<div class="col-md-6">
<div className={`form-group mb-3 form-floating ${page.errors.MidName && 'has-error'}`}>
        <input class="form-control" name="MidName" value={input.MidName} onChange={change} placeholder="Placeholder" />
        <label>Отчество</label>
    </div>
</div>
</div>
</div>
</div>
<h5 class="mt-3">Контактные данные</h5>
<div class="row">
<div class="col-md-8 col-lg-6">
<div className={`form-group mb-3 form-floating ${page.errors.email && 'has-error'}`}>
<input class="form-control" name="email" value={input.email} onChange={change} placeholder="Placeholder" />
<label>E-mail</label>
</div>
<div class="row">
<div class="col-md-6">
    <div class="form-group mb-3 form-floating">
    <InputMask className="form-control"  mask="+7 999 999 99 99" name="telMob" value={input.telMob} onChange={change} placeholder="Placeholder" />

        {/* <input class="form-control" name="telMob" value={input.telMob} onChange={change} placeholder="Placeholder" /> */}
        <label>Телефон</label>
    </div>
</div>
<div class="col-md-6">
{input.telVerifyRequestId !== "" && <div class="form-group mb-3 form-floating">
        <input class="form-control" name="code" value={input.code} onChange={change} id="code" placeholder="Placeholder" autoComplete='off' />
        <label>Код подтверждения</label>
    </div>} 
</div>
</div>
<div class="form-group mb-3 form-floating">
<select class="form-control form-select" name='TimeZone' value={input.TimeZone} onChange={change}>
    {main.TimeZone && main.TimeZone.map((d,i)=><option key={i} value={d.ID}>{d.Name}</option>)}
</select>
<label>Часовой пояс</label>
</div>
<button className="btn btn-base" onClick={save} disabled={button.save}><Button title='Сохранить контактные данные' color='btn-black-base' param={button.save} /></button>
</div>
<div class="col-lg-6 pt-3">
{input.emailVerifyed ? <div class="alert alert-success">Адрес электронной почты подтвержден</div> : <div class="alert alert-danger">Адрес электронной почты не подтвержден</div>}

{input.telVerifyed ? <div class="alert alert-success">Номер телефона верифицирован.</div> : <div class="alert alert-danger">
    
    
    {input.telVerifyRequestId === "" || !input.telVerifyRequestId ? <>Номер телефона не верифицирован.<br />Для верификации нажмите <button className='btn btn-ref' onClick={tel}>сюда.</button><br />Вам будет выслан код подтверждения.<br /></> : 
    <>Номер телефона не верифицирован.<br />Вам был выслан код подтверждения. <br /> Введите его. 
    
    {button.tel ? <><br /> Для повторной отправки кода нажмите <button className='btn btn-ref' onClick={tel} disabled={!button.tel}>сюда.</button></> :
    <><br /> Повторно запросить смс с кодом можно через <b>{button.time}</b></>}
    </>}

{/* <button className='btn btn-ref' onClick={tel}>Проверить код подтверждения</button> */}

</div>}


</div>
</div>
<h5 class="mt-3">Пароль</h5>
<div class="row">
<div class="col-md-8 col-lg-6 mb-3">


<div className='col-sm-12'>
    {page.ErrorPass && <div className='login-alert p-3 text-center mb-3'><div className='text-primary'>Внимание!</div>{page.ErrorPass}</div>}
    </div>


{/* <div class="form-group mb-3">
<div className={`custom-group custom-group-append form-floating ${page.errorsPass.password && 'has-error'}`}>
    <input class="form-control" type={data.pass ? 'password' : 'text'}  name="password" onChange={change} placeholder="Текущий пароль" />
    <label>Текущий пароль</label><div class="custom-group-icon js-drop-eye" onClick={pass}><i class="icon-eye"></i></div>
</div>
</div>
<div class="form-group mb-3">
<div className={`custom-group custom-group-append form-floating ${page.errorsPass.NewPassword && 'has-error'}`}>
    <input class="form-control" type={data.repass ? 'password' : 'text'} name="newPassword" onChange={change} placeholder="Текущий пароль" />
    <label>Новый пароль</label><div class="custom-group-icon js-drop-eye" onClick={repass}><i class="icon-eye"></i></div>
</div>
</div> */}
</div>
</div>
<button className="btn btn-base" onClick={passwordReset}  disabled={button.rePass}><Button title='Сменить пароль' color='btn-black-base' param={button.rePass} /></button>
    </div>
    <div class="content-promo"></div>
</div>


}

export default AppLeasing
