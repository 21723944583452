import {api,Load} from "../../Run"
import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import fil from '../../img/triangledown_83849.png'
import up from '../../img/triangle_icon_137074.png'
import upDown from '../../img/scrollverticalarrows_83824.png'
import style from './AppLeasingLis.module.css'

const mapStatus = {
    1: 'Новая',
    2: 'Согласование КП',
    3: 'Заполнение анкеты',
    4: 'Сбор документов',
    5: 'Одобрение сделки',
    6: 'Подписание ДЛ',
    7: 'Сделка отменена',
    8: 'ДЛ подписан(ы)'
};

const AppLeasingList = ({page, setPage, main}) => {

    let [data, setData] = useState({AppLeasingList: []});
    let [load, setLoad] = useState({list: true});
    let [sortNumber, setSortNumber] = useState('asc');
    let [sortDate, setSortDate] = useState('no');
    let [sortSumm, setSortSumm] = useState('no');
    let [sortStatus, setSortStatus] = useState('no');
    let [lastActive, setLastActive] = useState('number');

    useEffect(() => {
        
        setLoad(load => ({...load, list: true}))
        // setData({AppLeasingList:[]});
        api('request', {
            Get: "AppLeasingList",
            action: 'AppLeasingList',
            CompanyId: main.CompanyId,
            token: localStorage.getItem('token')
        }).then(
            (response) => {
                if (response.s === 'success') {
                    let test = response.data.AppLeasingList ? response.data.AppLeasingList : [];
                    setData(data => ({...data, ...response.data}));
                    return test
                }

                // setData(data => ({...data, ...response.data}));
               
            }).then(
            direction => {
                window.scrollTo( 0, 0 );
                sortingNumberStart(direction)
                setLoad(load => ({...load, list: false}))
            }
        )
    }, [main.CompanyId]);


    let sortingNumberStart = (direction) => {
        if (!(direction)) {
            return
        }
        let sorted = [...direction].sort((a, b) => {
            return a.AppLeasingNo < b.AppLeasingNo ? 1 : -1
        });

        setSortNumber('asc');
        setSortSumm('no');
        setSortDate('no');
        setSortStatus('no');
        setLastActive('number');
        setData(data =>({...data, AppLeasingList: sorted}))
    };

    let sortingNumber = (direction) => {
        if (!(data && data.AppLeasingList)) {
            return
        }
        let sorted = [...data.AppLeasingList].sort((a, b) => {
            if (direction === 'asc') {
                return a.AppLeasingNo < b.AppLeasingNo ? 1 : -1
            } else if (direction === 'desc') {
                return a.AppLeasingNo < b.AppLeasingNo ? -1 : 1
            }
            return Math.random() - 0.5
        });

        setSortNumber(direction);
        setSortSumm('no');
        setSortDate('no');
        setSortStatus('no');
        setLastActive('number');
        setData(data =>({...data, AppLeasingList: sorted}))
    };

    let sortingNum = (direction, field) => {
        let sorted = [...data.AppLeasingList].sort((a, b) => {
            if (direction === 'asc') {
                return +a[field] - +b[field]
            } else if (direction === 'desc') {
                return +b[field] - +a[field]
            }
            return Math.random() - 0.5
        });
        if (field === 'AppLeasingAmount') {
            setSortSumm(direction);
            setSortNumber('no');
            setSortDate('no');
            setSortStatus('no');
            setLastActive('amount')
        } else {
            setSortStatus(direction);
            setSortSumm('no');
            setSortNumber('no');
            setSortDate('no');
            setLastActive('status')
        }
        setData(data =>({...data, AppLeasingList: sorted}))
    };

    let sortingDates = (direction) => {
        let sorted = [...data.AppLeasingList].sort((a, b) => {
            if (direction === 'asc') {
                return new Date(b.AppLeasingDate) - new Date(a.AppLeasingDate)
            } else if (direction === 'desc') {
                return new Date(a.AppLeasingDate) - new Date(b.AppLeasingDate)
            }
            return Math.random() - 0.5
        });
        setSortDate(direction);
        setSortSumm('no');
        setSortNumber('no');
        setSortStatus('no');
        setLastActive('date');
        setData(data =>({...data, AppLeasingList: sorted}))
    };

    let changePage = (leasing) => {
        console.log('------------------------------------------------');
        console.log(leasing);
        setPage({...page,...leasing, step: 'new', id: leasing.AppLeasingId})
    };

    let changePageTwo = (leasing,AppLeasingStatus) => {
        let tempPage,tempStatus;

        switch (AppLeasingStatus) {
            case 'Новая':
                tempPage = 'new'
                tempStatus = '1'
            break
            case 'Согласование КП':
                tempPage = 'kp';
                tempStatus = '2'
            break
            case 'Заполнение анкеты':
                tempPage = 'blank';
                tempStatus = '3'
            break
            case 'Сбор документов':
                tempPage = 'doc';
                tempStatus = '4'
            break
            case 'Одобрение сделки':
                tempPage = 'ok';
                tempStatus = '5'
            break
            case 'Подписание ДЛ':
                tempPage = 'end';
                tempStatus = '6'
            break
            case 'Сделка отменена':
                tempPage = 'ok';
                tempStatus = '7'
            break
            case 'ДЛ подписан(ы)':
                tempPage = 'ok';
                tempStatus = '8'
            break
            default:
                tempPage = 'new';
                tempStatus = '1'
        }

        console.log(tempPage);


        setPage({...page, AppLeasingStatus: tempStatus,step: tempPage, AppLeasingId: leasing,id: leasing})
    };

   if (load.list) {
    return <Load />
   } else {
       return (
        <div className="content-page">
            <div className="content-main">
                <div className="page-head mb-4">Мои заявки</div>
                <div className="grid-table grid-table_col-2 mb-3">
                    <div className="grid-table__item mb-2">Новых расчетов (КП): </div>
                    <div className="grid-table__item mb-2"><span onClick={()=>changePageTwo(data.AppLeasingActiveId,data.AppLeasingActiveStatus)}><Link to={'/AppLeasing/'+data.AppLeasingActiveId}>{data.KPNew}</Link></span></div>
                    <div className="grid-table__item mb-2">Активная заявка на лизинг:</div>
                    <div className="grid-table__item mb-2"><span onClick={()=>changePageTwo(data.AppLeasingActiveId,data.AppLeasingActiveStatus)}><Link to={'/AppLeasing/'+data.AppLeasingActiveId}>{data.AppLeasingActiveStatus}</Link></span></div>
                    <div className="grid-table__item mb-2">Сумма активной заявки:</div>
                    <div className="grid-table__item mb-2">{data.AppLeasingActiveAmount}</div>
                    <div className="grid-table__item mb-2">Всего расчетов (КП):</div>
                    <div className="grid-table__item mb-2"><span onClick={()=>changePageTwo(data.AppLeasingActiveId,data.AppLeasingActiveStatus)}><Link to={'/AppLeasing/'+data.AppLeasingActiveId}>{data.KPTotal}</Link></span></div>

{/* 
                    <div className="grid-table__item mb-2">Всего заявок:</div>
                    <div className="grid-table__item mb-2">{data.AppLeasingList.length}</div> */}
                </div>
                <Link to='/AppLeasing'>
                    <div className="btn btn-base">Подать новую заявку на лизинг</div>
                </Link>

                <div className="table-responsive mt-4">
                    <table className="table text-nowrap">
                        <thead>
                        <tr>
                            <th className={style.table_h + ' ' + (lastActive === 'number' ? style.opacityBlock : '')}>
                                {
                                    sortNumber === 'asc'
                                        ? <div onClick={() => sortingNumber('desc')}>
                                            № заявки
                                            <img src={fil} style={{width: '10px'}} alt="down"/>
                                        </div>
                                        : sortNumber === 'desc'
                                        ?
                                        <div onClick={() => sortingNumber('no')}>
                                            № заявки
                                            <img src={up} style={{width: '10px'}} alt="up"/>
                                        </div>
                                        :
                                        <div onClick={() => sortingNumber('asc')}>
                                            № заявки
                                            <img src={upDown} style={{width: '15px'}} alt="random"/>
                                        </div>
                                }
                            </th>
                            <th className={style.table_h + ' ' + (lastActive === 'date' ? style.opacityBlock : '')}>
                                {sortDate === 'asc'
                                    ?
                                    <div onClick={() => sortingDates('desc')}>
                                        Дата обращения
                                        <img src={fil} style={{width: '10px'}} alt="down"/>
                                    </div>
                                    : sortDate === 'desc'
                                        ?
                                        <div onClick={() => sortingDates('no')}>
                                            Дата обращения
                                            <img src={up} style={{width: '10px'}} alt="up"/>
                                        </div>
                                        :
                                        <div onClick={() => sortingDates('asc')}>
                                            Дата обращения
                                            <img src={upDown} style={{width: '15px'}} alt="random"/>
                                        </div>
                                }

                            </th>
                            <th className={style.table_h + ' ' + (lastActive === 'amount' ? style.opacityBlock : '')}>
                                {sortSumm === 'asc'
                                    ?
                                    <div onClick={() => sortingNum('desc', 'AppLeasingAmount')}>
                                        Сумма заявки
                                        <img src={fil} style={{width: '10px'}} alt="down"/>
                                    </div>
                                    : sortSumm === 'desc'
                                        ?
                                        <div onClick={() => sortingNum('no', 'AppLeasingAmount')}>
                                            Сумма заявки
                                            <img src={up} style={{width: '10px'}} alt="up"/>
                                        </div>
                                        :
                                        <div onClick={() => sortingNum('asc', 'AppLeasingAmount')}>
                                            Сумма заявки
                                            <img src={upDown} style={{width: '15px'}} alt="random"/>
                                        </div>
                                }
                            </th>
                            <th className={style.table_h + ' ' + (lastActive === 'status' ? style.opacityBlock : '')}>
                                {sortStatus === 'asc'
                                    ?
                                    <div onClick={() => sortingNum('desc', 'AppLeasingStatus')}>
                                        Статус
                                        <img src={fil} style={{width: '10px'}} alt="down"/>
                                    </div>
                                    : sortStatus === 'desc'
                                        ?
                                        <div onClick={() => sortingNum('no', 'AppLeasingStatus')}>
                                            Статус
                                            <img src={up} style={{width: '10px'}} alt="up"/>
                                        </div>
                                        :
                                        <div onClick={() => sortingNum('asc', 'AppLeasingStatus')}>
                                            Статус
                                            <img src={upDown} style={{width: '15px'}} alt="random"/>
                                        </div>
                                }
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.AppLeasingList.length !== 0 ? data.AppLeasingList.map((leasing) => {
                            return (
                                <tr key={leasing.AppLeasingId}>
                                    <td>
                                        <div
                                            onClick={() => changePage(leasing)}
                                        >
                                            {/*<img className={style.search} src={search} alt="search"/>*/}
                                            {/* <button
                                                className='btn btn-link text-primary fw-normal'
                                                style={{padding: '0'}}
                                            >{leasing.AppLeasingNo}</button> */}
                                            <Link to={'/AppLeasing/'+leasing.AppLeasingId}>{leasing.AppLeasingNo}</Link>
                                        </div>
                                    </td>
                                    <td>
                                        {leasing.AppLeasingDate}
                                    </td>
                                    <td style={{textAlign: 'right'}}>
                                        {new Intl.NumberFormat('ru-RU').format(leasing.AppLeasingAmount)}
                                    </td>
                                    <td>
                                        {mapStatus[leasing.AppLeasingStatus]}
                                    </td>
                                </tr>)
                        }) : <tr>
                            <td>Нет заявок</td>
                        </tr>}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="content-promo"/>
        </div>
    )
}
};

export default AppLeasingList
