import React from "react";
import about1 from "../images/1.jpg"
import about2 from "../images/2.jpg"
import about3 from "../images/3.jpg"
import about4 from "../images/4.jpg"
import manager from "../images/manager.svg"
import task from "../images/task.svg"
import approved from "../images/approved.svg"
import { Link } from "react-router-dom";

const About = () => {
    let [tab, setTab] = React.useState(1)
    let [modal, setModal] = React.useState(false)


    return (
        <>
            <div className="content-page">
                <div className="content-main">
                    <div className="page-head mb-4">С чего начать</div>
                    <div className="text-center text-big">Личный кабинет клиента «СТОУН-XXI». <b>От заявки до сделки в
                        одном месте.</b></div>
                    <div className="text-center text-big">Взять в лизинг быстро и удобно из любой точки мира!</div>
                    <div className="about-slider js-about-slider mt-4">
                        <div className="row">
                            <div className="col-lg-6 d-none d-lg-block">
                                <div className="about-slider__list">
                                    <div className={tab === 1 ? 'about-slider__item active' : 'about-slider__item'} onClick={()=>setTab(1)}>
                                        <div className="h6 text-body mb-2 mt-0">Скорость</div>
                                        <div className="note">Заключения сделки за 24 часа – реально! Все этапы
                                            прохождения заявки прозрачны для пользователя и удобно собраны в одном
                                            разделе.
                                        </div>
                                    </div>
                                    <div className={tab === 2 ? 'about-slider__item active' : 'about-slider__item'} onClick={()=>setTab(2)}>
                                        <div className="h6 text-body mb-2 mt-0">Прозрачность</div>
                                        <div className="note">Настройте отслеживание статусов заявки и получайте
                                            оповещения только о важных событиях, чтобы ускорить процесс заключения
                                            сделки!
                                        </div>
                                    </div>
                                    <div className={tab === 3 ? 'about-slider__item active' : 'about-slider__item'} onClick={()=>setTab(3)}>
                                        <div className="h6 text-body mb-2 mt-0">Удобство</div>
                                        <div className="note">Выбирайте автотранспорт и технику, заполняйте формы,
                                            загружайте документы и общайтесь с персональным менеджером в одном месте.
                                        </div>
                                    </div>
                                    <div className={tab === 4 ? 'about-slider__item active' : 'about-slider__item'} onClick={()=>setTab(4)}>
                                        <div className="h6 text-body mb-2 mt-0">Многофункциональность</div>
                                        <div className="note">Один личный кабинет для разных ролей: работайте с нами как
                                            Клиент, Агент и Поставщик.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-lg-6">
                                {tab === 1 && <><div className="about-slide__img-wrap">
                                    <img className="about-slide__img" alt="about1" src={about1} />
                                </div>
                                <div className="about-slide__title-head">Скорость</div>
                                <div class="about-slide__info text-center">
										<div class="h6 text-body mb-2 mt-0">Скорость</div>
										<div class="note">Заключения сделки за 24 часа – реально! Все этапы прохождения заявки прозрачны для пользователя и удобно собраны в одном разделе.</div>
									</div></>}
                                {tab === 2 && <><div className="about-slide__img-wrap">
                                    <img className="about-slide__img" alt="about1" src={about2} />
                                </div>
                                <div className="about-slide__title-head">Прозрачность</div>
                                <div class="about-slide__info text-center">
										<div class="h6 text-body mb-2 mt-0">Прозрачность</div>
										<div class="note">Настройте отслеживание статусов заявки и получайте оповещения только о важных событиях, чтобы ускорить процесс заключения сделки!</div>
									</div></>}
                                {tab === 3 && <><div className="about-slide__img-wrap">
                                    <img className="about-slide__img" alt="about1" src={about3} />
                                </div>
                                <div className="about-slide__title-head">Удобство</div>
                                <div class="about-slide__info text-center">
										<div class="h6 text-body mb-2 mt-0">Удобство</div>
										<div class="note">Выбирайте автотранспорт и технику, заполняйте формы, загружайте документы и общайтесь с персональным менеджером в одном месте.</div>
									</div></>}
                                {tab === 4 && <><div className="about-slide__img-wrap">
                                    <img className="about-slide__img" alt="about1" src={about4} />
                                </div>
                                <div className="about-slide__title-head">Многофункциональность</div><div class="about-slide__info text-center">
										<div class="h6 text-body mb-2 mt-0">Многофункциональность</div>
										<div class="note">Один личный кабинет для разных ролей: работайте с нами как Клиент, Агент и Поставщик.</div>
									</div></>}
                                {/* <div className="about-slider__content">
                                    <div className="splide">
                                        <div className="splide__track">
                                            <ul className="splide__list m-0">
                                                <li className="splide__slide">
                                                    <div className="about-slide">
                                                        <div className="about-slide__img-wrap">
                                                            <img className="about-slide__img" alt="about1" src={about1} />
                                                        </div>
                                                        <div className="about-slide__title-head">Скорость</div>
                                                        <div className="about-slide__info text-center">
                                                            <div className="h6 text-body mb-2 mt-0">Скорость</div>
                                                            <div className="note">Заключения сделки за 24 часа –
                                                                реально! Все этапы прохождения заявки прозрачны для
                                                                пользователя и удобно собраны в одном разделе.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="splide__slide">
                                                    <div className="about-slide">
                                                        <div className="about-slide__img-wrap">
                                                            <img className="about-slide__img" alt="about2" src={about2}/>
                                                        </div>
                                                        <div className="about-slide__title-head">Прозрачность</div>
                                                        <div className="about-slide__info text-center">
                                                            <div className="h6 text-body mb-2 mt-0">Прозрачность</div>
                                                            <div className="note">Настройте отслеживание статусов заявки
                                                                и получайте оповещения только о важных событиях, чтобы
                                                                ускорить процесс заключения сделки!
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="splide__slide">
                                                    <div className="about-slide">
                                                        <div className="about-slide__img-wrap">
                                                            <img className="about-slide__img" alt="about1" src={about1} />
                                                        </div>
                                                        <div className="about-slide__title-head">Удобство</div>
                                                        <div className="about-slide__info text-center">
                                                            <div className="h6 text-body mb-2 mt-0">Удобство</div>
                                                            <div className="note">Выбирайте автотранспорт и технику,
                                                                заполняйте формы, загружайте документы и общайтесь с
                                                                персональным менеджером в одном месте.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="splide__slide">
                                                    <div className="about-slide">
                                                        <div className="about-slide__img-wrap">
                                                            <img className="about-slide__img" alt="about2" src={about2} />
                                                            </div>
                                                        <div className="about-slide__title-head">Многофункциональность
                                                        </div>
                                                        <div className="about-slide__info text-center">
                                                            <div
                                                                className="h6 text-body mb-2 mt-0">Многофункциональность
                                                            </div>
                                                            <div className="note">Один личный кабинет для разных ролей:
                                                                работайте с нами как Клиент, Агент и Поставщик.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> */}


<div className="mt-3">
                                <div className="text-center">
                                    <button className={tab === 1 ? 'btn btn-slider-ab btn-slider-active' : 'btn btn-slider-ab'} onClick={()=>setTab(1)}>
                                    </button>
                                    <button className={tab === 2 ? 'btn btn-slider-ab btn-slider-active' : 'btn btn-slider-ab'} onClick={()=>setTab(2)}>
                                    </button>
                                    <button className={tab === 3 ? 'btn btn-slider-ab btn-slider-active' : 'btn btn-slider-ab'} onClick={()=>setTab(3)}>
                                    </button>
                                    <button className={tab === 4 ? 'btn btn-slider-ab btn-slider-active' : 'btn btn-slider-ab'} onClick={()=>setTab(4)}>
                                    </button>
                                </div>
                            </div>
                                <div className="text-center mt-4"><Link to='/AppLeasingList' className="btn btn-base btn-large">Перейти к
                                    заявкам</Link></div>
                            </div>
                        </div>
                    </div>
                    <div className="page-head mb-5 mt-5">6 этапов сделки для клиента</div>
                    <div className="row">
                        <div className="col-sm-6 col-lg-4 mb-5">
                            <div className="about-stage">
                                <img className="about-stage__img" alt="manager" src={manager} />
                                <div className="about-stage__titel"><b>1.</b> Выбираем желаемые условия и отправляем
                                    заявку
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 mb-5">
                            <div className="about-stage">
                                <img className="about-stage__img" alt="manager" src={manager} />
                                <div className="about-stage__titel"><b>2.</b> Ваш персональный менеджер делает расчет и
                                    отправляет Вам
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 mb-5">
                            <div className="about-stage">
                                <img className="about-stage__img" alt="manager" src={manager} />
                                <div className="about-stage__titel"><b>3.</b> Подтверждаете коммерческое предложение и
                                    заполняете анкету
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 mb-5">
                            <div className="about-stage">
                                <img className="about-stage__img" alt="task" src={task} />
                                <div className="about-stage__titel"><b>4.</b> Подгружаете список необходимых документов
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 mb-5">
                            <div className="about-stage">
                                <img className="about-stage__img" alt="approved" src={approved} />
                                <div className="about-stage__titel"><b>5.</b> Документы проверяются. Сделка одобрена,
                                    идем дальше!
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 mb-5">
                            <div className="about-stage">
                                <img className="about-stage__img" alt="task" src={task} />
                                <div className="about-stage__titel"><b>6.</b> Подписываем договор, оплачиваем аванс и
                                    получаем технику и/или автомобили
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="page-head mb-5 mt-5">Что можно сделать в личном кабинете</div>
                    <ul className="row row-cols-sm-2 row-cols-lg-3 row-cols-1" style={{"--bs-gutter-x": "40px"}}>
                        <li className="col">Поиграться с расчетами и выбрать оптимальные для себя условия в
                            <Link to="/AppLeasingNew"> калькуляторе</Link>
                        </li>
                        <li className="col">Выбрать оптимальный график платежей и лучшее
                            коммерческое предложение
                        </li>
                        <li className="col">Заполнить
                            анкету лизингополучателя и другие формы в одном месте
                        </li>
                        <li className="col">Скачать, подписать и загрузить все
                             документы, необходимые для сделки
                        </li>
                        <li className="col">Получить помощь
                            персонального менеджерав удобное время
                        </li>
                        <li className="col">Назначить
                            ответственное лицо, узнать дату и место получения предмета лизинга
                        </li>
                        <li className="col">
                            <Link to='/Search'>Найти</Link> по любым параметрам все заявки, когда-либо отправленные в компанию
                        </li>
                        <li className="col">Распределить роли пользователей кабинета в одной компании и
                            назначить им разные права
                        </li>
                        <li className="col">Завести
                            <Link to='/CompanyList'> бесконечно много компаний</Link> для работы с нами:)
                        </li>
                        <li className="col">
                            Сделать запрос в компанию, <Link onClick={()=>setModal(true)}>пожаловаться на ошибку… или похвалить</Link>
                        </li>
                    </ul>
                </div>
                <div className="content-promo"/>
            </div>





        {modal && <div className="modal-open"><div class="modal fade show" id="modalManager" tabindex="-1" aria-labelledby="modalManager" aria-modal="true" role="dialog" style={{display: 'block'}}>
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
                <div class="modal-header">
						<h5 class="modal-title">Связаться с менеджером</h5>
						<button class="btn-close" type="button" data-bs-dismiss="modal" onClick={()=>setModal(false)}></button>
					</div>
                    <div class="modal-body"> У Вас есть замечания по работе личного кабинета?<br />
Есть предложения по работе с компанией?<br />
Хотите похвалить работу менеджера?<br />
Направляйте письмо на <a href="mailto:feedback@stone-xxi.ru">feedback@stone-xxi.ru</a>, здесь рассматривают каждое обращение!
</div>
<div class="modal-footer"></div>
					{/* <div class="modal-header">
						<h5 class="modal-title">Связаться с менеджером</h5>
						<button class="btn-close" type="button" data-bs-dismiss="modal" onClick={()=>setModal(false)}></button>
					</div>
					<div class="modal-body">
						<p>Разберем ваш запрос в течение 3 рабочих дней.</p>
						<label class="label-bold">Напишите ваш вопрос</label>
						<textarea class="form-control" placeholder="" rows="4"></textarea>
					</div>
					<div class="modal-footer">
						<button class="btn btn-base" onClick={()=>setModal(false)}>Отправить</button>
					</div> */}
				</div>
			</div>
		</div></div>}



        </>
    )
}

export default About
