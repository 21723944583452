import React, {useState,useMemo} from 'react'

import {Link,useHistory} from 'react-router-dom'

import Search from './svg/Search.svg'
import User from './svg/user.svg'

import Logo from './images/logo.svg'

let Select = ({main,reload,com}) => {
    let [data, setData] = useState({CompanyId:main.CompanyId})

    let history = useHistory()

    return useMemo(() =>  {
        var change = (e) => {

            console.log(e.target.value);
            localStorage.setItem('CompanyId',e.target.value)
            setData(data=> ({...data,CompanyId:e.target.value}))
            com(e.target.value)
            history.push('/About')
            e.persist()
        }
        console.log(data);
        return <select className="form-control form-control-base form-select" onChange={change} value={data.CompanyId}>
            {main.companies && main.companies.map((d,i)=><option value={d.id} key={i}>{d.name}</option>)}
    </select>},[data,main])
}

let Header = ({main,reload,com,ad}) => {




    let [data, setData] = useState({search:false,menuOpen:false})

    let menuOpen = () => {

       if (!data.menuOpen) {
        document.body.classList.add('mobile-menu-open')
        setData(data => ({...data,menuOpen:true}))
       } else {
        document.body.classList.remove('mobile-menu-open');
        setData(data => ({...data,menuOpen:false}))
       }  

    }

    console.log('ddd');
    

    return <header className="header">
        <Link className="header-logo" to="/"><img class="header-logo__img" src={Logo} /></Link>
    <div className="header-inner">
        <div className="d-xl-none ms-auto d-flex align-items-center">
            <div onClick={menuOpen} className="menu-bar js-menu-bar">
                <span className="menu-bar__stick"></span><span className="menu-bar__stick"></span><span className="menu-bar__stick"></span>
            </div>
        </div>
        <div className="header-alert">
            <div className="header-alert__status"><b className="text-danger fz-16">Важная информация!</b>
                <div className="text-secondary">1 января 2022</div>
            </div>
            <div className="header-alert__info"><a href={ad.NEWS && ad.NEWS.LINK} target="_blank"><b>{ad.NEWS && ad.NEWS.NAME}</b></a>
                <div className="header-alert__info-title">{ad.NEWS && ad.NEWS.PREVIEW_TEXT}</div>
            </div>
        </div>
        <div className="header-lk">
            <div className="header-lk__title mb-1">Добро пожаловать в личный кабинет, {main.userinfo}!<Link className="icon-user ms-2f" to='/UserSettings' ></Link></div>
            <div className="d-flex">
                <div className="form-group flex-grow-1 me-3">
                    <Select main={main} reload={reload} com={com} />
                </div><Link className="btn btn-base" to="/Search"><i className="icon-loupe"></i></Link>
            </div>
        </div>
    </div>
</header>

}

export default Header
