import React from 'react';
import {postRequest} from "../../Run";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";

import style from "./Adress.module.css";

const InnPrompt = (props) => {
    const [options, setOptions] = React.useState([]);
    const [input, setInput] = React.useState('');
    const [showTooltip, setShowTooltip] = React.useState(false);
    let onlyInn = props.onlyInn !== undefined ? props.onlyInn : true;
    const getOptions = (input) => {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Authorization: Token 599670fac2d78bb10384a4655f5b8fcc465125c2'
        };
        let query = input;

        postRequest(
            `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party`,
            {query: query},
            headers
        ).then((response) => {
            setOptions(response.suggestions)
        })
    };

    const onChange = (evt) => {
        let value = evt.currentTarget.value;
        props.onChange(value, props.name);
        getOptions(value);
    };

    const toggleTooltip = () => {
        setShowTooltip(!showTooltip)
    };

    const onBlur = () => {
        if (props.hasOwnProperty('onBlur')) {
            props.onBlur()
        }
        toggleTooltip();
        setTimeout(() => {
            setOptions([]);
        }, 200);

    };

    const setCurrentOption = (value) => {
        props.onChange(value, props.name, false);
        setOptions([]);
    };

    return (
        <>


<div className={`form-group mb-3 form-floating ${props.errors && 'has-error'}`}>

            <input
                type="text"
                name={props.name}
                value={props.value}
                onChange={onChange}
                className={props.className}
                autoComplete='off'
                onBlur={onBlur}
                disabled={props.disabled}
            />
            <label>{props.label}</label>
            </div>
            {!!options.length &&
            <div className={style.dropDown}>
                {options.map( el => (
                    <div
                        className={style.dropDown__item}
                        onClick={() => setCurrentOption(el)}
                        key={el.data.inn}
                    >
                        <div>{el.data.name.full_with_opf}</div>
                        <span>{el.data.inn} {el.data.address.value}</span>
                    </div>
                ))}
            </div>
            }
        </>
    )
};

export default InnPrompt;
