import {useEffect, useState} from 'react'
import {api, Button, chTel} from '../../Run'

import Block1 from './blocks/Block1'
import Block2 from './blocks/Block2'
import Block3 from './blocks/Block3'
import Block4 from './blocks/Block4'
import Block5 from './blocks/Block5'
import Block6 from './blocks/Block6'
import Block7 from './blocks/Block7'
import Block8 from './blocks/Block8'

import moment from "moment"

import {
    mainTemp,
    FounderFL,
    FounderUL,
    Beneficiary,
    LPActivityType,
    LPPartner,
    Credit,
    BankAccount
} from './ErrorInput'


let Blank = ({main,page,setPage}) => {

    let [data, setData] = useState({id:page.id,Founder:[{
        ...FounderFL,
        FounderFLCitizenship: 'РФ'
    }],

    Beneficiary: [{
        ...Beneficiary,
        BeneficiaryCitizenship: 'РФ'
    }],
    LPActivityType: [LPActivityType],
    LPPartner: [LPPartner],
    Credit: [Credit],
    BankAccount: [BankAccount]
});

console.log(main.companies.map((d,i)=> d.id === main.CompanyId && d.name));

let lll;

main.companies.map((d,i)=> d.id === main.CompanyId && d.name).forEach(element => {
    if (element) {
        lll = element;
    }
    
});

    let [input, setInputs] = useState({
        DocType: 'Устав',
        LPName: lll,
        ContractCurrency: 'RUR',
        FounderFL: [{
            ...FounderFL,
            FounderFLCitizenship: 'РФ'
        }],
        FounderUL: [],
        Beneficiary: [{
            ...Beneficiary,
            BeneficiaryCitizenship: 'РФ'
        }],
        LPActivityType: [LPActivityType],
        LPPartner: [LPPartner],
        Credit: [Credit],
        BankAccount: [BankAccount],
        AppLeasingId: data.id,
        AdditionalInfo:'',
        SL:10,Adv:10
    });

    let [button, setButton] = useState({save: false,saveB: false})

    let [error, setError] = useState({
        main: mainTemp,
        Founder: [FounderFL],
        Beneficiary: [Beneficiary],
        LPActivityType: [LPActivityType],
        LPPartner: [LPPartner],
        Credit: [Credit],
        BankAccount: [BankAccount]
    });

    let [errorTemp, setErrorTemp] = useState({main:[],FounderFL:[],FounderUL:[],Beneficiary:[],LPActivityType:[],LPPartner:[],Credit:[],BankAccount:[]});

    let [pa, setPa] = useState({print: false});



    useEffect(() => {
        document.title = 'Анкета ЛП '+page.AppLeasingNo
        window.scrollTo(0,0)
        api('request', {
            "Get": "AppFormEdit",
            "action": "AppFormSave",
            "AppLeasingId": data.id
        }).then((d) => {

            delete d.data.Data['Get'];
            delete d.data.Data['action'];

            if (d.data.Data.FounderFL === undefined || d.data.Data.FounderFL.length === 0) {
                d.data.Data.FounderFL = [{
                    ...FounderFL,
                    FounderFLCitizenship: 'РФ'
                }];
            }
            if (d.data.Data.FounderUL === undefined || d.data.Data.FounderUL.length === 0) {
                d.data.Data.FounderUL = [];
            }

            if (d.data.Data.Beneficiary === undefined || d.data.Data.Beneficiary.length === 0) {
                d.data.Data.Beneficiary = [{
                    ...Beneficiary,
                    BeneficiaryCitizenship: 'РФ'
                }];
            }

            if (d.data.Data.LPActivityType === undefined || d.data.Data.LPActivityType.length === 0) {
                d.data.Data.LPActivityType = [LPActivityType];
            }

            if (d.data.Data.LPPartner === undefined || d.data.Data.LPPartner.length === 0) {
                d.data.Data.LPPartner = [LPPartner];
            }

            if (d.data.Data.Credit === undefined || d.data.Data.Credit.length === 0) {
                d.data.Data.Credit = [Credit];
            }


            if (d.data.Data.BankAccount === undefined || d.data.Data.BankAccount.length === 0) {
                d.data.Data.BankAccount = [BankAccount];
            }


            setError({
                ...data,
                main: mainTemp,
                Founder: [...d.data.Data.FounderFL.map(() => FounderFL), ...d.data.Data.FounderUL.map(() => FounderUL)],
                Beneficiary: [...d.data.Data.Beneficiary.map(() => Beneficiary)],
                LPActivityType: d.data.Data.LPActivityType.map(() => LPActivityType),
                LPPartner: d.data.Data.LPPartner.map(() => LPPartner),
                Credit: d.data.Data.Credit.map(() => Credit),
                viewSix: d.data.Data.Credit.length > 0 ? true : false,
                BankAccount: d.data.Data.BankAccount.map(() => BankAccount)
            });

            setInputs({...input,...d.data.Data, LPName: lll});

            setData(data => ({
                ...data,
                Founder: [...d.data.Data.FounderFL],
                Beneficiary: d.data.Data.Beneficiary,
                LPActivityType: d.data.Data.LPActivityType,
                LPPartner: d.data.Data.LPPartner,
                Credit: d.data.Data.Credit,
                viewSix: false,
                BankAccount: d.data.Data.BankAccount
            }))

            d.data.Data.Credit.forEach(element => {
                if (element.Creditor !== "" || element.CreditAmount !== "" || element.CreditBalance !== "") {
                    setData(data => ({...data, viewSix: true}));
                    return;
                } else {
                    setData(data => ({...data, Credit:[]}));
                }
            });
            
            // setInputs(input => ({
            //     ...input,
            //     LPFormId: d.data.Data.LPFormId,
            //     SaveDate: d.data.Data.SaveDate
            // }))
            // window.scrollTo(0,0)
        })
    },[])
    
    let save = (e) => {


        rrr().then((dd) => {
            console.log(input.FounderFL);
            api('request', {
                "Put": "AppFormEdit",
                "action": "AppFormSaveTmp",
                ...input,
                ...data,
                FounderFL: dd.tempFounderFL,
                FounderUL: dd.tempFounderUL,
            }).then((d) => {
                if (d.data[0].LPFormId || d.data[0].LPFormId === 0 || d.data[0].LPFormId === "Отсутсвует LPFormId") {
                    setInputs(input => ({...input, LPFormId: d.data[0].LPFormId, SaveDate: d.data[0].SaveDate}))
                } else {
                    setInputs(input => ({...input, SaveDate: d.data[0].SaveDate}))
                }
            })

        })
    

    };
    



    const rrr = async () => {
        let tempFounderFL = [];
        let tempFounderUL = [];

        data.Founder.forEach(e => {
            if (e.type) {
                tempFounderFL = [...tempFounderFL, {...e, type: true}]
            } else {
                tempFounderUL = [...tempFounderUL, e]
            }
        });
        setInputs({...input, ...data, FounderFL:tempFounderFL,FounderUL:tempFounderUL});
        setData(data => ({
            ...data,
            Founder: [...tempFounderUL,...tempFounderFL]
        }))

        return {tempFounderUL,tempFounderFL}
    }
  
    
    const step = (e) => {
        rrr().then((dd) => {

            api('request', {
                ...input,
                "Put": "AppFormEdit",
                "action": "AppFormSave",
                FounderFL: dd.tempFounderFL,
                FounderUL: dd.tempFounderUL,
                Beneficiary: data.Beneficiary,
                LPActivityType: data.LPActivityType,
                LPPartner: data.LPPartner,
                Credit: data.Credit,
                BankAccount: data.BankAccount
            }
        ).then((d) => {

            if (d.data.error) {
                setError(error => ({...error, err: {title: 'Внимание!', msg: d.data.error.error}}))
                // window.scrollTo(0, 0)
            } else {

                if (d.data.Error) {
                    setError(error => ({...error, err: {title: 'Внимание!', msg: d.data.Error}}))
                    if (d.data.errors) {

                        setErrorTemp(errorTemp => ({...{main:[],FounderFL:[],FounderUL:[],Beneficiary:[],LPActivityType:[],LPPartner:[],Credit:[],BankAccount:[]},...d.data.errors}))
                        setError(error => ({
                            ...error,
                            tempError: d.data.errors,
                            sum: d.data.errors.main && d.data.errors.main[1] ? d.data.errors.main[1] : false,
                            main: d.data.errors.main && d.data.errors.main[0] ? d.data.errors.main[0] : mainTemp,
                            BankAccount:  [...data.BankAccount.map((de, i) => {
                                return d.data.errors.BankAccount ? d.data.errors.BankAccount[i] ? {...BankAccount, ...d.data.errors.BankAccount[i]} : BankAccount : BankAccount
                            })],
                            Credit: [...data.Credit.map((de, i) => {
                                return d.data.errors.Credit ? d.data.errors.Credit[i] ? {...Credit, ...d.data.errors.Credit[i]} : Credit : Credit
                            })],
                            LPPartner: [...data.LPPartner.map((de, i) => {
                                return d.data.errors.LPPartner ? d.data.errors.LPPartner[i] ? {...LPPartner, ...d.data.errors.LPPartner[i]} : LPPartner : LPPartner
                            })],
                            LPActivityType: [...data.LPActivityType.map((de, i) => {
                                return d.data.errors.LPActivityType ? d.data.errors.LPActivityType[i] ? {...LPActivityType, ...d.data.errors.LPActivityType[i]} : LPActivityType : LPActivityType
                            })],
                            Beneficiary: [...data.Beneficiary.map((de, i) => {
                                return d.data.errors.Beneficiary ? d.data.errors.Beneficiary[i] ? {...Beneficiary, ...d.data.errors.Beneficiary[i]} : Beneficiary : Beneficiary
                            })],
                            Founder: [
                                ...dd.tempFounderUL.map((de, i) => {
                                    return d.data.errors.FounderUL ? d.data.errors.FounderUL[i] ? {...FounderUL, ...d.data.errors.FounderUL[i]} : FounderUL : FounderUL 
                                }),
                                ...dd.tempFounderFL.map((de, i) => {
                                    return d.data.errors.FounderFL ? d.data.errors.FounderFL[i] ? {...FounderFL, ...d.data.errors.FounderFL[i]} : FounderFL : FounderFL 
                                })
                            ]
        
                        }))
                        
                    }
                } else {
                    setPage(page => ({...page,AppLeasingStatus:d.data['0'].AppLeasingStatus}))
                    setButton(button => ({...button, print: true}))
                    setError(error => ({...error, err: {title: 'Внимание!', msg: d.data.msg}}))
                    setErrorTemp(errorTemp => ({main:[],FounderFL:[],FounderUL:[],Beneficiary:[],LPActivityType:[],LPPartner:[],Credit:[],BankAccount:[]}))
                    // window.scrollTo(0, 0)
                }

            }



            setButton(button => ({...button, saveB: false}))
       });
        })

    
        e.preventDefault()
    }

    const changeMain = (e) => {
        setInputs(input => ({...input, [e.target.name]: e.target.value}))
        e.persist()
    }

    let AddFounderFL = () => {

    }

    // let tr = () => {
    //     console.log(sssTest);
    // }

    let [sssTest,sssTestset] = useState(0)

    useEffect(()=> {
        if (sssTest !== 0) {
            save()
        }
    },[sssTest]) 

    let changeArrChe  = (e, i, type) => {

        // sssTestset(sssTest=> ({dd:'222'}),tr());

console.log(e.target.checked);

        setData(data => ({
            ...data, [type]: data[type].map((d, ii) => {
                return i === ii ? {...d, [e.target.name]: e.target.checked ? 1 : 0 } : d
            })
        }));
        sssTestset(sssTest+1)
    }

    const changeArr = (e, i, type) => {

            setData(data => ({
                ...data, [type]: data[type].map((d, ii) => {
                    return i === ii ? {...d, [e.target.name]: e.target.value} : d
                })
            }))
        
        e.persist()
    };

    let del = (d, i, arr) => {
        let temp = data[d].filter((dd, ii) => ii !== i);
        setData(data => ({...data, [d]: temp}));
        if (arr) {
            let temp = error[arr].filter((dd, ii) => ii !== i);
            setError(error => ({...error, [arr]: temp}))
        }
    };

    let step4 = () => {
        setPage(page => ({...page,step:'doc'}))
    }



    let printDoc = (e) => {


        setTimeout(function () {

            api('request', {
                "Get": "AppLeasingForm",
                "action": "AppLeasingEdit",
                CompanyId: main.CompanyId,
                AppLeasingId: data.id
            }).then((d) => {
                var frm = document.getElementById('test').contentWindow;
                frm.postMessage({...input,...data,viewSix:data.viewSix,pl:d.data,main:main});
                setPa(pa => ({...pa, print: true}));
                setTimeout(function () {
                    frm.print();
                    setPa(pa => ({...pa, print: false}))

                }, 300);
            })

        }, 200);

        e.preventDefault()
    };


    let upload = (e) => {
        setButton(button => ({...button, save:true}))

        let temp = [];
        let file = e.target.files
        if (file) {


            for (let index = 0; index < file.length; index++) {
        
                
           

            let reader = new FileReader()
            reader.readAsDataURL(file[index])
            reader.onload = () => {

                temp = [...temp,{file:reader.result,name:file[index].name}]
                
        

            if ((index+1) === file.length) {

                    api('upload',{AppLeasingId:data.id,files:temp}).then((d) => {
                    setData(data => ({...data, file:true, fileUrl:d.url}))
                    setButton(button => ({...button, save:false}))

                    api('request',{Put:'AppFormEdit',"action":"AppFormLoadScan","LPFormId":input.LPFormId,"AppLeasingId":data.id,"AppLeasingFile":d.url,
                    token: localStorage.getItem('token')}).then((dd) => {
                        console.log(dd);
                        if (dd.data.Error) {
                            setError(error => ({...error, err: {title: 'Внимание!', msg: dd.data.Error}}))
                        } else {
                            setError(error => ({...error, err: {title: 'Внимание!', msg: 'Анкета отправлена на обработку менеджеру!'}}))
                        }
                    })


                })
            }
            }

        }

        } else {
            setButton(button => ({...button, up:false}))
        }

    };



    const handleChangeInn = (value, name, onlyValue=true) => {
        if (onlyValue) {
            setInputs({...input, [name]: value})
        } else {
                let organization = value.data;
            let registation_date = '';
            if (organization.state.registration_date) {
                registation_date = moment(organization.state.registration_date).format('YYYY-MM-DD')
            }
            let directorName = ['', '', ''];
            if (organization.management) {
                directorName = organization.management.name.split(' ');
            }

            const inputsToUpdate = {
                LPINN: organization.inn,
                AddressFact: organization.address.unrestricted_value,
                OGRN: organization.ogrn,
                OKPO: organization.okpo,
                RegDate: registation_date,
                EmployeesNumber: organization.employee_count || input.EmployeesNumber,
                DirectorLName: directorName[0],
                DirectorPosition: (organization.management || {}).post || '',
                DirectorFName: directorName[1],
                DirectorMName: directorName[2],
            };

            setInputs({
                ...input,
                ...inputsToUpdate
            });
            let dataToUpdate = {...data};
            dataToUpdate = {...dataToUpdate, LPPartner: dataToUpdate.LPPartner.map( p => {
                    p.LPPartnerName = '';
                    return p;
                })};
            dataToUpdate = {...dataToUpdate, Credit: dataToUpdate.Credit.map( c => {
                    c.Creditor = '';
                    return c;
                })};

            if (organization.founders) {
                const Founders = organization.founders.map( founder => {
                    let newFounder;
                    if (founder.type === 'LEGAL') {

                        newFounder = {
                            FounderULINN: founder.inn,
                            FounderULIOGRN: founder.ogrn,
                            FounderULName: founder.name,
                            FounderULShare: 0,
                            type: false
                        }
                    }
                    if (founder.type === 'PHYSICAL') {
                        newFounder = {
                            FounderFLBD: "",
                            FounderFLBPlace: "",
                            FounderFLCitizenship: "РФ",
                            FounderFLFName: "",
                            FounderFLMName: "",
                            FounderFLPassDate: "",
                            FounderFLPassPCode: "___-___",
                            FounderFLPassPlace: "",
                            FounderFLPassportSN: "",
                            FounderFLRegistration: "",
                            FounderFLShare: "",
                            type: true
                        };

                        let founderName = ['', '', ''];
                        if (founder.name) {
                            founderName = founder.name.split(' ')
                        }
                        newFounder.FounderFLLName = founderName[0] || '';
                        newFounder.FounderFLFName = founderName[1] || '';
                        newFounder.FounderFLMName = founderName[2] || '';
                    }
                    if (founder.share) {
                        let percent = 0;
                        if (founder.share.type === 'PERCENT') {
                            percent = founder.share.value;
                        }
                        if (founder.share.type === 'FRACTION') {
                            percent = Math.round((founder.share.numerator/founder.share.denominator) * 100)
                        }
                        if (founder.type === 'PHYSICAL') {
                            newFounder.FounderFLShare = percent;
                        }
                        if (founder.type === 'LEGAL') {
                            newFounder.FounderULShare = percent;
                        }
                    }
                    return newFounder;
                });
                dataToUpdate.Founder = Founders;

            }
            setData(data => ({
                ...data,
                ...dataToUpdate
            }));
            // setError({
            //     ...error,
            //     ...data,
            //     ...dataToUpdate
            // })

        }
    };

    const changeAddress = (name) => (value) => {
        setInputs({...input, [name]: value})
    };

    const changeAddressArr = (name, elName, i) => (value) => {
        setData({
            ...data,
            [name]: data[name].map( (el, j) => {
                return i === j ? {...el, [elName]: value} : el
            })
        })
    };

    const changePassport = (name, elName, elCode, i) => (value) => {
        setData({
            ...data,
            [name]: data[name].map( (p, j) => {
                return i === j ? {...p, [elName]: value.name, [elCode]: value.code} : p
            })
        })
    };

    const changeInnArr = (type, i, name, value) => {
        console.log(value);
        setData({
            ...data,
            [type]: data[type].map((d, j) => {
                return i === j ? {...d, [name]: value} : d
            })
        })
    };

    const changeInnArrTwo = (type, i, inn,ogrn,name,valueInn,valueOgrn,valueName) => {
        // console.log(value);
        setData({
            ...data,
            [type]: data[type].map((d, j) => {
                return i === j ? {...d, [inn]: valueInn, [ogrn]: valueOgrn, [name]: valueName} : d
            })
        })
    };



    const changeBankInfo = (value, name, i) => {
        setData({
            ...data,
            [name]: data[name].map( (b, j) => {
                return j === i ? {...b, BankAccountCode: value.bic, BankAccountName: value.name} : b
            })
        })
    };

    let disabled = !(page.AppLeasingStatus == 3);

    return <><div class="row justify-content-between mt-3">
        <div class="col-auto">
            {/* <a class="d-flex align-items-center line-height-1_2" href=""><i class="icon-download me-2"></i>Анкета-заявка лизингополучателя</a> */}
        </div>
        {input.SaveDate && <div class="col-auto text-secondary"><span class="text-nowrap me-2">Анкета сохранена:</span><span class="text-nowrap me-2">{moment(input.SaveDate).format('DD.MM.YYYY H:m:s')}</span></div>}
    </div>
    {/* <div class="mt-4 d-flex flex-column mb-4"> */}
        {/* <a class="mb-2" href="">Загрузить анкету с ЭЦП</a> */}
        {/* <a class="mb-2" href="">Загрузить анкету с обычной подписью</a> */}
        {/* <input

                            type="file"
                            id="my_file"
                            onChange={upload}  multiple/>
                            <div>
                            <button onClick={() => document.getElementById('my_file').click()} className="btn btn-ref" disabled={!(page.AppLeasingStatus == 4)}>Загрузить анкету с обычной подписью</button>
                            </div>
    </div> */}
    <div className="col-12">{error.err && <div className='login-alert p-3 text-center mb-3'><div className='text-primary'>{error.err.title}</div><span>{error.err.msg}</span></div>}</div>
    <hr size="2" /><b>Шаг 1 из 8 Общие сведения об организации-лизингополучателе</b>
    <Block1 save={save} input={input} setInputs={setInputs} error={error} changeMain={changeMain} handleChangeInn={handleChangeInn} changeAddress={changeAddress} disabled={disabled} />

    <hr size="2" /><b>Шаг 2 из 8 Сведения об учредителях</b>
    <Block2 save={save} data={data} setData={setData} changeArrChe={changeArrChe} error={error} setError={setError} del={del} changeArr={changeArr} changePassport={changePassport} changeAddress={changeAddressArr} changeInnArr={changeInnArr} changeInnArrTwo={changeInnArrTwo} disabled={disabled}/>

    <hr size="2" /><b>Шаг 3 из 8 Сведения о бенефициарных владельцах, которые не являются учредителями</b>
    <Block3 save={save} data={data} setData={setData} error={error} setError={setError} del={del} changeArr={changeArr} changeArrChe={changeArrChe} changeAddress={changeAddressArr} changePassport={changePassport} disabled={disabled} />

    <hr size="2" /><b>Шаг 4 из 8 Виды деятельности лизингополучателя</b>
    <Block4 save={save} data={data} setData={setData} error={error} setError={setError} del={del} changeArr={changeArr} disabled={disabled} />

    <hr size="2" /><b>Шаг 5 из 8 Основные контрагенты лизингополучателя</b>
    <Block5 save={save} data={data} setData={setData} error={error} setError={setError} del={del} changeInnArr={changeInnArr} disabled={disabled}  />

    <hr size="2" /><b>Шаг 6 из 8 Информация о кредитах, договорах лизинга и займах, в том числе, от учредителей</b>
    <Block6 save={save} data={data} setData={setData} error={error} setError={setError} del={del} changeArr={changeArr} changeInnArr={changeInnArr}  disabled={disabled} />
 
    <hr size="2" /><b>Шаг 7 из 8 Сведения об открытых банковских счетах лизингополучателя</b>
    <Block7 save={save} data={data} setData={setData} error={error} setError={setError} del={del} changeArr={changeArr} changeBankInfo={changeBankInfo} disabled={disabled} />

    <hr size="2" /><b>Шаг 8 из 8 Дополнительная информация, которая может повлиять на одобрение сделки</b>
    <Block8 save={save} input={input} setInputs={setInputs} error={error} changeMain={changeMain} disabled={disabled}  />

    <div class="row mt-4">
	<div class="col-lg-8">
		<div class="form-group">
			<label>
				<input type="checkbox" name="checkbox" onClick={save}  disabled={!(page.AppLeasingStatus == 3)} defaultChecked={!(page.AppLeasingStatus == 3)}  onChange={() => setData(data => ({...data, save: !data.save}))} /><span>Заявитель подтверждает, что вся вышеприведенная информация является подлинной, соответствует истинным фактами выражает согласие на получение ООО «СТОУН-ХХI» кредитного отчета в отношении  организации в ЗАО «Объединенное Кредитное Бюро» с целью проверки благонадежности, а также на проведение дальнейшего анализа предприятия.</span>
			</label>
		</div>
	</div>
</div>

    <div class="mt-3">
	<div class="row align-items-center">
    {/* <div className="col-12">{error.sum && <div className='login-alert p-3 text-center mb-3'><div className='text-primary'>Внимание!</div><span>{error.sum.Error}</span></div>}</div> */}
    <div className="col-12">{error.err && <div className='login-alert p-3 text-center mb-3'><div className='text-primary'>{error.err.title}</div><span>{error.err.msg}</span></div>}</div>


    <div className="col-12">{errorTemp.main.length > 0  && <div className='login-alert px-3 pt-3  text-center mb-3'>
    {/* <span>gfgg</span> */}
    <div style={{textAlign:'left'}}><ul className='bUl'>{errorTemp.main.map((d,i)=>{

        if (d.Error) {
            return <li key={i}>{d.Error}</li>
        } else {
            let temp = [];

            for (let index = 0; index < Object.keys(d).length; index++) {
                temp = [...temp, {Error: Object.values(d)[index]}]
            }

            return temp.map((d,i)=>{
                return <li key={i}>{d.Error}</li>
            })
        }
        
      
            
        
                           
                        })}</ul>
                        </div>
    </div>}</div>


    <div className="col-12">{(errorTemp.main.length > 0 && !errorTemp.main[0].Error) && <div className='login-alert p-3 text-center mb-3'><span>В шаге 1 заполнены не все поля!</span></div>}</div> 

    <div className="col-12">{(errorTemp.FounderFL.length > 0  || errorTemp.FounderUL.length > 0) && <div className='login-alert p-3 text-center mb-3'><span>В шаге 2 заполнены не все поля!</span></div>}</div> 


    <div className="col-12">{errorTemp.Beneficiary.length > 0  && <div className='login-alert p-3 text-center mb-3'><span>В шаге 3 заполнены не все поля!</span></div>}</div> 

    <div className="col-12">{errorTemp.LPActivityType.length > 0  && <div className='login-alert p-3 text-center mb-3'><span>В шаге 4 заполнены не все поля!</span></div>}</div> 

    <div className="col-12">{errorTemp.LPPartner.length > 0  && <div className='login-alert p-3 text-center mb-3'><span>В шаге 5 заполнены не все поля!</span></div>}</div> 

    <div className="col-12">{errorTemp.Credit.length > 0  && <div className='login-alert p-3 text-center mb-3'><span>В шаге 6 заполнены не все поля!</span></div>}</div> 

    <div className="col-12">{errorTemp.BankAccount.length > 0  && <div className='login-alert p-3 text-center mb-3'><span>В шаге 7 заполнены не все поля!</span></div>}</div> 


  


		<div class="col-auto mb-2">
			<button class="btn btn-ghost" onClick={step} disabled={!data.save || button.saveB || !(page.AppLeasingStatus == 3)}>Сохранить</button>
		</div>

        <div class=" mt-3 mb-4">* ВНИМАНИЕ! Сохраняйте оригинал документа для получения предмета лизинга.</div>
<div></div>
		<div class="col-auto mb-3"><button class="d-flex align-items-center line-height-1_2 btn btn-ref" onClick={printDoc} disabled={!(page.AppLeasingStatus == 4 || page.AppLeasingStatus == 5 || page.AppLeasingStatus == 6 || page.AppLeasingStatus == 7 || page.AppLeasingStatus == 8)}><i class="icon-print me-2"></i>Распечатать</button></div>
		{/* <div class="col-auto mb-2"><a href="">Отменить сохранение анкеты</a></div> */}
        <div class="col-auto mb-2">
        {/* <a class="mb-2" href="">Загрузить анкету с ЭЦП</a> */}
        {/* <a class="mb-2" href="">Загрузить анкету с обычной подписью</a> */}
        <input

                            type="file"
                            id="my_file"
                            onChange={upload}  multiple/>
                            <div>
                            <button onClick={() => document.getElementById('my_file').click()} className="btn btn-base" disabled={!(page.AppLeasingStatus == 4)}>Загрузить анкету с обычной подписью</button>
                            </div>
    </div>
    <div class="col-auto mb-2">
			<button class="btn btn-base" onClick={step4} disabled={!(page.AppLeasingStatus == 4 || page.AppLeasingStatus == 5 || page.AppLeasingStatus == 6 || page.AppLeasingStatus == 7 || page.AppLeasingStatus == 8)}>Перейти к загрузке документов</button>
		</div>
	</div>
</div>


<iframe src='/blank.html' id='test' width='0' height='0' />

    </>
}

export default Blank