import {useEffect, useState} from 'react'
import {LPActivityType} from '../ErrorInput'


let Blank4 = ({save,input,data,setData,error,setError,del,changeArr,disabled}) => {
    return data.LPActivityType.map((d, i) =><><div class="row mt-3 flex-column mb-3">
	<div class="col-md-8 col-lg-6">
		<div className={`form-group mb-3 form-floating ${error.LPActivityType[i].LPActivityTypeName && 'has-error'}`}>
			<input class="form-control" onBlur={save}  name="LPActivityTypeName" onChange={(e) => changeArr(e, i, 'LPActivityType')} value={d.LPActivityTypeName} placeholder="Иванов"  disabled={disabled}/>
			<label>Вид деятельности</label>
		</div>
	</div>
	<div class="col-md-8 col-lg-6">
		<div className={`form-group mb-3 form-floating ${error.LPActivityType[i].LPActivityTypeRevenueShare && 'has-error'}`}>
			<input class="form-control" onBlur={save}  name="LPActivityTypeRevenueShare" onChange={(e) => changeArr(e, i, 'LPActivityType')} value={d.LPActivityTypeRevenueShare} placeholder="Иванов" disabled={disabled} />
			<label>Доля в выручке</label>
		</div>
	</div>
</div>

<div className='mb-3'>{data.LPActivityType.length !== 1 &&
                        <button
                            onClick={() => del('LPActivityType', i, 'LPActivityType')}
                            className='btn btn-ref' onBlur={save}  disabled={disabled}>
                            Удалить вид деятельности
                        </button>}
                        </div>


						{data.LPActivityType.length === (i + 1) &&<button className='btn btn-ref' onClick={(e) => {
                                setError(error => ({...error, LPActivityType: [...error.LPActivityType, LPActivityType]}));
                                setData(data => ({...data, LPActivityType: [...data.LPActivityType, LPActivityType]}))
                            }} onBlur={save}  disabled={disabled}>Добавить вид деятельности</button>}</>)
}

export default Blank4