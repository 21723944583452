import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import {api,Load} from "../../Run"

let Ok = ({page,setPage,main}) => {

	let [data,setData] = useState({})

	useEffect(()=> {
		api('request', {
			"Get": "DocList",
			"action": "DocPackList",
			"AppLeasingId": page.id
		}).then((d) => {
			let temp = false;
			d.data.DocPackItem.forEach(e => {
				if (e.DocStatus === '0' || e.DocStatus === '3') {
					temp = true;
				}
			});
			setData(data => ({...data,...d.data,two: temp}))
		})
	},[])


	let ok = () => {
		api('request', {"Put":"AppLeasingAcceptPage","action":"AppLeasingAccept","AppLeasingId":page.id}).then((d) => {
			if (d.data.Error) {
				
			} else {
				setPage(page => ({...page,step:'end',AppLeasingStatus:String(d.data.AppLeasingStatus)}))
			}
		})
		
	}


	let cancel = () => {
		api('request', {"Put":"AppLeasingAcceptPage","action":"AppLeasingCancel","AppLeasingId":page.id}).then((d) => {
			if (d.data.Error) {
				
			} else {
				setPage(page => ({...page,AppLeasingStatus:String(d.data.AppLeasingStatus)}))
			}
		})
		
	}



    return <>

<div class="mt-5"><b>Шаг 1 из 3. Проверка документов</b>

{data.DocPackStatus == '0' && <> <div class="row mt-2">
		<div class="col-lg-8">Автоматическая проверка загруженных документов на соответствие формату, проверка корректности содержимого.</div>
	</div>

	<button onClick={()=>setPage(page => ({...page,step:'doc'}))} className='btn btn-ref'>Вернуться к сбору документов</button></>}

	{data.DocPackStatus == '1' && <> <div class="row mt-2">
		<div class="col-lg-8">Автоматическая проверка загруженных документов на соответствие формату, проверка корректности содержимого.</div>
	</div>

	<button onClick={()=>setPage(page => ({...page,step:'doc'}))} className='btn btn-ref'>Вернуться к сбору документов</button></>}

	{data.DocPackStatus == '2' && <> <div class="row mt-2">
		<div class="col-lg-8">Автоматическая проверка загруженных документов на соответствие формату, проверка корректности содержимого.</div>
	</div>

	<button onClick={()=>setPage(page => ({...page,step:'doc'}))} className='btn btn-ref'>Вернуться к сбору документов</button></>}
</div>
<hr size="2" /><b>Шаг 2 из 3. Запрос дополнительной информации</b>


{data.two && <> <div class="row mt-2 mb-3">
	<div class="col-lg-12">Для принятия окончательного решения требуется предоставление дополнительной информации. Необходимо вернуться к этапу сбора документов и подгрузить запрошенные дополнительно материалы.</div>
</div><button onClick={()=>setPage(page => ({...page,step:'doc'}))} className='btn btn-ref'>Вернуться к сбору документов</button></>}


<hr size="2" /><b>Шаг 3 из 3. Проверка завершена</b>


{data.DocPackStatus === '3' && (page.AppLeasingStatus ==='5' || page.AppLeasingStatus ==='6') && <> 
<div class="row mt-2 mb-3">
	<div class="col-lg-12">Поздравляем! Компания “СТОУН-XXI” готова сотрудничать. Для уточнения одобренных условий в ближайшее время с Вами свяжется наш сотрудник:</div>
</div>
<div class="d-flex flex-wrap mb-4"><span class="text-nowrap me-1">Ваш персональный менеджер</span>{main.PM.fio.split(' ')[0]} {main.PM.fio.split(' ')[1]} {main.PM.fio.split(' ')[2]}</div>
<div class="mt-4 mb-4"><a class="d-flex align-items-center line-height-1_2" href=""><i class="icon-download me-2"></i>Скачать финальное КП</a></div><button onClick={ok} class="btn btn-base" disabled={page.AppLeasingStatus !== '5'}>Акцептовать одобренные условия</button>
<div class="row mt-3">
	<div class="col-auto"><button onClick={()=>setPage(page => ({...page,step:'end'}))} className='btn btn-ref' disabled={page.AppLeasingStatus !=='6'}>Перейти к подписанию договора</button></div>
	<div class="col-auto"><button onClick={cancel} className='btn btn-ref' disabled={page.AppLeasingStatus ==='5'}>Отменить сделку</button></div>
</div> </>}

{data.DocPackStatus === '4' && (page.AppLeasingStatus ==='9' || page.AppLeasingStatus ==='7') && <> <div class="row mt-2 mb-3">
	<div class="col-lg-12">К сожалению, на текущий момент компания “СТОУН-XXI” не готова подтвердить сделку на запрашиваемых условиях. Для более подробного ответа и обсуждения возможных действий в ближайшее время с Вами свяжется наш сотрудник:</div>
</div>
<div class="d-flex flex-wrap mb-4"><span class="text-nowrap me-1">Ваш персональный менеджер</span>{main.PM.fio.split(' ')[0]} {main.PM.fio.split(' ')[1]} {main.PM.fio.split(' ')[2]}</div><Link class="btn btn-base" to='/AppLeasing'>Отправить новую заявку</Link>
		
</>}

{data.DocPackStatus === '3' && (page.AppLeasingStatus ==='9' || page.AppLeasingStatus ==='7') && <> <div class="row mt-2 mb-3">
	<div class="col-lg-12">К сожалению, на текущий момент компания “СТОУН-XXI” не готова подтвердить сделку на запрашиваемых условиях. Для более подробного ответа и обсуждения возможных действий в ближайшее время с Вами свяжется наш сотрудник:</div>
</div>
<div class="d-flex flex-wrap mb-4"><span class="text-nowrap me-1">Ваш персональный менеджер</span>{main.PM.fio.split(' ')[0]} {main.PM.fio.split(' ')[1]} {main.PM.fio.split(' ')[2]}</div><Link class="btn btn-base" to='/AppLeasing'>Отправить новую заявку</Link>
		
</>}

				




    </>
}

export default Ok