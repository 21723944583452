import React,{useEffect,useRef,useState} from "react";
import {api,Load,getRequest,Button,chTel} from "../../Run"
import { Link } from "react-router-dom"
import {ProgressBar,OverlayTrigger, Popover} from "react-bootstrap";
import '../../css/App.css'
import FileDownload from 'js-file-download';

import InputMask from "react-input-mask";


const End = ({page, setPage, main, reload, activeTab}) => {
    const token = localStorage.getItem('token');
    const [showErrorDownload, setShowErrorDownload] = React.useState(false);
    const [content, setContent] = React.useState(null)
    const [data, setData] = React.useState(null)
    const [input, setInput] = React.useState({})
    const [btn, setBtn] = React.useState({sopd:false})
    const modal = useRef(true)
    useEffect(() => {
        setContent(0)
    }, [activeTab])

    useEffect(()=> {
        api('request', {
			"Get": "AppLeasingDLSignPage",
			"action": "AppLeasingDLSign",
			"AppLeasingId": page.id,
            "CompanyId": main.CompanyId
		}).then((d) => {
            setData(d.data)
            if (d.data.DL[0]) {
                setInput(d.data.DL[0].PLT[0])
            }
		})
    },[])

    const [activeSubTab, setActiveSubTab] = React.useState(1)
    const [activeSubTabPl, setActiveSubTabPl] = React.useState(0)

    let pl = (d,i) => {
        console.log(d);
        setActiveSubTabPl(i)
        setInput({PLTPersonLastName:'',PLTPersonFirstName:'',PLTPersonMidName:'',PLTPersonBirthDate:'',PLTPersonTel:'',PLTPersonEmail:'',...d})
    }

    let change = (e) => {

        setInput(input => ({...input, [e.target.name]:e.target.value}))

        e.persist()
    }


    const modalYesNO = useRef(null)

    let upload = (e,dl) => {
        // setButton(button => ({...button, save:true}))

        setErrorUp(false)


        let temp = [];
        let file = e.target.files
        if (file) {


            for (let index = 0; index < file.length; index++) {
        
                
           

            let reader = new FileReader()
            reader.readAsDataURL(file[index])
            reader.onload = () => {




                temp = [...temp,{file:reader.result,name:file[index].name}]
                
        

            if ((index+1) === file.length) {

                    api('upload',{AppLeasingId:page.id,files:temp}).then((d) => {
                    // setData(data => ({...data, file:true, fileUrl:d.url}))
                    // setButton(button => ({...button, save:false}))

                    api('request',{Put:'AppLeasingDLSignPage',"action":"AppLeasingDLUpload","AppLeasingId": page.id,"DL":{"DLNumber":dl,"DLSigned":d.url}}).then((dd) => {
                        // console.log(dd);
                        if (dd.data.Error) {
                            // setError(error => ({...error, err: {title: 'Внимание!', msg: dd.data.Error}}))
                            setErrorUp(errorUp => ({...errorUp, msg: dd.data.Error,step:1}))

                        } else {
                            // setError(error => ({...error, err: {title: 'Внимание!', msg: 'Анкета отправлена на обработку менеджеру!'}}))
                            setErrorUp(errorUp => ({...errorUp, msg: dd.data.msg,step:1}))
                            setData(data => ({...data, DL:data.DL.map((e,ii)=> content === ii ? {...e,DLSigned: {file:dd.data.DL.DLSigned , status : 1}} : {...e})}))
                            // console.log(data.DL.map((e,ii)=> content === ii ? {...e,DLSigned: {file:dd.data.DL.DLSigned , status : 0}} : {...e}))
                        }
                    })


                })
            }
            }

        }

        } else {
            // setButton(button => ({...button, up:false}))
        }

    };

    let [errorUp,setErrorUp] = useState(false)


    let uploadTwo = (e,dl,i) => {
        // setButton(button => ({...button, save:true}))
        setErrorUp(false)

        let temp = [];
        let file = e.target.files
        if (file) {


            for (let index = 0; index < file.length; index++) {
        
                
           

            let reader = new FileReader()
            reader.readAsDataURL(file[index])
            reader.onload = () => {

                temp = [...temp,{file:reader.result,name:file[index].name}]
                
        

            if ((index+1) === file.length) {

                    api('upload',{AppLeasingId:page.id,files:temp}).then((d) => {
                    // setData(data => ({...data, file:true, fileUrl:d.url}))
                    // setButton(button => ({...button, save:false}))

                    api('request',{Put:'AppLeasingDLSignPage',"action":"AppLeasingDPUpload","AppLeasingId": page.id,"DL":{"DLNumber":dl,"DLDPSigned":d.url}}).then((dd) => {
                        if (dd.data.Error) {
                            // setError(error => ({...error, err: {title: 'Внимание!', msg: dd.data.Error}}))
                            setErrorUp(errorUp => ({...errorUp, msg: dd.data.Error,step:1}))

                        } else {
                            // setError(error => ({...error, err: {title: 'Внимание!', msg: 'Анкета отправлена на обработку менеджеру!'}}))
                            setErrorUp(errorUp => ({...errorUp, msg: dd.data.msg,step:1}))
                            setData(data => ({...data, DL:data.DL.map((e,ii)=> content === ii ? {...e,DLDPSigned: {file:dd.data.DL.DLDPSigned , status : 1}} : {...e})}))
                            // console.log(data.DL.map((e,ii)=> content === ii ? {...e,DLSigned: {file:dd.data.DL.DLSigned , status : 0}} : {...e}))
                        }
                    })


                })
            }
            }

        }

        } else {
            // setButton(button => ({...button, up:false}))
        }

    };


    let uploadThree = (e,dl,i) => {
        // setButton(button => ({...button, save:true}))

        setErrorUp(false)


        let temp = [];
        let file = e.target.files
        if (file) {


            for (let index = 0; index < file.length; index++) {
        
                
           

            let reader = new FileReader()
            reader.readAsDataURL(file[index])
            reader.onload = () => {

                temp = [...temp,{file:reader.result,name:file[index].name}]
                
        

            if ((index+1) === file.length) {

                    api('upload',{AppLeasingId:page.id,files:temp}).then((d) => {
                    // setData(data => ({...data, file:true, fileUrl:d.url}))
                    // setButton(button => ({...button, save:false}))

                    api('request',{Put:'AppLeasingDLSignPage',"action":"AppLeasingAdvPPUpload","AppLeasingId": page.id,"DL":{"DLNumber":dl,"DLAdvPP":d.url}}).then((dd) => {
                        console.log(dd);

                        if (dd.data.Error) {
                            // setError(error => ({...error, err: {title: 'Внимание!', msg: dd.data.Error}}))
                            setErrorUp(errorUp => ({...errorUp, msg: dd.data.Error,step:2}))

                        } else {
                            // setError(error => ({...error, err: {title: 'Внимание!', msg: 'Анкета отправлена на обработку менеджеру!'}}))
                            setErrorUp(errorUp => ({...errorUp, msg: dd.data.msg,step:2}))
                            setData(data => ({...data, DL:data.DL.map((e,ii)=> content === ii ? {...e,DLAdvPP: {file:dd.data.DL.DLAdvPP , status : 1}} : {...e})}))
                            // console.log(data.DL.map((e,ii)=> content === ii ? {...e,DLSigned: {file:dd.data.DL.DLSigned , status : 0}} : {...e}))
                        }
                    })


                })
            }
            }

        }

        } else {
            // setButton(button => ({...button, up:false}))
        }

    };


    let uploadFour = (e,dl,i) => {
        // setButton(button => ({...button, save:true}))

        setErrorUp(false)


        let temp = [];
        let file = e.target.files
        if (file) {


            for (let index = 0; index < file.length; index++) {
        
                
           

            let reader = new FileReader()
            reader.readAsDataURL(file[index])
            reader.onload = () => {

                temp = [...temp,{file:reader.result,name:file[index].name}]
                
        

            if ((index+1) === file.length) {

                    api('upload',{AppLeasingId:page.id,files:temp}).then((d) => {
                    // setData(data => ({...data, file:true, fileUrl:d.url}))
                    // setButton(button => ({...button, save:false}))

                    api('request',{Put:'AppLeasingDLSignPage',"action":"AppLeasingInsPPUpload","AppLeasingId": page.id,"DL":{"DLNumber":dl,"DLInsPP":d.url}}).then((dd) => {
                        if (dd.data.Error) {
                            setErrorUp(errorUp => ({...errorUp, msg: dd.data.Error,step:3}))
                        } else {
                            // setError(error => ({...error, err: {title: 'Внимание!', msg: 'Анкета отправлена на обработку менеджеру!'}}))
                            setErrorUp(errorUp => ({...errorUp, msg: dd.data.msg,step:3}))

                            setData(data => ({...data, DL:data.DL.map((e,ii)=> content === ii ? {...e,DLInsPP: {file:dd.data.DL.DLInsPP , status : 1}} : {...e})}))
                            // console.log(data.DL.map((e,ii)=> content === ii ? {...e,DLSigned: {file:dd.data.DL.DLSigned , status : 0}} : {...e}))
                        }
                    })


                })
            }
            }

        }

        } else {
            // setButton(button => ({...button, up:false}))
        }

    };


    let dl = (FileType,action,pl) => {
        api('request',{"Get":"AppLeasingDLSignPage","action":"DLDownload","AppLeasingId":page.id,"DLNumber":data.DL[content].DLNumber,PLTId:pl,"FileType":FileType}).then((dd) => {

            if (dd.data.Error) {
                setShowErrorDownload(FileType)
            } else {
                getRequest('/api/dlFile',{"AppLeasingId": page.id,action:action,"DLNumber":data.DL[content].DLNumber,PLTId:pl,name:dd.data.DLFileName,"FileType":FileType,token: token}).then((data) => {
                
                    FileDownload(data, dd.data.DLFileName);
                })
            }
        })

       
    }


    let dlpl = (FileType,action,pl) => {
        api('request',{"Get":"AppLeasingDLSignPage","action":"PLTDownload","AppLeasingId":page.id,"DLNumber":data.DL[content].DLNumber,PLTId:pl,"FileType":FileType}).then((dd) => {

            if (dd.data.Error) {
                setShowErrorDownload(FileType)
            } else {
                getRequest('/api/dlFile',{"AppLeasingId": page.id,action:action,"DLNumber":data.DL[content].DLNumber,PLTId:pl,name:dd.data.PLTFileName,"FileType":FileType,token: token}).then((data) => {
                
                    FileDownload(data, dd.data.PLTFileName);
                })
            }
        })

       
    }


    const [error, setError] = useState([])
    let test = () => {
        setBtn(btn=> ({...btn, sopd:true}))
        api('request',{"Put":"AppLeasingDLSignPage","action":"AppLeasingPLTPersonSet","AppLeasingId":page.id,"DLNumber":data.DL[content].DLNumber,PLTId:data.DL[content].PLT[activeSubTabPl].PLTId,...input, PLTPersonTel:'+'+chTel(input.PLTPersonTel)}).then((dd) => {

            if (dd.data.error) {
                setError(dd.data.error.errors)
            } else {
                setData(data => ({...data, DL: 
                    data.DL.map(d=> d.DLNumber === data.DL[content].DLNumber ? 
                        {...d, 
                            PLT: d.PLT.map(p=> p.PLTId === data.DL[content].PLT[activeSubTabPl].PLTId ? {
                                ...p,PLTPersonSOPDFile:dd.data.PLTPersonSOPDFile ? dd.data.PLTPersonSOPDFile : '',PLTPersonSOPDLink:dd.data.PLTPersonSOPDLink} 
                    : p)} : d)}))
            }
            setBtn(btn=> ({...btn, sopd:false}))
        })
        // console.log(error.find(d => d.name === "PLTPersonLastName").name)
       
    }


    const [confirmError, setConfirmError] = useState(null)
    let confirm = () => {
        setBtn(btn=> ({...btn, confirm:true}))
        api('request',{"Put":"AppLeasingDLSignPage","action":"PLConfirmation","AppLeasingId":page.id,"DLNumber":data.DL[content].DLNumber,PLTId:data.DL[content].PLT[activeSubTabPl].PLTId}).then((dd) => {

            if (dd.data.Error) {
                setConfirmError(dd.data.Error)
            } else {
                setData(data => ({...data, DL: 
                    data.DL.map(d=> d.DLNumber === data.DL[content].DLNumber ? 
                        {...d, 
                            PLT: d.PLT.map(p=> p.PLTId === data.DL[content].PLT[activeSubTabPl].PLTId ? {
                                ...p,PLTConfirmDate:dd.data.PLTConfirmDate  ? dd.data.PLTConfirmDate  : ''} 
                    : p)} : d)}))
            }
            setBtn(btn=> ({...btn, confirm:false}))
        })
    }

    const onToggle = () => {
        setShowErrorDownload(false)
    };


    // let dl = () => {
    //     api('request',{"Get":"AppLeasingDLSignPage","action":"DLDownload","AppLeasingId":page.id,"DLNumber":data.DL[content].DLNumber,"FileType":"DLSigned"}).then((dd) => {
            
    //         getRequest('/api/dlFile',{"AppLeasingId": page.id,"DLNumber":data.DL[content].DLNumber,name:dd.data.DLFileName,"FileType":"DLSigned",token: token}).then((dd) => {
                
    //             console.log(dd);
    //         })
    //     })

       
    // }

    const popoverDownloadError = (
        <Popover id="downloadError">
            <div className="tooltip tooltipError">
                Ошибка скачивания файла. Нужна другая спецификация
            </div>
        </Popover>
    );

    return data ? <><div class="row">
        {data.DL && data.DL.map((d,i) => <div class="col-md-4 mb-3">
            <button onClick={()=> {
                setError([])
                setConfirmError(null)
                setContent(i)
                setInput({PLTPersonLastName:'',PLTPersonFirstName:'',PLTPersonMidName:'',PLTPersonBirthDate:'',PLTPersonTel:'',PLTPersonEmail:'',...data.DL[i].PLT[0]})
                }} style={{borderRadius:'10px'}} className={"page-nav__link" + (content === i ? " active" : "")}>Договор № {d.DLNumber}</button>
            {/* <ProgressBar style={{borderRadius:' 0px 0px 10px 10px'}}>
                <ProgressBar variant="success" now={50} key={1} title="Выполнен" />
                <ProgressBar variant="warning" now={50} key={2} title="Не начат" />
             
            </ProgressBar> */}
        </div>)}
    </div>
    

            <div className="mt-5">
                <div className="row">
                    <div className="col-lg-6 mb-3">
                        <div className="grid-table grid-table_col-2">
                            <div className="grid-table__item mb-2">№ договора:</div>
                            <div className="grid-table__item mb-2">{data.DL[content].DLNumber}</div>
                            <div className="grid-table__item mb-2">Дата заключения:</div>
                            <div className="grid-table__item mb-2">{data.DL[content].DLDate}</div>
                            <div className="grid-table__item mb-2">Сумма договора:</div>
                            <div className="grid-table__item mb-2">{data.DL[content].DLAmount}</div>
                        </div>
                    </div>
                    <div className="col-lg-6 mb-3">
                        <div className="grid-table grid-table_col-2">
                            <div className="grid-table__item mb-2">Сумма аванса:</div>
                            <div className="grid-table__item mb-2">{data.DL[content].DLAdvAmount}</div>
                            <div className="grid-table__item mb-2">Очередной ежемесячный платеж:</div>
                            <div className="grid-table__item mb-2">{data.DL[content].DLNextPayment}</div>
                            <div className="grid-table__item mb-2">Дата акта приемки-передачи ПЛ:</div>
                            <div className="grid-table__item mb-2">—</div>
                        </div>
                    </div>
                </div>
            </div>
            <hr size="2"/>
            <b>Шаг 1 из 4 Подписание документов</b>


            {errorUp && errorUp.step === 1 && <div className='login-alert p-3 text-center mt-3'><div className='text-primary'>Внимание!</div><span>{errorUp.msg}</span></div> }


            <div className="row mt-3">
                <div className="col-lg-6 note text-secondary">Раздел содержит полный пакет документов по сделке, включая
                    платежные документы для оплаты, договоры и закрывающие документы. Переход к этапу получения предмета
                    лизинга осуществляется после загрузки подписанных лизингополучателем документов и оплаты
                    обязательных
                    платежей.
                </div>
                <div className="col-lg-6 note text-secondary">При загрузке документов выполняется автоматическая
                    проверка на
                    соответствие формату и корректности содержимого. В случае, если документ не прошел автоматическую
                    проверку, его необходимо подгрузить повторно, выполнив требования в описании к документу, для
                    перехода к
                    следующим шагам подписания сделки.
                </div>
            </div>
            <div className="table-responsive">
                <table className="table mt-4">
                    <tbody>
                    <tr>
                        <td className="w-50 text-nowrap">
                            <button className="btn btn-ref d-flex align-items-center line-height-1_2" onClick={()=>dl("DLFile","DLContent")} disabled={!Number(data.DL[content].DLFile.status) > 0}>
                                <i className="icon-download me-2"/>Договор лизинга
                            </button>
                        </td>
                        <td>
                            {/* <a className="text-nowrap d-block" href="">Загрузить договор с ЭЦП</a> */}
                            <button onClick={() => document.getElementById('my_file').click()} className="btn btn-ref">Загрузить договор с обычной подписью</button>
                            <input

                            type="file"
                            id="my_file"
                            onChange={(e)=>upload(e,data.DL[content].DLNumber)}  multiple/>
                            </td>
                        <td>
                            {data.DL[content].DLSigned.file ? 
                            <>
                            {data.DL[content].DLSigned.status == 0 && <div className="text-primary text-nowrap">Не загружен</div> }
                            {data.DL[content].DLSigned.status == 1 && <div className="text-success text-nowrap">Загружен</div>} 
                            {data.DL[content].DLSigned.status == 2 && <div className="text-warning text-nowrap">Проверен</div>}
                            {data.DL[content].DLSigned.status == 3 && <div className="text-danger text-nowrap">Отказ</div>}
                            </>
                            : <div className="text-warning text-nowrap">Не загружен</div>
                            }
                            {data.DL[content].DLSigned.status > 0 && <button className="btn btn-ref d-flex align-items-center line-height-1_2" onClick={()=>dl("DLSigned","DLContent")} disabled={!Number(data.DL[content].DLSigned.status) > 0}>
                                <i className="icon-download me-2"/>Скачать
                            </button>}
                        </td>
                    </tr>
                    {data.DL[content].DLDPNeeded === '1'  && <tr>
                        <td className="w-50 text-nowrap">
                            <button className="btn btn-ref d-flex align-items-center line-height-1_2" onClick={()=>dl("DLDPFile","DLContent")} disabled={!Number(data.DL[content].DLDPFile.status) > 0}>
                                <i className="icon-download me-2"/>Договор поручительства
                            </button>
                        </td>
                        <td>
                            {/* <a className="text-nowrap d-block" href="">Загрузить договор с ЭЦП</a> */}
                            {/* <a className="text-nowrap d-block" href="">Загрузить договор с обычной подписью</a> */}

                            <button onClick={() => document.getElementById('my_file1').click()} className="btn btn-ref">Загрузить договор с обычной подписью</button>

                            <input
                            style={{display:"none"}}
                            type="file"
                            id="my_file1"
                            onChange={(e)=>uploadTwo(e,data.DL[content].DLNumber)}  multiple/>


                        </td>
                        <td>
                        {data.DL[content].DLDPSigned.file ? 
                            <>
                            {data.DL[content].DLDPSigned.status == 0 && <div className="text-primary text-nowrap">Не загружен</div> }
                            {data.DL[content].DLDPSigned.status == 1 && <div className="text-success text-nowrap">Загружен</div>} 
                            {data.DL[content].DLDPSigned.status == 2 && <div className="text-warning text-nowrap">Проверен</div>}
                            {data.DL[content].DLDPSigned.status == 3 && <div className="text-danger text-nowrap">Отказ</div>}
                            </>
                            : <div className="text-warning text-nowrap">Не загружен</div>
                            }
                            {data.DL[content].DLDPSigned.status > 0 && <button className="btn btn-ref d-flex align-items-center line-height-1_2" onClick={()=>dl("DLDPSigned","DLContent")} disabled={!Number(data.DL[content].DLDPSigned.status) > 0}>
                                <i className="icon-download me-2"/>Скачать
                            </button>}
                        </td>
                    </tr>}
                    <tr>
                        <td className="w-50 text-nowrap" colspan="3">
                            <button className="btn btn-ref d-flex align-items-center line-height-1_2" onClick={()=>dl("DLInsQuotation","DLContent")} disabled={!Number(data.DL[content].DLInsQuotation.status) > 0}>
                                <i className="icon-download me-2"/>Котировка страхования ПЛ
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            {/* <div className="mt-3 text-secondary">
                <div className="row">
                    <div className="col-lg-8">Выберите страховую компанию для формирования счета на страховку. Счет
                        появится
                        ниже в списке документов.
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-8 col-lg-6">
                    <div className="form-group form-floating">
                        <select className="form-control form-select">
                            <option>Альфа-Страхование</option>
                            <option>Альфа-Страхование 1</option>
                        </select>
                        <label>Название страховой компании</label>
                    </div>
                </div>
            </div> */}
            {/* <a className="d-flex align-items-center line-height-1_2 mt-3" href="">
                <i className="icon-download me-2"/>Счет на страховку
            </a> */}
            <button className="btn btn-ref d-flex align-items-center line-height-1_2 mt-3" onClick={()=>dl("DLInsInvoice","DLContent")} disabled={!Number(data.DL[content].DLInsInvoice.status) > 0}>
                <i className="icon-download me-2"/>Счет на страховку
            </button>
            {/* <a className="d-flex align-items-center line-height-1_2 mt-3" href="">
                <i className="icon-download me-2"/>Счет на аванс
            </a> */}
            <button className="btn btn-ref d-flex align-items-center line-height-1_2 mt-3" onClick={()=>dl("DLAdvInvoice","DLContent")} disabled={!Number(data.DL[content].DLAdvInvoice.status) > 0}>
                <i className="icon-download me-2"/>Счет на аванс
            </button>
            <hr size="2"/>
            <b>Шаг 2 из 4 Оплата аванса</b>

            {errorUp && errorUp.step === 2 && <div className='login-alert p-3 text-center mt-3'><div className='text-primary'>Внимание!</div><span>{errorUp.msg}</span></div> }

            <div className="row my-4 note">
                <div className="col-lg-6 text-secondary">Ожидается оплата авансового платежа. Для ускорения перехода на
                    шаг
                    передачи предмета лизинга подгрузите платежное банковское поручение с отметкой “ПРОВЕДЕНО”.
                    Допустимый
                    формат файла – pdf, jpg, jpeg, png, gif.
                    <button className="btn btn-ref d-flex align-items-center line-height-1_2 mt-3" onClick={()=>dl("DLAdvInvoice","DLContent")} disabled={!Number(data.DL[content].DLAdvInvoice.status) > 0}>
                                <i className="icon-download me-2"/>Оплата аванса
                            </button>
                </div>
                <div className="col-lg-6 mt-3 mt-lg-0">
                    <div className="row justify-content-lg-around">
                        <div className="col-auto">
                            {/* <a className="text-nowrap d-block" href="">Загрузить платежное поручение</a> */}
                            <button onClick={() => document.getElementById('my_file2').click()} className="btn btn-ref">Загрузить платежное поручение</button>
                            <input
                            style={{display:"none"}}
                            type="file"
                            id="my_file2"
                            onChange={(e)=>uploadThree(e,data.DL[content].DLNumber)}  multiple/>
                        </div>
                        <div className="col-auto">
                            {/* <div className="text-success text-nowrap">Принято</div> */}
                            {/* {data.DL[content].DLAdvPP ? <div className="text-primary text-nowrap">Загружен</div> : <div className="text-warning text-nowrap">Не загружен</div>} */}

                            {data.DL[content].DLAdvPP.file ? 
                            <>
                            {data.DL[content].DLAdvPP.status == 0 && <div className="text-primary text-nowrap">Не загружен</div> }
                            {data.DL[content].DLAdvPP.status == 1 && <div className="text-success text-nowrap">Загружен</div>} 
                            {data.DL[content].DLAdvPP.status == 2 && <div className="text-warning text-nowrap">Проверен</div>}
                            {data.DL[content].DLAdvPP.status == 3 && <div className="text-danger text-nowrap">Отказ</div>}
                            </>
                            : <div className="text-warning text-nowrap">Не загружен</div>
                            }
                            
                            {data.DL[content].DLAdvPP.status > 0 && <button className="btn btn-ref d-flex align-items-center line-height-1_2" onClick={()=>dl("DLAdvPP","DLContent")} disabled={!Number(data.DL[content].DLAdvPP.status) > 0}>
                                <i className="icon-download me-2"/>Скачать
                            </button>}
                        
                        </div>
                    </div>
                </div>
            </div>
            <hr size="2"/>
            <b>Шаг 3 из 4 Оплата страховки</b>

            {errorUp && errorUp.step === 3 && <div className='login-alert p-3 text-center mt-3'><div className='text-primary'>Внимание!</div><span>{errorUp.msg}</span></div> }


            <div className="row my-4 note">
                <div className="col-lg-6 text-secondary">Ожидается оплата авансового платежа. Для ускорения перехода на
                    шаг
                    передачи предмета лизинга подгрузите платежное банковское поручение с отметкой “ПРОВЕДЕНО”.
                    Допустимый
                    формат файла – pdf, jpg, jpeg, png, gif.
                    <button className="btn btn-ref d-flex align-items-center line-height-1_2 mt-3" onClick={()=>dl("DLInsInvoice","DLContent")} disabled={!Number(data.DL[content].DLInsInvoice.status) > 0}>
                                <i className="icon-download me-2"/>Оплата страховки
                            </button>
                </div>
                <div className="col-lg-6 mt-3 mt-lg-0">
                    <div className="row justify-content-lg-around">
                        <div className="col-auto">
                            {/* <a className="text-nowrap d-block" href="">Загрузить платежное поручение</a> */}

                            <button onClick={() => document.getElementById('my_file3').click()} className="btn btn-ref">Загрузить платежное поручение</button>
                            <input
                            style={{display:"none"}}
                            type="file"
                            id="my_file3"
                            onChange={(e)=>uploadFour(e,data.DL[content].DLNumber)}  multiple/>

                        </div>
                        <div className="col-auto">
                            {/* <div className="text-success text-nowrap">Принято</div> */}
                            {/* {data.DL[content].DLInsPP ? <div className="text-primary text-nowrap">Загружен</div> : <div className="text-warning text-nowrap">Не загружен</div>} */}

                            {data.DL[content].DLInsPP.file ? 
                            <>
                            {data.DL[content].DLInsPP.status == 0 && <div className="text-primary text-nowrap">Не загружен</div> }
                            {data.DL[content].DLInsPP.status == 1 && <div className="text-success text-nowrap">Загружен</div>} 
                            {data.DL[content].DLInsPP.status == 2 && <div className="text-warning text-nowrap">Проверен</div>}
                            {data.DL[content].DLInsPP.status == 3 && <div className="text-danger text-nowrap">Отказ</div>}
                            </>
                            : <div className="text-warning text-nowrap">Не загружен</div>
                            }
                        
                        {data.DL[content].DLInsPP.status > 0 && <button className="btn btn-ref d-flex align-items-center line-height-1_2" onClick={()=>dl("DLInsPP","DLContent")} disabled={!Number(data.DL[content].DLInsPP.status) > 0}>
                                <i className="icon-download me-2"/>Скачать
                            </button>}
                        
                        </div>
                    </div>
                </div>
            </div>

            <hr size="2"/>


            <div className="mb-3"><b>Шаг 4 из 4 Получение предметов лизинга</b></div>
            
                
                {data.DL[content].PLT &&<><div class="row"> {data.DL[content].PLT.map((dp,i) => <div className="col-md-4 mb-3">
                    <button onClick={() => pl(dp,i)} className={"page-nav__link" + (activeSubTabPl === i ? " active" : "")} style={{borderRadius:'10px'}}>
                        <span>Передача ПЛ №{i+1} <br/>
                        Дата: {dp.PLTDate} <br/>
                        {dp.PLTList}</span>
                    </button>
                    {/* <ProgressBar style={{borderRadius:' 0px 0px 10px 10px'}}>
                        <ProgressBar variant="warning" now={50} key={1} title="Не начат" />
                        <ProgressBar variant="success" now={50} key={2} title="Выполнен" />
                    </ProgressBar> */}
                </div>)} </div>


                <b>4.1 Назначение ответственного лица для получения предметов лизинга</b>
            <div className="row mt-3 mb-3">
                <div className="col-md-8 col-lg-6">
                    {/* <div className="form-group mb-3 form-floating"> */}
                    <div className={`form-group mb-3 form-floating ${error.filter(d => d.name === "PLTPersonLastName")[0] && 'has-error'}`}>
                        <input className="form-control" name='PLTPersonLastName' value={input.PLTPersonLastName} onChange={change} placeholder="Placeholder"/>
                        <label>Фамилия ответственного лица</label>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className={`form-group mb-3 form-floating ${error.filter(d => d.name === "PLTPersonFirstName")[0] && 'has-error'}`}>
                                <input className="form-control" name='PLTPersonFirstName' value={input.PLTPersonFirstName} onChange={change} placeholder="Placeholder"/>
                                <label>Имя ответственного лица</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className={`form-group mb-3 form-floating ${error.filter(d => d.name === "PLTPersonMidName")[0] && 'has-error'}`}>
                                <input className="form-control" name='PLTPersonMidName' value={input.PLTPersonMidName} onChange={change} placeholder="Placeholder"/>
                                <label>Отчество ответственного лица</label>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mb-3">
                        <div className={`form-group mb-3 form-floating ${error.filter(d => d.name === "PLTPersonBirthDate")[0] && 'has-error'}`}>
                            <input className="form-control" name='PLTPersonBirthDate' value={input.PLTPersonBirthDate} onChange={change} autocomplete="off" type="date" placeholder="Placeholder"/>
                            <label>Дата рождения</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <div className={`form-group mb-3 form-floating ${error.filter(d => d.name === "PLTPersonTel")[0] && 'has-error'}`}>
                            <InputMask className="form-control"  mask="+7 (999)999-99-99" name='PLTPersonTel' value={input.PLTPersonTel} onChange={change} placeholder="Placeholder" />

                                {/* <input className="form-control" name='PLTPersonTel' value={input.PLTPersonTel} onChange={change} placeholder="Placeholder"/> */}
                                <label>Телефон</label>
                            </div>
                        </div>
                        <div className="col-md-6">
                            {/* <div className="form-group mb-3 form-floating"> */}
                            <div className={`form-group mb-3 form-floating ${error.filter(d => d.name === "PLTPersonEmail")[0] && 'has-error'}`}>
                                <input className="form-control" name='PLTPersonEmail' value={input.PLTPersonEmail} onChange={change} placeholder="Placeholder"/>
                                <label>E-mail</label>
                            </div>
                        </div>
                    </div>
                    {data.DL[content].PLT[activeSubTabPl].PLTPersonSOPDFile.file ? <div className='login-alert p-3 text-center mb-3'><div className='text-primary'>Внимание!</div><span>Согласие на обработку персональных данных есть</span></div> : data.DL[content].PLT[activeSubTabPl].PLTPersonSOPDLink && <a className="note" target="_blank" href={data.DL[content].PLT[activeSubTabPl].PLTPersonSOPDLink}>Форма Согласия для ответственного лица (доступна без регистрации)</a>}
                    <div className="mt-3">
                        <button className="btn btn-base" onClick={test} disabled={btn.sopd}><Button title='Назначить ответственным лицом' color='btn-black-base' param={btn.sopd} /></button>
                    </div>
                </div>
                <div className="col-md-8 col-lg-6 note text-secondary">Для получения предмета лизинга необходимо указать
                    контактные данные ответственного лица, а также заполнить и подписать форму Согласия на обработку
                    персональных данных по ссылке ниже. В случае, если Согласие было получено ранее, переход к
                    следующему
                    шагу произойдет автоматически.
                </div>
            </div>
            {/*<hr size="2"/>*/}
            <b>4.2 Получение предметов лизинга</b>
            <div className="row mt-3">
                <div className="col-lg-8">
                    <div className="mb-2">
                        <b className="note">Список документов, необходимых для получения предмета лизинга:</b>
                    </div>
                    <ol>
                        <li className="mb-1 note">Паспорт</li>
                        <li className="mb-1 note">Печать компании (кроме случаев получения материальных ценностей по
                            разовой
                            доверенности)
                        </li>
                        <li className="mb-1 note">Оригинал действующей доверенности на имя сотрудника в случае, если
                            получатель не является лицом, указанным в Уставе компании
                        </li>
                        <li className="mb-1 note">Оригинал договора лизинга в случае, если получатель не является лицом,
                            указанным в Уставе компании
                        </li>
                        <li className="mb-1 note">Подписанный оригинал анкеты лизингополучателя</li>
                        <li className="mb-1 note">Подписанный оригинал согласия на обработку персональных данных.</li>
                    </ol>
                    <div className="note">Дата и место передачи появятся ниже.</div>
                </div>
            </div>
            <div className="grid-table grid-table_col-2 mt-3 text-secondary">
                <div className="grid-table__item">Дата передачи ПЛ:</div>
                <div className="grid-table__item">{data.DL[content].PLT[activeSubTabPl].PLTDate}</div>
                <div className="grid-table__item">Место передачи ПЛ:</div>
                <div className="grid-table__item">{data.DL[content].PLT[activeSubTabPl].PLTAddress}</div>
            </div>
            {/* <a className="d-flex align-items-center line-height-1_2 mt-3" href="">
                <i className="icon-download me-2"/>Доверенность на право подписания актов к Договору лизинга и получение
                предмета лизинга по форме компании
            </a> */}
            <button className="btn btn-ref d-flex align-items-center line-height-1_2 mt-3" onClick={()=>dlpl("PLTDovFile","PLTContent",data.DL[content].PLT[activeSubTabPl].PLTId)} disabled={data.DL[content].PLT[activeSubTabPl].PLTDovFile.status != 1} >
                <i className="icon-download me-2"/>Доверенность на право подписания актов к Договору лизинга и получение
                предмета лизинга по форме компании
            </button>
            {/* <a className="d-flex align-items-center line-height-1_2 mt-3" href="">
                <i className="icon-download me-2"/>Акт приемки-передачи к Договору лизинга
            </a> */}
            <button className="btn btn-ref d-flex align-items-center line-height-1_2 mt-3" onClick={()=>dlpl("PLTActPPFile","PLTContent",data.DL[content].PLT[activeSubTabPl].PLTId)} disabled={data.DL[content].PLT[activeSubTabPl].PLTActPPFile.status != 1}>
                <i className="icon-download me-2"/>Акт приемки-передачи к Договору лизинга
            </button>
            {/* <a className="d-flex align-items-center line-height-1_2 mt-3" href="">
                <i className="icon-download me-2"/>Акт ввода в эксплуатацию к Договору лизинга
            </a> */}
            <button className="btn btn-ref d-flex align-items-center line-height-1_2 mt-3" onClick={()=>dlpl("PLTActVVFile","PLTContent",data.DL[content].PLT[activeSubTabPl].PLTId)} disabled={data.DL[content].PLT[activeSubTabPl].PLTActVVFile.status != 1}>
                <i className="icon-download me-2"/>Акт ввода в эксплуатацию к Договору лизинга
            </button>
            <div>

            {/* {confirmError ? <div className='login-alert p-3 text-center mе-5'><div className='text-primary'>Внимание!</div><span>{confirmError}</span></div> : data.DL[content].PLT[activeSubTabPl].PLTPersonSOPDLink && <a className="note" target="_blank" href={data.DL[content].PLT[activeSubTabPl].PLTPersonSOPDLink}>Форма Согласия для ответственного лица (доступна без регистрации)</a>} */}
            </div>
            <div className="mt-3">
                <button className="btn btn-base" onClick={confirm} disabled={data.DL[content].PLT[activeSubTabPl].PLTConfirmDate}>Подтвердить получение ПЛ</button> 
                {data.DL[content].PLT[activeSubTabPl].PLTConfirmDate && <div className='login-alert p-3 text-center my-3'>Получение ПЛ подтверждено {data.DL[content].PLT[activeSubTabPl].PLTConfirmDate}</div>}
            </div>
            <div className="row mt-3">
                <div className="col-auto"><button className="btn btn-ref" disabled>Перейти в карточку договора</button></div>
                {/* <div className="col-auto"><button className="btn btn-ref" disabled>Отменить сделку</button></div> */}
            </div>
                </>}








                {!modal.current && <div className="modal-open"><div class="modal fade show" id="modalManager" tabindex="-1" aria-labelledby="modalManager" aria-modal="true" role="dialog" style={{display: 'block'}}>
    <div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>

					<div class="modal-body">
                    Вы уверены что хотите перезаписать файл?


                    <div class="row mt-3">
							<div class="col-auto mb-3">
								<button class="btn btn-base" onClick={()=>modal.current = false}>Да</button>
							</div>
							<div class="col-auto mb-3">
								<button class="btn btn-ghost" onClick={()=>modal.current = false}>Нет</button>
							</div>
						</div>
					</div>


				</div>
			</div>
		</div></div>}

           
           
       </> : <Load />
}

export default End
