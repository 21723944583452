
import { useEffect } from 'react'
import {api,Button} from '../../Run'

function Error({change,input,button,setButton,error,setError,setData,reload,data,pass}) {
    useEffect(()=> {
        setError(error => ({...error, errorMsg:''}))
    },[])

    let errorMsg = (e) => {
        setError(error => ({...error, errorMsg:''}))
        api('request',{"Put":"AuthForm","action":"AuthProblemRequest",login:input.email,AuthProblem:input.AuthProblem}).then((d) => {
            if (d.data.Error) {
                setError(error => ({...error, errorMsg:d.data.Error}))
            } else {
                setError(error => ({...error, errorMsg:d.data.msg}))
            }
        })
        e.preventDefault()
    }


    return <>
        <div class="login-inner__nav mb-5">
        
        <div className={"login-inner__nav-item active"} >Сообщить о проблемах со входом</div>
   
    </div>
    
                    
        

                    {error.errorMsg && <div className='login-alert p-3 text-center mb-3'><div className='text-primary'>Внимание!</div><span>{error.errorMsg}</span></div>}
                    {/* <div className="col-12">{error.reg && <div className='login-alert p-3 text-center mb-3'><div className='text-primary'>Внимание!</div><span>{error.reg}</span></div>}</div> */}

                        
               
                        <div className="form-group mb-3 form-floating">
                                <input type="email" name="email" onChange={change} class="form-control" required="required" required value={input.email} />
                                <label for="E-mail">E-mail</label>
                        </div>


                        <div className="form-group mb-3 form-floating">
                                <textarea name="AuthProblem" onChange={change} class="form-control" required="required" style={{height:'100px'}} required rows="2" >{input.AuthProblem}</textarea>

                                <label>Сообщение об ошибки</label>
                        </div>

       
                    <button class="btn btn-base btn-large" onClick={ errorMsg  }>Отправить</button>
          


                    <div className="pb-5 mt-5"><button onClick={()=>setData(data => ({...data, errorMsg: !data.errorMsg}))}  type='button' className=' btn btn-ref'><span className='icon icon-arrow-back'> Вернуться</span></button></div>

                   </>
}

export default Error