import React from 'react'
import down from '../../../img/triangledown_83849.png'
import up from '../../../img/triangle_icon_137074.png'
import random from '../../../img/scrollverticalarrows_83824.png'
import moment from "moment";
import {Link} from "react-router-dom";

const SearchTable = ({dataTable}) => {

    const statuses = {
        1: 'Новая',
        2: 'Согласование',
        3: 'Заполнение анкеты',
        4: 'Сбор документов',
        5: 'Одобрение сделки',
        6: 'Подписание ДЛ',
        7: 'Сделка отменена клиентом',
        8: 'ДЛ подписан(ы)',
        9: 'Отказано в одобрении лизингодателем'
    }

    const [data, setData] = React.useState(null)
    const [sorts, setSorts] = React.useState({
        sort_No: 'random',
        sort_Created: 'random',
        sort_CalcAmount: 'random',
        sort_CalcAdv: 'random',
        sort_CalcSL: 'random',
        sort_Status: 'random',
    })
    React.useEffect( () => {
        setData(dataTable)
    }, [dataTable])

    const computeDirection = (direction) => {
        if (direction === 'up')	{
            return 'down'
        } else if (direction === 'down') {
            return 'random'
        } else {
            return 'up'
        }
    }

    const sortingByNum = (key) => {
        const direction = computeDirection(sorts[`sort_${key}`]);
        let newData = data.sort((a, b) => {
            if (direction === 'up') {
                return +a[key] - +b[key]
            } else if (direction === 'down') {
                return +b[key] - +a[key]
            }
            return Math.random() - 0.5
        });
        setData(newData);
        setSorts({
            sort_No: 'random',
            sort_Created: 'random',
            sort_CalcAmount: 'random',
            sort_CalcAdv: 'random',
            sort_CalcSL: 'random',
            sort_Status: 'random',
            [`sort_${key}`]: direction
        })
    };

    const sortByString = (key) => {
        const direction = computeDirection(sorts[`sort_${key}`]);
        const newData = data.sort( (a, b) => {
            if (direction === 'up') {
                return b[key] < a[key] ? 1 : -1
            } else if (direction === 'down') {
                return a[key] < b[key] ? 1 : -1
            } else {
                return Math.random() - 0.5
            }
        })
        setData(newData);
        setSorts({
            sort_No: 'random',
            sort_Created: 'random',
            sort_CalcAmount: 'random',
            sort_CalcAdv: 'random',
            sort_CalcSL: 'random',
            sort_Status: 'random',
            [`sort_${key}`]: direction
        })
    }

    const sortByDate = () => {
        let direction = computeDirection(sorts.sort_Created);
        const newData = data.sort( (a, b) => {
            const aDate = moment(a.Created, 'YYYY-MM-DD HH:mm:ss')
            const bDate = moment(b.Created, 'YYYY-MM-DD HH:mm:ss')

            if (direction === 'down') {
                return bDate - aDate
            } else if (direction === 'up') {
                return aDate - bDate
            }
            return Math.random() - 0.5
        })
        setData(newData);
        setSorts({
            sort_No: 'random',
            sort_CalcAmount: 'random',
            sort_CalcAdv: 'random',
            sort_CalcSL: 'random',
            sort_Status: 'random',
            sort_Created: direction
        })
    }

    const formatAmount = (n, curr, LanguageFormat = undefined) =>
        Intl.NumberFormat(LanguageFormat, {style: 'currency', currency: curr}).format(n);

    const formatDate = (date) => {
        return new Date(date).toLocaleString('ru', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
    }
    console.log('data', data)

    return (
        <div className="table-responsive">
            <table className="table text-nowrap">
                <thead>
                <tr>
                    <th>
                        <div onClick={()=> sortByString('No')}>
                            Номер договора
                            {sorts.sort_No === 'up'
                                ? <img src={up} alt='up' style={{width: '8px', marginLeft: '5px'}} />
                                : sorts.sort_No === 'down'
                                    ? <img src={down} alt='down' style={{width: '8px', marginLeft: '5px'}} />
                                    : <img src={random} alt='random' style={{width: '10px', marginLeft: '5px'}} />
                            }
                        </div>
                    </th>
                    <th>
                        <div onClick={() => sortByDate()}>
                            Дата обращения
                            {sorts.sort_Created === 'up'
                                ? <img src={up} alt='up' style={{width: '8px', marginLeft: '5px'}} />
                                : sorts.sort_Created === 'down'
                                    ? <img src={down} alt='down' style={{width: '8px', marginLeft: '5px'}} />
                                    : <img src={random} alt='random' style={{width: '10px', marginLeft: '5px'}} />
                            }
                        </div>
                    </th>
                    <th>
                        <div onClick={() => sortingByNum('CalcAmount')}>
                            Сумма заявки
                            {sorts.sort_CalcAmount === 'up'
                                ? <img src={up} alt='up' style={{width: '8px', marginLeft: '5px'}} />
                                : sorts.sort_CalcAmount === 'down'
                                    ? <img src={down} alt='down' style={{width: '8px', marginLeft: '5px'}} />
                                    : <img src={random} alt='random' style={{width: '10px', marginLeft: '5px'}} />
                            }
                        </div>
                    </th>
                    <th>
                        <div onClick={() => sortingByNum('CalcAdv')}>
                            Аванс
                            {sorts.sort_CalcAdv === 'up'
                                ? <img src={up} alt='up' style={{width: '8px', marginLeft: '5px'}} />
                                : sorts.sort_CalcAdv === 'down'
                                    ? <img src={down} alt='down' style={{width: '8px', marginLeft: '5px'}} />
                                    : <img src={random} alt='random' style={{width: '10px', marginLeft: '5px'}} />
                            }
                        </div>
                    </th>
                    <th>
                        <div onClick={() => sortingByNum('CalcSL')}>
                            Срок лизинга
                            {sorts.sort_CalcSL === 'up'
                                ? <img src={up} alt='up' style={{width: '8px', marginLeft: '5px'}} />
                                : sorts.sort_CalcSL === 'down'
                                    ? <img src={down} alt='down' style={{width: '8px', marginLeft: '5px'}} />
                                    : <img src={random} alt='random' style={{width: '10px', marginLeft: '5px'}} />
                            }
                        </div>
                    </th>
                    <th>
                        <div onClick={() => sortingByNum('Status')}>
                            Статус
                            {sorts.sort_Status === 'up'
                                ? <img src={up} alt='up' style={{width: '8px', marginLeft: '5px'}} />
                                : sorts.sort_Status === 'down'
                                    ? <img src={down} alt='down' style={{width: '8px', marginLeft: '5px'}} />
                                    : <img src={random} alt='random' style={{width: '10px', marginLeft: '5px'}} />
                            }
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {data && data.map(d => <tr>
                     <td><Link to={`/AppLeasing/${d.Id}`}>{d.No}</Link></td>
                     <td>{formatDate(d.Created)}</td>
                     <td>{formatAmount(d.CalcAmount, 'RUB', 'Ru-ru')}</td>
                     <td>{d.CalcAdv}<span>%</span></td>
                     <td>{d.CalcSL}<span> мес.</span></td>
                     <td>{statuses[d.Status]}</td>
                </tr>)}
                </tbody>
            </table>
        </div>
    )
}

export default SearchTable
