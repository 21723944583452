import React, {useState,useEffect} from 'react'
import {api,Button} from '../Run'
import {Link} from 'react-router-dom'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faVk,faFacebookSquare} from '@fortawesome/free-brands-svg-icons'

import Logo from '../images/logo-login.svg'
import Lashes from '../svg/Lashes.svg'
import Eye from '../svg/Eye.svg'

import ddd from '../images/login.jpeg'


import Enter from './Login/Enter'
import Reg from './Login/Reg'

import Error from './Login/Error'

function Login({main,reload,ad}) {

    let [data,setData] = useState({slider:0, pass:true, repass:true, errorMsg:true})
    let [error, setError] = useState({login:false,reg:''})
    let [input,setInputs] = useState({email:'',password:''})
    let [button, setButton] = useState({login:false,reg:false})
    let [btn,setBtn] = useState(true)


    let slide = ad.ADV_AUTH ? ad.ADV_AUTH : []

    let change = (e) => {
        setInputs(input => ({...input, [e.target.name]: e.target.value}))
        console.log(input);
        e.persist()
    }

    // let int;
    useEffect(()=> {

        sl(0)
        
    },[])

    let sl = (ii) => {
        clearInterval(data.int)
        setData(data => ({...data, slider: ii,int:setInterval(()=>{setData(data => ({...data, slider: data.slider === 2 ? 0 : data.slider+1}))}, 7000)}))
    }


    let reg = (e) => {
        setButton(button => ({...button, reg:true}))
        api('request',{...input,"Put":"RegForm","action":"RegFormEdit"}).then((d) => {
            if (d.data.Error) {
                setError(error => ({...error, reg:d.data.Error,...d.data[0]}))
            } else {
                setError(error => ({...error, reg:d.data.msg}))
            }
            setButton(button => ({...button, reg:false}))
        })
        e.preventDefault()
    }

    let errorMsg = (e) => {
        setError(error => ({...error, errorMsg:''}))
        api('request',{"Put":"AuthForm","action":"AuthProblemRequest",login:input.email,AuthProblem:input.AuthProblem}).then((d) => {
            if (d.data.Error) {
                setError(error => ({...error, errorMsg:d.data.Error}))
            } else {
                setError(error => ({...error, errorMsg:d.data.msg}))
            }
        })
        e.preventDefault()
    }

    let pass = () => {
        data.pass ? setData(data => ({...data, pass:false})): setData(data => ({...data, pass:true}));
    }

    let passwordReset = (e) => {
        api('test',{Put:'PasswordReset',login:input.email, action:'PasswordReset'}).then((d) => {
            if (d.data.Error) {
                setError(error => ({...error, ress: d.data.Error}))
            } else {
                setError(error => ({...error, ress: d.data.msg}))
            }
        })
        e.preventDefault()
    }


    return <div class="login-page">
    <div class="login-page__content">
        <div class="login-page__inner">
<div class="login-inner">





<div class="d-flex justify-content-center"><img class="login-inner__logo" src={Logo} /></div>






{/* 
<div class="login-alert p-3 text-center mb-3">

<div class="text-primary">Внимание!</div><span>Вы ввели неверный логин и/или пароль</span>
</div> */}
{data.repass ? <> 


    {data.errorMsg ? <>


{ btn ?  <>

    <div class="login-inner__nav mb-5">
        
        <div className={"login-inner__nav-item " + (btn && "active")} onClick={() => setBtn(true)}>Вход</div>
        <div className={"login-inner__nav-item " + (!btn && "active")} onClick={() => setBtn(false)}>Регистрация</div>
    
    </div>


{main.msg && <div className='login-alert p-3 text-center mb-3'><div className='text-primary'>Внимание!</div><span>{main.msg}</span></div>}

{error.login && <div className='login-alert p-3 text-center mb-3'><div className='text-primary'>Внимание!</div><span>{error.login}</span></div>}

<Enter change={change} input={input} button={button} setButton={setButton} setError={setError} setData={setData} reload={reload} pass={pass} data={data} /></> : <>

<div class="login-inner__nav mb-5">
        
<div className={"login-inner__nav-item " + (btn && "active")} onClick={() => setBtn(true)}>Вход</div>
<div className={"login-inner__nav-item " + (!btn && "active")} onClick={() => setBtn(false)}>Регистрация</div>

</div>

<Reg change={change} input={input} button={button} setButton={setButton} setError={setError} error={error} setData={setData} reload={reload} reg={reg} data={data} pass={pass} />

</>

}  </> : <Error change={change} input={input} button={button} setButton={setButton} setError={setError} error={error} setData={setData} reload={reload} reg={reg} data={data} pass={pass} />}



</> : <>


<div class="login-inner__nav mb-5"><a class="login-inner__nav-item active" href="">Восстановление/изменение пароля</a></div>

{error.ress && <div className='login-alert p-3 text-center mb-3'><div className='text-primary'>Внимание!</div>{error.ress}</div>}
	<div class="form-group mb-3 form-floating">
		<input class="form-control" id="E-mail" name='email' onChange={change} required value={input.email} placeholder="E-mail" type="E-mail" />
		<label for="E-mail">E-mail</label>
	</div>
	<div class="row align-items-center">
		<div class="col-auto mb-2">
			<button class="btn btn-base btn-large" onClick={passwordReset}> Сбросить пароль</button>
		</div>
		<div class="col-auto text-secondary note mb-2">Логин и новый пароль будут высланы Вам с адреса noreply@stone-xxi.ru</div>
	</div>
    <button onClick={()=>setData(data => ({...data, repass: true}))} className='btn btn-ref'>Вернуться</button>

</>}

</div>
        </div>
    </div>
    {slide.map((e,i) => i === data.slider && <div class="login-page__img-wrap">
        <div class="login-page__img-info">
        {e.LINK ? <a className="btn btn-base disable-links" href={e.LINK} target="_blank">Подробнее</a> : <button className="btn btn-base" disabled={true}>Подробнее</button>}
            <div style={{width:'100%',position:'relative',textAlign:'right',right:'10px'}}>
            {/* <div className='text-right' style={{width:'100%',right:'10px'}}>{slide.map((ee,ii) => <button key={ii} type="button" onClick={()=>sl(ii)} className="btn-slider ms-1 me-1" style={ ii === data.slider ? {background:'#004680',border:'0px solid #f5f5f5'} : {background:'#fff',border:'0px solid #f5f5f5'}} />)}</div>     */}
                      <div className='text-right' style={{width:'100%',right:'-6px',top:'10px',position:'relative'}}>{slide.map((ee,ii) => <button key={ii} type="button" onClick={()=>sl(ii)} className={ii === data.slider ? 'btn btn-slider-ab btn-slider-active' : 'btn btn-slider-ab'}  />)}</div>    

            </div>
        </div>
        <img class="login-page__img" src={e.IMG} />
    </div>)}
</div>

    
    // return <div className='container-fluid'>
    //     <div className='row'>
    //         <div className='col-sm-4 full-page box-shadow '>
    //             <div className='site-wrapper'>
    //             <div className='site-wrapper-inner'>








    //             <div className='form-login my-3'>
    //                 <div className='logo'>
    //                     <img src={Logo} />
    //                 </div>
    //                { data.repass ? <> 
                   
                   
    //                {data.errorMsg ? <>
                   
                   
                   
                   
                   
    //                 <div className='row mt-3'>
    //                     <div className='col-xs-6' style={{paddingRight:0}}>
    //                         <button className={"btn btn-tab " + (btn && "active")} onClick={() => setBtn(true)}>Вход</button>
    //                     </div>
    //                     <div className='col-xs-6' style={{paddingLeft:0}}>
    //                         <button className={"btn btn-tab " + (!btn && "active")} onClick={() => setBtn(false)}>Регистрация</button>
    //                     </div>
    //                 </div>


                    
    //                 { btn ? <form className='mt-5'>

    //                 {main.msg && <div className='alert alert-warning mb-3 text-center'><div className='title-w mb-1'>Внимание!</div>{main.msg}</div>}

    //                     {error.login && <div className='alert alert-warning mb-3 text-center'><div className='title-w mb-1'>Внимание!</div>{error.login}</div>}
    //                     <div>
    //                         <label className="has-float-label">
    //                             <input type="email" name="email" onChange={change} id="first_name" required="required" required value={input.email} />
    //                             <span>E-mail</span>
    //                         </label>
    //                     </div>
    //                     <div className="mt-3">
    //                         <label className="has-float-label">
    //                             <input type={data.pass ? 'password' : 'text'} name="password" onChange={change} id="first_name" required="required" required />
    //                             <span>Пароль</span>
    //                             { data.pass ? <div className='icon-input'><img onClick={pass} src={Lashes} /></div> : <div className='icon-input'><img onClick={pass} src={Eye} /></div>}
    //                         </label>
    //                     </div>
                        
    //                     {/* <div className='my-3 text-center p-color'>
    //                         <div className="radio">
    //                         Войти через &emsp; <label>
    //                                 <input type="radio" name="optionsRadios" id="optionsRadios2" value="option2" />&ensp;Сбербанк ID
    //                             </label> 
                                
    //                             &ensp;
    //                             <label>
    //                                 <input type="radio" name="optionsRadios" id="optionsRadios2" value="option2" />&ensp;<FontAwesomeIcon style={{color:'#5f86b2'}} icon={faVk} />
    //                             </label> &ensp;
    //                             <label>
    //                                 <input type="radio" name="optionsRadios" id="optionsRadios2" value="option2" />&ensp;<FontAwesomeIcon style={{color:'#223c73'}} icon={faFacebookSquare} />
    //                             </label>
    //                         </div>
    //                     </div> */}

    //                     <div className='p-color my-3'>
                            
                            
    //                         <div className="row">
    //                             <div className="col-xs-6 col-xs-offset-3">
                                    
    //                                 <label className="container-checkbox ms-1">
    //                                     Запомнить меня
    //                                     <input type="checkbox"  />
    //                                     <span className="checkmark"></span>
    //                                 </label>
                                
    //                             </div>
    //                         </div>
    //                     </div>

    //                     {/* <button className='btn btn-primary btn-login btn-block my-3'>Войти</button> */}
    //                     <button type="submit" onClick={login} className="btn btn-primary btn-login btn-block mt-1" disabled={button.login}><Button title='Войти' color='btn-black' param={button.login} /></button>

    //                     <div className='text-center'><button onClick={()=>setData(data => ({...data, repass: false}))} className='btn btn-link'>Восстановить пароль</button></div>
    //                     <div className='text-center'><button onClick={()=>setData(data => ({...data, errorMsg: !data.errorMsg}))}  type='button' className='btn btn-link'>Сообщить о проблемах со входом</button></div>
    //                 </form> : <>{error.reg && <div className='alert alert-warning my-3 text-center'><div className='title-w mb-1'>Внимание!</div>{error.reg}</div>}
    //                 <form onSubmit={ reg } className='mt-5'>
    //                     <div>
    //                         <label className="has-float-label">
    //                             <input type="text" id="first_name" name="LastName" onChange={change} required="required" required />
    //                             <span>Фамилия</span>
    //                         </label>
    //                     </div>
    //                     <div className="mt-3">
    //                         <label className="has-float-label">
    //                             <input type="text" id="first_name" name="FirstName" onChange={change} required="required" required />
    //                             <span>Имя</span>
    //                         </label>
    //                     </div>
    //                     <div className="mt-3">
    //                         <label className="has-float-label">
    //                             <input type="text" id="first_name" name="MidName" onChange={change} required="required" required />
    //                             <span>Отчество</span>
    //                         </label>
    //                     </div>
    //                     <div className="mt-3">
    //                         <label className="has-float-label">
    //                             <input type="email" id="first_name" name="email" onChange={change} required="required" required />
    //                             <span>E-mail</span>
    //                         </label>
    //                     </div>
    //                     {/* <div className="mt-3">
    //                         <label className="has-float-label">
    //                             <input type={data.pass ? 'password' : 'text'} id="first_name" name="fname" required="required" required />
    //                             <span>Пароль</span>
    //                             { data.pass ? <div className='icon-input'><img onClick={pass} src={Lashes} /></div> : <div className='icon-input'><img onClick={pass} src={Eye} /></div>}
    //                         </label>
    //                     </div> */}
    //                     <button type="submit" className='btn btn-primary btn-reg btn-block mt-3'>Зарегистрироваться</button>



    //                 </form></>} 
                    
                    
                   
                   
                   
                   
                   
    //                </>:<>
                   
                   
                   
    //                <div className='row mt-3'>
    //                     <div className='col-xs-12'>
    //                         <button className={"btn btn-tab " + (btn && "active")} onClick={() => setBtn(true)}>Сообщить о проблемах со входом</button>
    //                     </div>
    //                 </div>

                    
                    
    //                 <form onSubmit={ errorMsg } className='mt-5'>

    //                 {error.errorMsg && <div className='alert alert-warning mb-3 text-center'><div className='title-w mb-1'>Внимание!</div>{error.errorMsg}</div>}
                        
    //                     <div>
    //                         <label className="has-float-label">
    //                             <input type="email" name="email" onChange={change} id="first_name" required="required" required value={input.email} />
    //                             <span>E-mail</span>
    //                         </label>
    //                     </div>


    //                     <div className='mt-3'>
    //                         <label className="has-float-label">
    //                             <textarea name="AuthProblem" onChange={change} id="first_name" required="required" style={{height:'100px'}} required rows="2" >{input.AuthProblem}</textarea>
    //                             <span>Сообщение об ошибки</span>
    //                         </label>
    //                     </div>

    //                 <button type="submit" className='btn btn-primary btn-reg btn-block mt-3'>Отправить</button>
    //                 </form>



    //                 <div className='text-center'><button onClick={()=>setData(data => ({...data, errorMsg: !data.errorMsg}))}  type='button' className='btn btn-link'>Вернуться</button></div>

                   
    //               </>}
                   
                   
                   
                    
                    
                    
                    
                    
                    
                    
    //                 </> : <>
                    
                    
    //                 <div className='row mt-3'>
    //                     <div className='col-xs-12'>
    //                         <button className={"btn btn-tab " + (btn && "active")} onClick={() => setBtn(true)}>Восстановить пароль</button>
    //                     </div>
    //                 </div>

    //                 {error.ress ? <div className='alert alert-warning mb-3 text-center mt-3'><div className='title-w mb-1'>Внимание!</div>Письмо с новым паролем отправлено на электронную почту..</div>:
                    
    //                 <form onSubmit={passwordReset} className='mt-5'>
                        
    //                     <div>
    //                         <label className="has-float-label">
    //                             <input type="text" name="email" onChange={change} id="first_name" required="required" required value={input.email} />
    //                             <span>E-mail</span>
    //                         </label>
    //                     </div>

    //                     <button type="submit" className='btn btn-primary btn-reg btn-block mt-3'>Сбросить пароль</button>

    //                     <p style={{fontSize:'15px', color:'#004F8B'}}>Логин и пароль будут высланы Вам с адреса noreply@stone-xxi.ru</p>
    //                 </form> }
    //                 <div className='text-center'><button onClick={()=>setData(data => ({...data, repass: true}))} className='btn btn-link'>Вернуться</button></div>
    //                 </>}
    //                 <p>Входя в личный кабинет пользователя, я подтверждаю, что даю согласие на обработку персональных данных, сведений об абоненте и на получение кредитного отчета.</p>
    //             </div>



                
    //             </div>
    //             </div>
    //         </div>
    //         {slide.map((e,i) => i === data.slider && <div key={i} className="col-sm-8 full-page login-fon hidden-xs" style={{backgroundImage: 'url('+e.img+')'}}>
    //             <div className="btn-slider-box">
    //                 <a type="submit" href={e.url} className="btn btn-light pe-5 ps-5">Подробнее</a>
    //                 <div className='text-right' style={{marginTop: '20px'}}>{slide.map((ee,ii) => <button key={ii} type="button" onClick={()=>setData(data => ({...data, slider: ii}))} className="btn-slider ms-1 me-1" style={ ii === data.slider ? {background:'#fdd701'} : {background:'#fff'}} />)}</div>
    //             </div>
    //         </div>)}
    //     </div>
    // </div>

}

export default Login
