import React from 'react';
import {useState, useEffect, useMemo} from 'react'
import {Link} from 'react-router-dom'
import ReviewAdd from './images/review-add.svg'

const Select = ({main, reload, com}) => {
    let [data, setData] = useState({CompanyId: main.CompanyId})

    return useMemo(() => {
        var change = (e) => {

            console.log(e.target.value);
            localStorage.setItem('CompanyId', e.target.value)
            setData(data => ({...data, CompanyId: e.target.value}))
            com(e.target.value)
            e.persist()
        }
        console.log(data);
        return <select className="form-control form-control-base form-select" onChange={change} value={data.CompanyId}>
            {main.companies && main.companies.map((d, i) => <option value={d.id} key={i}>{d.name}</option>)}
        </select>
    }, [data])
}


const Menu = ({main, reload, com}) => {

    let [modal, setModal] = useState(false)

    let [exitModal, setExitModal] = useState(false)

    let exit = () => {
        localStorage.removeItem('token')
        window.location.reload()
    }




    return <div className="sidebar">
        <div className="header-lk">
            <div className="header-lk__title mb-1">Добро пожаловать в личный кабинет, {main.userinfo}!
                <a className="icon-user ms-2 link-light" href=""/>
            </div>
            <div className="d-flex">
                <div className="form-group flex-grow-1 me-3">
                    <Select main={main} reload={reload} com={com}/>
                </div>
                <a className="btn btn-base" href="signing-contract.html">
                    <i className="icon-loupe"/>
                </a>
            </div>
        </div>
        <nav className="menu-nav">
            <ul className="menu-nav__list">

                {main.menu && main.menu.map((d, i) => ('/Logout' !== d.url && '/MainPage' !== d.url) ? <>

                    <li key={i} className="menu-nav__item"><Link key={i} class="menu-nav__link" onClick={() => reload()}
                                                                 to={d.url}>{d.name}</Link>

                        {d.child && <ul className="menu-nav__list">{d.child.map((dd, ii) =>

                            <li key={ii} className="menu-nav__item"><Link class="menu-nav__link fw-bold" key={ii}
                                                                          onClick={() => reload()}
                                                                          to={dd.url}>{dd.name}</Link></li>
                        )}</ul>}
                    </li>


                </> : '')}
                <li className="menu-nav__item">
                    <Link className="menu-nav__link" onClick={()=>setExitModal(true)}>Выход</Link>
                </li>
            </ul>


        </nav>

        <Link to='/AppLeasing' className="btn btn-base mt-4">Подать новую заявку на лизинг</Link>

        {main.PM.fio && <div className="personal-managers mt-4">Ваш персональный менеджер
            <h6 className="mt-2 mb-2">{main.PM.fio.split(' ')[0]} {main.PM.fio.split(' ')[1]} {main.PM.fio.split(' ')[2]}</h6>
            <a className="d-flex align-items-center link-black" href="tel:8 (495) 981-19-90">
                <i className="icon-tel me-2"/><span>8 (495) 981-19-90</span><span
                className="ms-2">доб. {main.PM.tel}</span></a>
            <a className="d-flex align-items-center" href={"mailto:" + main.PM.email}><i className="icon-mail me-2"/><span
                className="link-black">{main.PM.email}</span></a>
            <button className="btn btn-base btn-small mt-3" disabled>Связаться с
                менеджером</button>
        </div>}


        <div className="give-feedback mt-4" style={{cursor:'pointer'}} onClick={()=>setModal(true)}>
            <img className="give-feedback__img" src={ReviewAdd}/>Оставьте свой отзыв и помогите нам стать лучше!
        </div>





        {modal && <div className="modal-open"><div class="modal fade show" id="modalManager" tabindex="-1" aria-labelledby="modalManager" aria-modal="true" role="dialog" style={{display: 'block'}}>
			<div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
                <div class="modal-header">
						<h5 class="modal-title">Связаться с менеджером</h5>
						<button class="btn-close" type="button" data-bs-dismiss="modal" onClick={()=>setModal(false)}></button>
					</div>
                    <div class="modal-body"> У Вас есть замечания по работе личного кабинета?<br />
Есть предложения по работе с компанией?<br />
Хотите похвалить работу менеджера?<br />
Направляйте письмо на <a href="mailto:feedback@stone-xxi.ru">feedback@stone-xxi.ru</a>, здесь рассматривают каждое обращение!
</div>
<div class="modal-footer"></div>

				</div>
			</div>
		</div></div>}


        {exitModal && <div className="modal-open"><div class="modal fade show" id="modalManager" tabindex="-1" aria-labelledby="modalManager" aria-modal="true" role="dialog" style={{display: 'block'}}>
        <div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<h3 class="modal-title">Выход</h3>
						<button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setExitModal(false)}></button>
					</div>
					<div class="modal-body">
						<p>Завершаете работу в личном кабинете ЛК «СТОУН-XXI»?</p>
						<ul>
							<li>Убедитесь, что сохранили текущие результаты работ и загрузили все документы.</li>
							<li>В следующий раз вы сможете продолжить работу там же, где закончили.</li>
						</ul>
					</div>
					<div class="modal-footer">
						<button class="btn btn-base" onClick={exit}>Выйти из личного кабинета</button>
					</div>
				</div>
			</div>
		</div></div>}










    </div>


}

export default Menu
