import React, { useEffect, useState } from "react";
import {api} from '../Run'
import managerM from "../images/manager-m.png"
import managerW from "../images/manager-w.png"

const Contacts = ({main}) => {

    let [contacts,setContacts] = useState({})

    useEffect(()=> {

        api('request',{"Get":"CompanyContact",action:'ContactInfo',CompanyId:main.CompanyId}).then((d) => {
            setContacts(d.data)
        })

    },[])

    // let contacts = [
    //     {
    //         id: '1',
    //         name: 'Стоунов Иван Иванович',
    //         position: 'заместитель руководителя отдела продаж в регионе',
    //         tel: '8 (495) 981-19-90 доб.7777',
    //         email: 'sender@stone-xxi.ru',
    //         photo: managerM
    //     },
    //     {
    //         id: '2',
    //         name: 'Стоунова Мария Ивановна',
    //         position: 'заместитель руководителя отдела продаж в регионе',
    //         tel: '8 (495) 981-19-90 доб.7777',
    //         email: 'sender@stone-xxi.ru',
    //         photo: managerW
    //     },
    //     {
    //         id: '3',
    //         name: 'Стоунов Иван Иванович',
    //         position: 'заместитель руководителя отдела продаж в регионе',
    //         tel: '8 (495) 981-19-90 доб.7777',
    //         email: 'sender@stone-xxi.ru',
    //         photo: managerM
    //     }
    // ]



    

    return (
        <div className="content-page">
            <div className="content-main">
                <div className="page-head mb-4">Контакты</div>
                <div className="row">

                {contacts.director && <div key={contacts.director.id} className="col-lg-6 mb-5">
                            <div className="manager-item">
                            <img className="manager-item__img" src={contacts.director.gender === 'M' ? managerM : managerW}/>
                            <div className="manager-item__body">
                                <div className="h5">{contacts.director.last_name} {contacts.director.name} {contacts.director.second_name}</div>
                                <div className="text-secondary mb-3">Директор</div>
                                {contacts.director.personal_mobile &&  <a className="d-flex align-items-center line-height-1_2 mb-2">
                                    <i className="icon-tel me-2"/>{contacts.director.personal_mobile}
                                </a>}
                                <a className="d-flex align-items-center line-height-1_2 mb-3" href={`mailto:${contacts.director.email}`}>
                                    <i className="icon-mail me-2"/>{contacts.director.email}
                                </a>
                                {contacts.director.personal_mobile &&   <a className="btn btn-base" href={'tel:'+contacts.director.personal_mobile}>Перезвоните мне!</a>}
                            </div>
                        </div>
                        </div>}


                        {contacts.manager && <div key={contacts.manager.id} className="col-lg-6 mb-5">
                            <div className="manager-item">
                            <img className="manager-item__img" src={contacts.manager.gender === 'M' ? managerM : managerW}/>
                            <div className="manager-item__body">
                                <div className="h5">{contacts.manager.last_name} {contacts.manager.name} {contacts.manager.second_name}</div>
                                <div className="text-secondary mb-3">Менеджер</div>
                                {contacts.manager.personal_mobile && <a className="d-flex align-items-center line-height-1_2 mb-2">
                                    <i className="icon-tel me-2"/>{contacts.manager.personal_mobile}
                                </a>}
                                <a className="d-flex align-items-center line-height-1_2 mb-3" href={`mailto:${contacts.manager.email}`}>
                                    <i className="icon-mail me-2"/>{contacts.manager.email}
                                </a>
                                {contacts.manager.personal_mobile && <a className="btn btn-base" href={'tel:'+contacts.manager.personal_mobile}>Перезвоните мне!</a>}
                            </div>
                        </div>
                        </div>}

                        {/* {contacts.map(el => <div key={el.id} className="col-lg-6 mb-5">
                            <div className="manager-item">
                            <img className="manager-item__img" src={el.photo}/>
                            <div className="manager-item__body">
                                <div className="h5">{el.name}</div>
                                <div className="text-secondary mb-3">{el.position}</div>
                                <a className="d-flex align-items-center line-height-1_2 mb-2">
                                    <i className="icon-tel me-2"/>{el.tel}
                                </a>
                                <a className="d-flex align-items-center line-height-1_2 mb-3" href={`mailto:${el.email}`}>
                                    <i className="icon-mail me-2"/>{el.email}
                                </a>
                                <a className="btn btn-base">Перезвоните мне!</a>
                            </div>
                        </div>
                        </div>)} */}
                </div>
            </div>
            <div className="content-promo"/>
        </div>
    )
}

export default Contacts
