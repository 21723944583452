import React, {useState, useEffect} from 'react';
import UploadBuhFile from "./UploadBuhFile";
import {api,Load} from "../../../Run";
import {toast} from 'react-toastify';

let Doc = (props) => {
    const [isActiveSave, setSave] = useState(false);
    const [isDisabledConsideration, setConsideration] = useState(true);
    const [leftToDownload, setLeftToDownload] = useState(0);
    const [leftToCheck, setLeftToCheck] = useState(0);
    const [leftReady, setLeftReady] = useState(0);
    const [createdDate, setCreatedDate] = useState('');
    const [status, setStatus] = useState('1');
    const [buhDocs, setBuhDocs] = useState([]);
    const [amount, setAmount] = useState(0);
    const [applicationDate, setApplicationDate] = useState('');
    const [data, setData] = useState({checkDoc: true});
    const [disableUpload, setDisableUpload] = useState(false)
    let [error, setError] = useState({});
    let [load, setLoad] = useState({list: true});

    useEffect(() => {
        toDownload();
        onCheck();
        readyDocs();
        checkDisableConsideration();
    }, [buhDocs]);

    useEffect(() => {
        setLoad(load => ({...load, list: true}))
        fetchListDocs();
        fetchAppLeasingData();
    }, []);

    const checkDisableConsideration = () => {
        if (!buhDocs.some(el => el.DocStatus === '0')) {
            setConsideration(!isDisabledConsideration)
        }
    };

    const fetchAppLeasingData = () => {
        api('test', {
                Get: "AppLeasingForm",
                action: 'AppLeasingEdit',
                AppLeasingId: props.page.id,
                CompanyId: props.main.CompanyId,
                token: localStorage.getItem('token')
            }
        ).then((response) => {
            if (response.s === 'success') {
                if (parseInt(response.data.AppLeasingStatus) > 4) {
                    setDisableUpload(true)
                }
                setAmount(new Intl.NumberFormat('ru-RU').format(response.data.AppLeasingAmount))
                const date = new Date(response.data.AppLeasingDate).toLocaleString('ru', {
                    year: 'numeric',
                    month: 'numeric',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric'

                });
                setApplicationDate(date)
                console.log('d', response )
            }
        })
    };

    let checkDoc = () => {
        let lll = false;
        for (let index = 0; index < buhDocs.length; index++) {
            if (buhDocs[index].DocFile === '') {
                lll = true;
            }
        }

        if (lll) {
            setData(data => ({...data, checkDoc: false}))
        }

        console.log(buhDocs);
    }

    const fetchListDocs = () => {
        setError(error => ({title: 'Внимание!', msg: ''}))
        const data = {
            Get: "DocList",
            action: "DocPackList",
            AppLeasingId: props.page.id,
            token: localStorage.getItem('token')
        };

        api('request', data).then(
            response => {
                if (response.s === 'success') {
                    const date = new Date(response.data.DocPackStatusDate).toLocaleString('ru', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric'

                    });
                    setCreatedDate(date);
                    // setStatus(response.data.DocPackStatus);
                    // const itemStatuses = response.data.DocPackItem.filter( el => {
                    //     return el.DocStatus !== '0'
                    // })
                    // if (response.data.DocPackItem.length === itemStatuses.length) {
                    //     setStatus('1')
                    // }
                    let result = response.data.DocPackItem.map(el => {
                        el.file = null;
                        el.isErrorRequired = false;
                        return el;
                    });
                    setBuhDocs(result)
                }
                setLoad(load => ({...load, list: false}))
            }
        ).then(() => {
                checkDoc()
            }
        );
    };

    const toDownload = () => {
        let buhdoc = buhDocs.filter(el => el.DocStatus === '0' || el.DocStatus === '3');
        setLeftToDownload(buhdoc.length);
    };
    const onCheck = () => {
        let check = buhDocs.filter(el => el.DocStatus === '1');
        setLeftToCheck(check.length);
    };
    const readyDocs = () => {
        let ready = buhDocs.filter(el => el.DocStatus === '2');
        setLeftReady(ready.length)
    };

    const setBuhDocument = (Id, file, typeId) => {
        if (!file) {
            return
        }
        let temp = [];
        for (let index = 0; index < file.length; index++) {


            let reader = new FileReader()
            reader.readAsDataURL(file[index])
            reader.onload = () => {
                temp = [...temp,{file:reader.result,name:file[index].name}]

                if ((index+1) === file.length) {
                  api('upload', {
                AppLeasingId: props.page.id,
                files: temp
            }).then(responseUpload => {
                if (responseUpload.s === 'success') {
                    return responseUpload.url
                }
            }).then(url => {
                api('request/', {
                    Put: "DocList",
                    action: "DocUpload",
                    AppLeasingId: props.page.id,
                    token: localStorage.getItem('token'),
                    DocPackItem: {
                        DocId: Id,
                        DocTypeId: typeId,
                        DocFile: url
                    }
                }).then(response => {
                    if (response.s === 'success') {
                        return response.data.DocPackItem
                    }
                }).then(doc => {
                    console.log('doc', doc)
                    let newBuhDocs = buhDocs.map(el => {
                        if (el.DocId === Id) {
                            return {
                                ...el,
                                ...doc,
                                DocFileName: url,
                                fileName: '/api/files/' + url
                            }
                        }
                        return el;
                    });
                    setBuhDocs(newBuhDocs);
                    if (newBuhDocs.some(el => el.DocStatus === '0')) {
                        setConsideration(!isDisabledConsideration)
                    }
                }).then(() => {
                        checkDoc()
                    }
                );
            })
                }

            }
        }

        // let fileReader = new FileReader();
        // fileReader.readAsDataURL(file)
        // fileReader.onload = () => {

        //     api('upload', {
        //         AppLeasingId: props.page.id,
        //         token: localStorage.getItem('token'),
        //         file: fileReader.result,
        //         type: file.name.match(/\.[0-9a-z]+$/i)[0]
        //     }).then(responseUpload => {
        //         if (responseUpload.s === 'success') {
        //             return responseUpload.url
        //         }
        //     }).then(url => {
        //         api('request/', {
        //             Put: "DocList",
        //             action: "DocUpload",
        //             AppLeasingId: props.page.id,
        //             token: localStorage.getItem('token'),
        //             DocPackItem: {
        //                 DocId: Id,
        //                 DocTypeId: typeId,
        //                 DocFile: url
        //             }
        //         }).then(response => {
        //             if (response.s === 'success') {
        //                 return response.data.DocPackItem
        //             }
        //         }).then(doc => {
        //             console.log('doc', doc)
        //             let newBuhDocs = buhDocs.map(el => {
        //                 if (el.DocId === Id) {
        //                     return {
        //                         ...el,
        //                         ...doc,
        //                         DocFileName: url,
        //                         fileName: '/api/files/' + url
        //                     }
        //                 }
        //                 return el;
        //             });
        //             setBuhDocs(newBuhDocs);
        //             if (newBuhDocs.some(el => el.DocStatus === '0')) {
        //                 setConsideration(!isDisabledConsideration)
        //             }
        //         }).then(() => {
        //                 checkDoc()
        //             }
        //         );
        //     })
        // };
    };

    const sendToConsideration = () => {
        const filterRequired = buhDocs.filter(el => {
            console.log('el', el)
            if (el.DocStatus === '0' && el.DocRequired === '1') {
                return true
            }
        }).reduce((acc, curr) => (acc[curr.DocId] = true, acc), {})

        if (Object.keys(filterRequired).length > 0) {
            let newBuhDocs = [];
            buhDocs.forEach(el => {
                if (filterRequired.hasOwnProperty(el.DocId)) {
                    el.isErrorRequired = true;
                    newBuhDocs.unshift(el)
                } else {
                    newBuhDocs.push(el)
                }
            })
            setBuhDocs(newBuhDocs)
            toast.error('Не загружены обязательные документы');
            return
        }
        setError(error => ({title: 'Внимание!', msg: ''}))
        // setSave(!isActiveSave);
        api('request/', {
            Put: "DocList",
            action: "DocPackSend",
            token: localStorage.getItem('token'),
            AppLeasingId: props.page.id
        }).then(
            (response) => {
                setError(error => ({title: 'Внимание!', msg: response.data.msg}))
                setStatus(response.data.DocPackStatus)
                props.setPage(page => ({...page, AppLeasingStatus: response.data.AppLeasingStatus}))
                console.log('response put', response)
            }
        )
    };

    let ref = () => {
        api('request/', {
            Put: "DocList",
            action: "DocPackList",
            token: localStorage.getItem('token'),
            AppLeasingId: props.page.id
        }).then(
            (response) => {
                console.log('response put', response)
            }
        )
    }

    let sendPackUnSend = () => {
        setError(error => ({title: 'Внимание!', msg: ''}))
        api('request/', {
            Put: "DocList",
            action: "DocPackUnSend",
            token: localStorage.getItem('token'),
            AppLeasingId: props.page.id
        }).then(
            (response) => {
                props.setPage(page => ({...page, AppLeasingStatus: response.data.AppLeasingStatus}))
                setStatus('1')
                setError(error => ({title: 'Внимание!', msg: response.data.msg}))
            }
        )
    }


    if (load.list) {
        return <Load />
       } else {

    return <>
        <div className="row mt-5">
            <div className="col-lg-4 mb-3">
                <div className="grid-table grid-table_col-2">
                    <div className="grid-table__item mb-2">Дата обращения:</div>
                    <div className="grid-table__item mb-2">{applicationDate}</div>
                    <div className="grid-table__item mb-2">Сумма заявки:</div>
                    <div className="grid-table__item mb-2">{amount}</div>
                </div>
            </div>
            <div className="col-lg-4 mb-3">
                <div className="grid-table grid-table_col-2">
                    <div className="grid-table__item mb-2">На проверке:</div>
                    <div className="grid-table__item mb-2">{leftToCheck} документ(ов)</div>
                    <div className="grid-table__item mb-2">Осталось загрузить:</div>
                    <div className="grid-table__item mb-2">{leftToDownload} документ(ов)</div>
                    <div className="grid-table__item mb-2">Согласовано:</div>
                    <div className="grid-table__item mb-2">{leftReady} документ(ов)</div>
                </div>
            </div>
            <div className="col-lg-4 mb-3">
                <div className="grid-table grid-table_col-2">
                    <div className="grid-table__item mb-2">Список обновлен:</div>
                    <div className="grid-table__item mb-2">{createdDate}</div>
                </div>
            </div>
        </div>

        <div className="collecting-documents-table table-responsive mt-5">
            <table className="table">
                <tbody>
                {buhDocs.map((el) => {
                    return <UploadBuhFile
                        key={el.DocId}
                        doc={el}
                        setDocument={setBuhDocument}
                        page={props.page}
                        link={el.DocLink}
                        disableUpload={disableUpload}
                    />
                })}
                </tbody>
            </table>
        </div>

        {error.msg && <div className='login-alert p-3 text-center mb-3'><div className='text-primary'>Внимание!</div><span>{error.msg}</span></div>}

        <div className="row mt-3">
            <div className="col-auto mb-3">
                <button
                    className="btn btn-base"
                    onClick={sendToConsideration}
                    disabled={!isDisabledConsideration}
                >Отправить на рассмотрение
                </button>
            </div>
            <div className="col-auto mb-3">
                <button
                    className="btn btn-ghost"
                    onClick={fetchListDocs}
                    // disabled={isDisabledConsideration}
                >Сохранить
                </button>
            </div>
        </div>
        <div className="row mb-3 ms-1">
            <button
                className="col-auto mb-3 btn btn-ref-link"
                onClick={sendPackUnSend}
                disabled={!isDisabledConsideration}
                // disabled={!(status === "2")}
            >Отменить отправку
            </button>&ensp;<button
                className="col-auto mb-3 btn btn-ref-link"
                disabled={!isDisabledConsideration}
            >Узнать результат рассмотрения
            </button>
        </div>
    </>}
};

export default Doc;
