import React from 'react';
import {postRequest} from "../../Run";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faLaptopHouse} from "@fortawesome/free-solid-svg-icons";
import style from './Adress.module.css';
import 'react-toastify/dist/ReactToastify.css'


const Fio = (props) => {

    const [showTooltip, setShowTooltip] = React.useState(false)
    const [options, setOptions] = React.useState([]);

    const getOptions = (input) => {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Authorization: Token 599670fac2d78bb10384a4655f5b8fcc465125c2'
        }

        postRequest(
            `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fio`,
            {query: input,"parts": ["NAME"]},
            headers
        ).then((response) => {
            setOptions(response.suggestions.map( el => el.value))
        })
    }


    const onChange = (evt) => {
        let value = evt.currentTarget.value;
        props.onChange(value);
        getOptions(value);
    }

    const toggleTooltip = () => {
        setShowTooltip(!showTooltip)
    }

    const setCurrentOption = (value) => {
        props.onChange(value);
        setOptions([]);
    }

    const onBlur = () => {
        if (props.hasOwnProperty('onBlur')) {
            props.onBlur()
        }
        toggleTooltip();
        setTimeout(() => {
            setOptions([]);
        }, 200);

    };

    return (
        <>
<div className={`form-group mb-3 form-floating ${props.error && 'has-error'}`}>

            <input
                type="text"
                name={props.name}
                value={props.value}
                onChange={onChange}
                disabled={props.disabled}
                autoComplete='off'
                className={props.className}
                onBlur={onBlur}
            />
            <label>{props.label}</label>
            </div>
            {/* <span>{props.label} {props.tooltipText && <FontAwesomeIcon icon={faInfoCircle} />}</span> */}
            {!!options.length &&
                <div className={style.dropDown}>
                    {options.map( el => (
                        <div className={style.dropDown__item}
                            onClick={() => setCurrentOption(el)}
                        >{el}</div>
                    ))}
                </div>
            }
        </>
    )
}

export default Fio;
