import {useEffect, useState} from 'react'
import {LPPartner} from "../ErrorInput";
import InnPrompt from "../../../components/common/InnPrompt";

let Blank5 = ({save,input,data,setData,error,setError,del,changeArr,changeInnArr,disabled}) => {

    const onChangeInn = (i,value, name, onlyValue=true) => {

        if (!onlyValue) {
            value = value.data.name.short_with_opf
        }
        changeInnArr('LPPartner', i, name, value)
    };

    return data.LPPartner.map((d, i) => <><div class="row mt-3 flex-column mb-3">
	<div class="col-md-8 col-lg-6">
		{/* <div className={`form-group mb-3 form-floating ${error.LPPartner[i].LPPartnerName && 'has-error'}`}>
			<input class="form-control" name="LPPartnerName" onChange={(e) => changeArr(e, i, 'LPPartner')} value={d.LPPartnerName} placeholder="Иванов" />
			<label>Название или ИНН</label>
		</div> */}
        <InnPrompt
			error={error.LPPartner[i].LPPartnerName}
                            value={d.LPPartnerName}
                            name="LPPartnerName"
                            className='form-control'
                            label='Название или ИНН'
                            onChange={onChangeInn}
                            arr={true}
							i={i}
                            disabled={disabled}
                            onBlur={save} 
                        />
	</div>
</div>

<div className='mb-3'>{data.LPPartner.length !== 1 && <button
onClick={() => del('LPPartner', i)} className="btn btn-ref" onBlur={save}  disabled={disabled}>
                            Удалить контрагента
                        </button>}
                        </div>
                        <div>{data.LPPartner.length === (i + 1) &&
                        <button
                            onClick={() => {
                                setError(error => ({...error, LPPartner: [...error.LPPartner, LPPartner]}));
                                setData(data => ({...data, LPPartner: [...data.LPPartner, LPPartner]}))
                            }}
                            onBlur={save} 
                            className="btn btn-ref" disabled={disabled}>
                            Добавить контрагента
                        </button>}
                        </div>


</>)
}

export default Blank5