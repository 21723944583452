
import {api,Button} from '../../Run'

function Reg({change,input,button,setButton,setError,error,setData,reload,reg,data,pass}) {


    return <>
    <div className="col-12">{error.reg && <div className='login-alert p-3 text-center mb-3'><div className='text-primary'>Внимание!</div><span>{error.reg}</span></div>}</div>
    <div className={`form-group mb-3 form-floating ${error.FirstName && 'has-error'}`}>
        <input class="form-control" name="LastName" onChange={change} required  placeholder="E-mail" />
        <label for="E-mail">Фамилия *</label>
    </div>
    
    <div class="row">
        <div class="col-md-6">
        <div className={`form-group mb-3 form-floating ${error.LastName && 'has-error'}`}>
                <input class="form-control" name="FirstName" onChange={change} required placeholder="E-mail" />
                <label for="E-mail">Имя *</label>
            </div>
        </div>
        <div class="col-md-6">
        <div className={`form-group mb-3 form-floating ${error.MidName && 'has-error'}`}>
                <input class="form-control" name="MidName" onChange={change} required placeholder="E-mail" />
                <label for="E-mail">Отчество</label>
            </div>
        </div>
    </div>
    
    <div className={`form-group mb-3 form-floating ${error.email && 'has-error'}`}>
        <input class="form-control" id="E-mail" name="email" onChange={change} required placeholder="E-mail" />
        <label for="E-mail">E-mail *</label>
    </div>
    
    {/* <div class="note text-secondary mb-3">Пароль – не менее 8 знаков, включающих цифры, буквы и  символы верхнего и нижнего регистров.</div>
    <div className={`form-group mb-3 form-floating ${error.email && 'has-error'}`}>
        <div class="custom-group custom-group-append form-floating">
            <input class="form-control" type={data.pass ? 'password' : 'text'} placeholder="Placeholder" />
            <label for="E-mail">Пароль</label><div class="custom-group-icon js-drop-eye" onClick={pass}><i class="icon-eye"></i></div>
        </div>
    </div> */}
    <div class="note text-secondary mb-3">Входя в личный кабинет пользователя, я подтверждаю, что даю <a href="https://www.stone-xxi.ru/about/person/" target="_blank">согласие на обработку персональных данных</a>.</div>
    <div class="row align-items-center">
        <div class="col-auto mb-2">
            <button class="btn btn-base btn-large" onClick={reg} disabled={button.reg}><Button title='Зарегистрироваться' color='btn-black' param={button.reg} /></button>
        </div>
        {/* <div class="col-auto text-secondary note mb-2" style="max-width: 200px">* — поля, обязательные для заполнения</div> */}
        <div class="col-auto text-secondary note mb-2">* — поля, обязательные для заполнения</div>
    </div>
    
    
    </>
}

export default Reg