import React, {useState, useEffect} from 'react'
import {api,getRequest,Load} from '../../Run'
import moment from "moment"
import style from './Doc.module.css';
import {OverlayTrigger, Popover} from "react-bootstrap";
import FileDownload from 'js-file-download'
import {Link} from "react-router-dom";


let Kp = ({page,setPage}) => {

	let [data, setData] = useState({kp: []});
    let [input, setInput] = useState({cancel:false,KPid: 0,KPList:[],KPListNo:false});
    let [msg,setMsg] = useState({title:'',msg:''})
    const [sortNumber, setSortNumber] = useState('asc');
    const [sortDate, setSortDate] = useState('no');
    const [sortSumm, setSortSumm] = useState('no');
    const [sortPercent, setSortPercent] = useState('no');
    const [sortSL, setSortSL] = useState('no');
    const [sortStatus, setSortStatus] = useState('no');
    const [lastActive, setLastActive] = useState('');

    const [showErrorDownload, setShowErrorDownload] = React.useState(false);

    let [load, setLoad] = useState({list: true});

	useEffect(() => {
        setLoad(load => ({...load, list: true}))
        api('request', {
            Get: "KPList",
            action: "KPListShow",
            AppLeasingId: page.id,
            token: localStorage.getItem('token')
        }).then((response) => {
            console.log(response)
            if (response.data.Error) {
                setData({...data, error: response.data.Error});
                return {
                    isError: true
                }
            } else {
                setData({...data, kp: response.data.KP});

                console.log(response.data.KP);
                response.data.KP.forEach(element => {
                    element.list.forEach((e)=>{
                        if (e.KPStatus === '3') {
                            setInput(input => ({...input, cancel:true, KPList: [...input.KPList, {KPId:e.KPId,...e,name:element.name}]}))
                        }
                    })

                });


                return {
                    isError: false
                }
            }
        }).then(
            result => {
                if (!result.isError) {
                    sortingNumber(sortNumber)
                }
                setLoad(load => ({...load, list: false}))
            }
        )
    }, []);

    let status = (s) => {
        let text;
        if (s === '0') {
            text = 'Создан расчет'
        } else if (s === '1') {
            text = 'Запрошен КП'
        } else if (s === '2') {
            text = 'Менеджер выслал КП'
        } else if (s === '3') {
            text = 'КП акцептовано'
        } else if (s === '4') {
            text = 'КП отклонено'
        } else if (s === '5') {
            text = 'КП просрочено'
        }
        return text
    };

    let accept = () => {

        console.log(input);

        api('request', {
            Put: "KPList",
            action: "KPAccept",
            AppLeasingId: page.id,
            KPList: input.KPList,
            token: localStorage.getItem('token')
        })
            .then(response => {

                if (response.data.Error) {
                    setMsg(msg => ({title:'Внимание!',msg:response.data.Error}))
                } else {

                    setPage(page => ({...page,modNewKp:true}))

                    // setData(data => ({
                    //     ...data, kp: data.kp.map((e, i) => {
                    //         e.list.map((d)=> {
                    //             // console.log(d.KPId);
                    //             if (d.KPId == response.data[0].KPId) {
                    //                 return {...d, KPStatus: response.data[0].Status}
                    //             } else {
                    //                 return d
                    //             }
                    //         })
                    //     })
                    // }))


     
           
    
                    setPage(page => ({...page,AppLeasingStatus:String(response.data.AppLeasingStatus)}))
                }

            })
    };


    let dow = (id) => {


        api('request', {"Get":"KPList","action":"KPFileDownload","KPId":id,
            token: localStorage.getItem('token')
        })
            .then(response => {
                return response;
            }).then(response => {
    
            getRequest('/api/fileKp', {
                KPId: id,
                token: localStorage.getItem('token')
            }).then(
                data => {
                    if (!data) {
                        setShowErrorDownload(id)
                    } else {
                        console.log(
                            response
                        );
                        FileDownload(data, response.data.KPFileName);
                    }
                }
            )

            })
        
    
    
    };


    const onToggle = () => {
        setShowErrorDownload(false)
    };



    const popoverDownloadError = (
        <Popover id="popover-basic">
            <div className={style.docNotes}>
                Ошибка скачивания!
            </div>
        </Popover>
    );


    let cancel = () => {
        api('request', {
            Put: "KPList",
            action: "KPAcceptCancel",
            AppLeasingId: page.id,
            KPList: input.KPList,
            token: localStorage.getItem('token')
        })
            .then((d) => {
                // setPage(page => ({...page,AppLeasingStatus:d.data.AppLeasingStatus}))
            
                setData(data => ({
                    ...data, kp: data.kp.map((e, i) => {
                        return {...e, list: e.list.map((ee)=>{
                            if (ee.KPStatus === '3') {
                                return {...ee, KPStatus: "2"}
                            } else {
                                return ee
                            }
                        })}
                    })
                }))
                setPage(page => ({...page,modNewKp:false,AppLeasingStatus:d.data.AppLeasingStatus}))
                setInput(input => ({...input, cancel:false, KPList: []}))
            })
    };

    const sortingNumber = (direction) => {
        if (!(data && data.kp.length)) {
            return
        }

        const sorted = [...data.kp].sort( (a, b) => {
            let compare = parseInt(a.KPId) < parseInt(b.KPId);
            if (direction === 'asc') {
                return compare ? 1 : -1
            } else if (direction === 'desc') {
                return compare ? -1 : 1
            }
            return Math.random() - 0.5
        });
        setSortNumber(direction);
        setSortDate('no');
        setSortStatus('no');
        setSortSumm('no');
        setSortPercent('no');
        setSortSL('no');
        setLastActive('number');
        setData({...data, kp: sorted})
    };

    const sortingDates = (direction) => {
        const sorted = [...data.kp].sort( (a, b) => {
            if (direction === 'asc') {
                return new Date(b.KPDate) - new Date(a.KPDate)
            } else if (direction === 'desc') {
                return new Date(a.KPDate) - new Date(b.KPDate)
            }
            return Math.random() - 0.5
        });
        setSortDate(direction);
        setSortNumber('no');
        setSortStatus('no');
        setSortSumm('no');
        setSortPercent('no');
        setSortSL('no');
        setLastActive('number');
        setLastActive('date');
        setData({...data, kp: sorted})
    };

    const sortingNum = (direction, field) => {
        const sorted = [...data.kp].sort( (a, b) => {
            if (direction === 'asc') {
                return +b[field] - +a[field]
            } else if (direction === 'desc') {
                return +a[field] - +b[field]
            }
            return Math.random() - 0.5
        });
        switch (field) {
            case 'KPAmount': {
                setSortSumm(direction);
                setSortDate('no');
                setSortStatus('no');
                setSortPercent('no');
                setSortNumber('no');
                setSortSL('no');
                setLastActive('number');
                setLastActive('amount');
                break
            }
            case 'KPAdvance': {
                setSortPercent(direction);
                setSortDate('no');
                setSortStatus('no');
                setSortSumm('no');
                setSortNumber('no');
                setSortSL('no');
                setLastActive('percent');
                break
            }
            case 'KPSl': {
                setSortSL(direction);
                setSortDate('no');
                setSortStatus('no');
                setSortSumm('no');
                setSortPercent('no');
                setSortNumber('no');
                setLastActive('sl');
                break
            }
            case 'KPStatus': {
                setSortStatus(direction);
                setSortDate('no');
                setSortSumm('no');
                setSortPercent('no');
                setSortNumber('no');
                setSortSL('no');
                setLastActive('status');
                break
            }
        }
        setData({...data, kp: sorted})
    };


    let intt = (ev,id,ii,d,name) => {
        if(ev.target.checked) {
            data.kp.map((dd,i)=> {
                if (i === ii) {
                    dd.list.map((l)=> {
                        if (l.KPId === id) {
                            setInput(input => ({...input, KPList: [...input.KPList, {KPId:id,...d,name:name}]}))
                        } else {
                            setInput(input => ({...input, KPList: [...input.KPList.filter((ee)=> l.KPId !== ee.KPId )] }))
                        }
                    })
                } 
            })
        } else {
            data.kp.map((dd,i)=> {
                if (i === ii) {
                    dd.list.map((l)=> {
                        if (l.KPId === id) {
                            setInput(input => ({...input, KPList: [...input.KPList.filter((ee)=> ee.KPId !== id && {KPId:ee.KPId,...ee,name:name} )] }))
                        }
                    })
                } 
            })
        }
        
        // setInput(input => ({...input, KPListNo: true}))
        // if (ev.target.checked) {
        //     setInput(input => ({...input, KPList: [...input.KPList, {KPId:id}]}))
        // } else {
        //     setInput(input => ({...input, KPList: [...input.KPList.filter((ee)=> ee.KPId !== id && {KPId:ee.KPId} )] }))
        // }
    }


    let item = (d) => {
        
        setPage(page => ({...page,step:'new', KPId: d.KPId,newKP:Number(d.KPId)}))
    }


    let  reverseString = (str) => {

        if(str) {
            let splitString = String(str).split("");
        let reverseArray = splitString.reverse();
        let joinArray = reverseArray.join("");

        let num = joinArray.replace(/\s/g, '')
        let len = num.length
        let arr = []
        for (let i = 0; i < len; i++) {
          arr.push(num[i]);
          if ((i + 1) % 3 == 0 && i !== len - 1) {
            arr.push(' ');
          }
        }
        let newFormat = arr.reverse().join('');


        return newFormat;
        } else {
            return '';
        }
    }



    let del = (id,g) => {
        console.log({...data, kp: data.kp.map((d,i)=> i === g ? {...d , list: d.list.filter((ll)=> ll.KPId !== id)} : d ) });

        api('request', {
            Put: "KPList",
            action: "KPDelete",
            AppLeasingId: page.id,
            KPId: id
        })
            .then((d) => {
                if (d.data.Error) {
                    setMsg(msg => ({title:'Внимание!',msg:d.data.Error}))
                } else {
                    setMsg(msg => ({title:'Внимание!',msg:d.data.msg}))
                    setData(data => ({...data, kp: data.kp.map((d,i)=> i === g ? {...d , list: d.list.filter((ll)=> ll.KPId !== id)} : d ) }))
                }
            })
    }

    let copy = (id) => {

        // setPage(page => ({...page,step:'new', ...e, KPId: 0}))
        // console.log({...e,KPId:0});
        api('request', {
            Get: "KPCalcEdit",
            action: "KPCopy",
            AppLeasingId: page.id,
            KPId: id
        }).then((d) => {
            if (d.data.Error) {
                setMsg(msg => ({title:'Внимание!',msg:d.data.Error}))
            } else {
                setPage(page => ({...page,step:'new', KPId: id,newKP:false}))
            }
        })

    }


    if (load.list) {
        return <Load />
       } else {


    return data.kp.length > 0 ? <>
                    <div className="col-12">{msg.msg && <div className='login-alert p-3 text-center mb-3'><div className='text-primary'>{msg.title}</div><span>{msg.msg}</span></div>}</div>

    
    <div class="table-responsive mt-4">
	<table class="table text-nowrap">
		<thead>
			<tr>				
                {/* <th onClick={ () => sortingNumber(sortNumber === 'asc' ? 'desc' : sortNumber === 'desc' ? 'no' : 'asc' )} className={`table-sort ${sortNumber === 'asc' ? 'table-sort_up' : sortNumber === 'desc' ? 'table-sort_down': ''}`}>№ расчета</th> */}

				<th  className={`table-sort`}>№ расчета</th>
				<th class="table-sort">Предмет лизинга</th>
                <th class="table-sort">Дата обращения</th>
				<th class="table-sort">Сумма заявки</th>
				<th class="table-sort">Аванс</th>
				<th class="table-sort">Срок лизинга</th>
				<th class="table-sort">Статус КП</th>
				<th>Скачать</th>
			</tr>
		</thead>
		<tbody>
			{data.kp.map((ee, ii) => {
				return<> 
                
                
                <tr>
                    <td colspan="8" style={{background:'#e9eff2'}}>
                        {ee.name}
                    </td>
                </tr>
                
                {ee.list.length !== 0 ? ee.list.map((e, i) => {
				return<>  <tr>
					<td>
                <label>
                    <input type="checkbox" onChange={(ev) =>  intt(ev,e.KPId,ii,e,ee.name)} disabled={!(e.KPStatus == '2' || e.KPStatus == '3') || !(page.AppLeasingStatus <= '4' ) || input.cancel} checked={input.KPList.find((f)=>f.KPId === e.KPId)} /> <span>&#8203;</span></label>

						{/* <label><input type="checkbox" onChange={(ev) =>  intt(ev,e.KPId,ii)} checked={input.KPList.find((f)=>f.KPId === e.KPId)} /> <span>&#8203;</span></label> */}

                <Link class="btn btn-ref-link" style={{fontSize:'12px'}} onClick={() => item(e)}>{e.KPNo}</Link> 
					</td>
                    <td>{e.PLName}</td>
					<td>{moment(e.KPDate).format('DD.MM.YYYY')}</td>
					<td>{new Intl.NumberFormat('ru-RU').format(e.KPAmount)}</td>
					<td>{e.KPAdvance}%</td>
					<td>{e.KPSl} мес</td>
					<td>{status(e.KPStatus)}</td>
					<td>
						{(e.KPStatus === '2' || e.KPStatus === '3' || e.KPStatus === '4') ? <Link onClick={()=>dow(e.KPId)} style={{cursor:'pointer',fontSize:'12px'}} className='btn btn-ref'><i class="icon-download me-2"></i> Подробное КП</Link> : null}
					
                        {showErrorDownload === e.KPId && <OverlayTrigger
                                trigger='click'
                                placement="top"
                                rootClose={true}
                                show={true}
                                overlay={popoverDownloadError}
                                onToggle={onToggle}
                            >
                                <span style={{width: '0', height: '0'}}/>
                            </OverlayTrigger>}
                    
                    </td>
				</tr>
                <tr>
                    <td colspan="8">
                    <div class="row mb-2">
                    <div class="col-auto mb-2">
                        <button className='btn btn-ref-link' onClick={() => item(e)} disabled={!(page.AppLeasingStatus === '1' || page.AppLeasingStatus === '2' || page.AppLeasingStatus === '3' )} >Редактировать условия лизинга</button>
                        </div>
                        <div class="col-auto mb-2">
                        <button className='btn btn-ref-link' onClick={()=>copy(e.KPId,e)} disabled={!(page.AppLeasingStatus === '1' || page.AppLeasingStatus === '2' || page.AppLeasingStatus === '3' )} >Скопировать</button>
                        </div>
                        <div class="col-auto">
                        <button className='btn btn-ref-link' onClick={()=>del(e.KPId,ii)} disabled={!(page.AppLeasingStatus === '1' || page.AppLeasingStatus === '2' || page.AppLeasingStatus === '3' )} >Удалить</button>
                        </div>
                        </div>
                    </td>
                </tr>
                
                
                </> }  ) : <tr><td colspan="8" className='text-center'>Список пуст</td></tr>}



                </>
			})}
		
		</tbody>
	</table>
    </div>
    
        <div class="row align-items-center mb-n2 mt-2">
        <div class="col-auto mb-2">
								<button class="btn btn-base" onClick={accept} disabled={!(page.AppLeasingStatus === '1' || page.AppLeasingStatus === '2' || page.AppLeasingStatus === '3' ) || input.cancel} >Акцептовать КП</button>
							</div>

                            <div class="col-auto mb-2">
                            {/* <button class="btn btn-base" onClick={cancel} disabled={!(page.AppLeasingStatus === '1' || page.AppLeasingStatus === '2' || page.AppLeasingStatus === '3')}>Отменить акцептирование</button> */}

								<button class="btn btn-ref-link" onClick={cancel} disabled={!(page.AppLeasingStatus === '1' || page.AppLeasingStatus === '2' || page.AppLeasingStatus === '3' ) || !input.cancel}>Отменить акцептирование</button>
							</div>
                            <div class="col-auto mb-2 text-base">
								<button class="btn btn-ref-link" onClick={() => setPage(page => ({...page,step:'blank'}))} disabled={!(page.AppLeasingStatus >= '3')} >Перейти к заполнению анкеты</button>
							</div>
                            </div>

                            {page.modNewKp && <div className="modal-open"><div class="modal fade show" id="modalManager" tabindex="-1" aria-labelledby="modalManager" aria-modal="true" role="dialog" style={{display: 'block'}}>
    <div class="modal-dialog modal-dialog-centered">
				<div class="modal-content">
					<div class="modal-header">
						<h3 class="modal-title">Акцептовать КП</h3>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" onClick={()=>setPage(page => ({...page, modNewKp:false}))}></button>
					</div>

					<div class="modal-body">
						<p>Вы успешно акцептовали коммерческое предложение на лизинг со следующими параметрами:</p>
						{input.KPList.map((kp)=><><div className=''>{kp.name}</div><div class="grid-table grid-table_col-2 mb-3">
							<div class="grid-table__item">Сумма заявки</div>
							<div class="grid-table__item"><b>{reverseString(kp.KPAmount)} руб.</b></div>
							<div class="grid-table__item">Авансовый платеж</div>
							<div class="grid-table__item"><b>{kp.KPAdvance}%.</b></div>
							<div class="grid-table__item">Срок лизинга</div>
							<div class="grid-table__item"><b>{kp.KPSl} мес.</b></div>
						</div></>)}
						<p>Ваша заявка принята в работу и будет рассмотрена с текущими условиями.<br />На следующем этапе необходимо заполнить анкету лизингополучателя.</p>
						<p>Вы можете отменить заявку и акцептовать другое коммерческое предложение.</p>
						<div class="row mb-n3">
							<div class="col-auto mb-3">
								<button class="btn btn-base" onClick={()=>setPage(page => ({...page,step:'blank',modNewKp:false}))}>Перейти к заполнению анкеты</button>
							</div>
							<div class="col-auto mb-3">
								<button class="btn btn-ghost" onClick={cancel}>Отменить акцептование</button>
							</div>
						</div>
					</div>


				</div>
			</div>
		</div></div>}

</> : <div className='mt-5 text-center'>Нет коммерческих предложений</div>}
}

export default Kp