import {useEffect, useState} from 'react'
import {BankAccount} from "../ErrorInput";
import BankPrompt from "../../../components/common/BankPrompt";

let Blank7 = ({save,input,data,setData,error,setError,del,changeArr,changeBankInfo,disabled}) => {


	const onChangeBic = (i,value) => {
        changeBankInfo(value, 'BankAccount', i)
    };



    return data.BankAccount.map((d, i) => <div class="row mt-3 flex-column">
	<div class="col-md-8 col-lg-6">
		{/* <div className={`form-group mb-3 form-floating ${error.BankAccount[i].BankAccountName && 'has-error'}`}>
			<input class="form-control" name="BankAccountName" onChange={(e) => changeArr(e, i, 'BankAccount')} value={d.BankAccountName} placeholder="Иванов" />
			<label>Наименование банка</label>
		</div> */}
		<BankPrompt
		error={error.BankAccount[i].BankAccountName}
		type="BankAccountCode"
		className="form-control"
		onChange={onChangeBic}
		value={d.BankAccountName}
		name="BankAccountName"
		label="Наименование банка"
		i={i}
		disabled={disabled}
		onBlur={save} 
		/>
		<div className={`form-group mb-3 form-floating ${error.BankAccount[i].BankAccountNumber && 'has-error'}`}>
			<input class="form-control" onBlur={save}  name="BankAccountNumber" onChange={(e) => changeArr(e, i, 'BankAccount')} value={d.BankAccountNumber} placeholder="Иванов" disabled={disabled}/>
			<label>Расчетный счет</label>
		</div>
		{/* <div className={`form-group mb-3 form-floating ${error.BankAccount[i].BankAccountCode && 'has-error'}`}>
			<input class="form-control" name="BankAccountCode" onChange={(e) => changeArr(e, i, 'BankAccount')} value={d.BankAccountCode} placeholder="Иванов" />
			<label>БИК</label>
		</div> */}
		
		<BankPrompt
		error={error.BankAccount[i].BankAccountCode}
		type="BankAccountCode"
		className="form-control"
		onChange={onChangeBic}
		value={d.BankAccountCode}
		name="BankAccount"
		label="БИК"
		i={i}
		disabled={disabled}
		onBlur={save} 
		/>
	</div>
	<div className='mb-3'>{data.BankAccount.length !== 1 &&
                            <button
                                onClick={() => del('BankAccount', i)} className="btn btn-ref" onBlur={save}  disabled={disabled}>
                                Удалить информацию о счете
                            </button>}
                        </div>
                        <div>{data.BankAccount.length === (i + 1) &&
                            <button
							
							onClick={(e) => {
                                    setError(error => ({...error, BankAccount: [...error.Credit, BankAccount]}));
                                    setData(data => ({...data, BankAccount: [...data.BankAccount, BankAccount]}))
                                }}
                                className="btn btn-ref" onBlur={save}  disabled={disabled}>
                                Добавить информацию о другом счете
                            </button>}
                        </div>
</div>)
}

export default Blank7