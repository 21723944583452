import { useState } from 'react'

import {location,history,api,ImgUpload,Img} from './Run'


let Test = ({}) => {

    let [data,setData] = useState({end:'ss'})


    let change = (e) => {
        setData(data => ({...data, [e.target.name]: e.target.value}))
        e.persist()
    }

    let re = (e) => {
 
        api('request',{...JSON.parse(data.req) ,token:localStorage.getItem('token')}).then((dd) => {



            setData(data => ({...data, e: JSON.stringify(dd)}))

        })

    }

    return <div className="row m-3">
        
        
   
        <div> 
        <textarea name='req' onChange={change} rows="10" className='form-control'></textarea>
        </div>
    <div> <button onClick={re} className='btn btn-base my-3'>Start test</button></div>


    <div> 
    <textarea className='form-control' rows="25" value={data.e}></textarea>
   
    </div>
    </div>

}

export default Test