import {useEffect, useState} from 'react'
import {Credit} from "../ErrorInput";
import InnPrompt from "../../../components/common/InnPrompt";

let Blank6 = ({save,input,data,setData,error,setError,del,changeArr,changeInnArr,disabled}) => {


	const onChangeInn = (i,value, name, onlyValue=true) => {

        if (!onlyValue) {
            value = value.data.name.short_with_opf
        }
        changeInnArr('Credit', i, name, value)
    };



    return <><div class="row mt-3 flex-column mb-3">
	<div class="col-md-8 col-lg-6">
		<div class="form-group mb-3">
			<label>
				<input type="checkbox" name="checkbox" onBlur={save}  onChange={() => {
                            setData(data => ({
                                ...data,
                                viewSix: data.viewSix ? false : true,
                                Credit: data.viewSix ? [] : [Credit]
                            }));
                            setError(error=> ({...error, Credit: [Credit]}))
                        }} checked={data.viewSix} disabled={disabled} /><span>Имеются обязательства по кредитам, займам либо действующие/закрытые договоры с лизинговыми компаниями</span>
			</label>
		</div>



		{data.viewSix && data.Credit.map((d, i) => <>
		
		{/* <div className={`form-group mb-3 form-floating ${error.Credit[i].Creditor && 'has-error'}`}>
			<input class="form-control" name="Creditor" onChange={(e) => changeArr(e, i, 'Credit')} value={d.Creditor} placeholder="Иванов" />
			<label>Наименование кредитора</label>
		</div> */}
		<InnPrompt
		error={error.Credit[i].Creditor}
                            value={d.Creditor}
                            name="Creditor"
                            label='Наименование кредитора'
                            className='form-control'
                            onChange={onChangeInn}
                            onlyInn={false}
							arr={true}
							i={i}
							disabled={disabled}
							onBlur={save} 
                        />
		<div className={`form-group mb-3 ${error.Credit[i].CreditEndDate && 'has-error'}`}>
			<div class="datepicker-wrapper form-floating">
				<input class="form-control" onBlur={save}  name="CreditEndDate" onChange={(e) => changeArr(e, i, 'Credit')} value={d.CreditEndDate} autocomplete="off" type="date" placeholder="Placeholder" disabled={disabled} />
				<label>Дата окончания договора</label>
			</div>
		</div>
		<div class="row">
			<div class="col-md-6">
				<div className={`form-group mb-3 form-floating ${error.Credit[i].CreditAmount && 'has-error'}`}>
					<input class="form-control" onBlur={save}  name="CreditAmount" onChange={(e) => changeArr(e, i, 'Credit')} value={d.CreditAmount} placeholder="Иванов" disabled={disabled} />
					<label>Сумма договора</label>
				</div>
			</div>
			<div class="col-md-6">
				<div className={`form-group mb-3 form-floating ${error.Credit[i].CreditBalance && 'has-error'}`}>
					<input class="form-control" onBlur={save}  name="CreditBalance" onChange={(e) => changeArr(e, i, 'Credit')} value={d.CreditBalance} placeholder="Иванов" disabled={disabled} />
					<label>Остаток задолженности</label>
				</div>
			</div>
		</div>
		
		<div className='mb-3'>{data.Credit.length !== 1 &&
                        <button
                            type='button'
                            onClick={() => del('Credit', i)}
                            className="btn btn-ref" onBlur={save}  disabled={disabled}>
                            Удалить информацию по кредитору
                        </button>}
                        </div>
                        <div>{data.Credit.length === (i + 1) &&
                        <button
                            type='button' onClick={() => {
                                setError(error => ({...error, Credit: [...error.Credit, Credit]}));
                                setData(data => ({...data, Credit: [...data.Credit, Credit]}))
                            }}
                            className="btn btn-ref" onBlur={save}  disabled={disabled}>
                            Добавить информацию по другому кредитору
                        </button>}
                        </div>
		
		
		</>)}


	</div>
</div></>
}

export default Blank6