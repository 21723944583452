import React from 'react';
import {postRequest} from "../../Run";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import style from './Adress.module.css';
import InputMask from "react-input-mask";


const PassportPrompt = (props) => {

    const [showTooltip, setShowTooltip] = React.useState(false)
    const [options, setOptions] = React.useState([]);

    const getOptions = (input) => {
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Authorization: Token 1fa4a716e7f67a3f0342d5be4e1a4adc98b779d9'
        }

        postRequest(
            `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit`,
            {query: input},
            headers
        ).then((response) => {
            setOptions(response.suggestions.map( el => el.data))
        })
    }


    const onChange = (evt) => {
        let value = evt.currentTarget.value;
        props.onChange(value);
        getOptions(value);
    }

    const toggleTooltip = () => {
        setShowTooltip(!showTooltip);
    }

    const setCurrentOption = (value) => {
        props.onChange(value);
        setOptions([]);
    }

    const onBlur = () => {
        if (props.hasOwnProperty('onBlur')) {
            props.onBlur()
        }
        toggleTooltip();
        setTimeout(() => {
            setOptions([]);
        }, 200);

    };

    return (
        <>
<div className={`form-group mb-3 form-floating ${props.error && 'has-error'}`}>

            <InputMask
                type="text"
                className={props.className}
                name={props.name}
                value={props.value}
                onChange={onChange}
                mask={props.mask}
                autoComplete='off'
                disabled={props.disabled}
                onBlur={onBlur}
            />
            <label>{props.label}</label>

            </div>
            {!!options.length &&
            <div className={style.dropDown}>
                {options.map( el => (
                    <div className={style.dropDown__item}
                         onClick={() => setCurrentOption(el)}
                    >{el.name}</div>
                ))}
            </div>
            }
        </>
    )
}

export default PassportPrompt;
